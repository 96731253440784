import { useSelector } from 'react-redux';

import classes from './ModalYesNo.module.css';
import MainButton from '../MainButton/MainButton';
import { getTrans } from '../../../utils/utils';

const ModalYesNo = (props) => {
    const lang = useSelector((state) => state.system.lang);
    const translations = useSelector((state) => state.system.translations);

    let elClasses = [classes.ModalWrapper];
    if (props.show) elClasses.push(classes.Show);

    return (
        <div className={elClasses.join(' ')}>
            <div className={classes.Backdrop}></div>
            <div className={classes.Modal}>
                <div className={classes.ModalInner}>
                    <div className={classes.ModalContent}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='0.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <circle cx='12' cy='12' r='10'></circle>
                            <line x1='12' y1='8' x2='12' y2='12'></line>
                            <line x1='12' y1='16' x2='12.01' y2='16'></line>
                        </svg>
                        <h1>{props.title}</h1>
                        <span>{props.subtitle}</span>
                    </div>

                    <div className={classes.ModalFooter}>
                        <MainButton label={getTrans(translations, 'yes', lang)} color='action' onClick={props.onYes} />
                        <span onClick={props.onCancel}>{getTrans(translations, 'cancel', lang)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalYesNo;
