import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    waypoints: null,
    businesses: [],
    images: [],
    slideLoading: false,
};

export const webGisSlice = createSlice({
    name: 'webGis',
    initialState,
    reducers: {
        setWaypoints: (state, action) => {
            state.waypoints = action.payload;
        },
        setBusinesses: (state, action) => {
            state.businesses = action.payload;
        },
        setImage: (state, action) => {
            const image = action.payload;
            const found = state.images.find((p) => p.imageName === image.imageName);

            if (!found) state.images.push(image);
        },
        setImages: (state, action) => {
            state.pages = action.payload;
        },
        setSlideLoading: (state, action) => {
            state.slideLoading = action.payload;
        },
    },
});

export const webGisActions = webGisSlice.actions;

export default webGisSlice;
