export default function ListenForOutsideClicks(listening, setListening, menuRef, setIsOpen) {
    return () => {
        if (listening) return;
        if (!menuRef.current) return;
        setListening(true);
        [`click`, `touchstart`].forEach((type) => {
            document.addEventListener(`click`, (evt) => {
                if (!menuRef.current) return;
                const cur = menuRef.current;
                const node = evt.target;
                if (cur.contains(node) || (node.id && node.id === 'startingPointInput')) return;
                setIsOpen(false);
            });
        });
    };
}
