import React from 'react';

import classes from './Modal.module.css';

const Modal = (props) => {
    let elClasses = [classes.ModalWrapper];

    if (props.show) elClasses.push(classes.Show);
    if (props.size === 'small') elClasses.push(classes.Small);
    if (props.size === 'autoWidth') elClasses.push(classes.AutoWidth);
    if (props.size === 'auto') elClasses.push(classes.Auto);

    return (
        <div className={elClasses.join(' ')}>
            <div className={classes.Backdrop}></div>
            <div className={classes.Modal}>
                <div className={classes.ModalInner}>
                    <div className={classes.ModalHeader}>
                        <div>
                            <h3>{props.title}</h3>
                            <span>{props.subtitle}</span>
                        </div>

                        <div className={classes.CloseButtonWrapper}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className={classes.CloseModal}
                                onClick={props.onClose}
                            >
                                <line x1='18' y1='6' x2='6' y2='18'></line>
                                <line x1='6' y1='6' x2='18' y2='18'></line>
                            </svg>
                        </div>
                    </div>

                    <div className={classes.ModalContent}>
                        {props.loading ? (
                            <div className={classes.Loading}>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className={props.loading ? [classes.Spinner, classes.Show].join(' ') : classes.Spinner}
                                >
                                    <line x1='12' y1='2' x2='12' y2='6'></line>
                                    <line x1='12' y1='18' x2='12' y2='22'></line>
                                    <line x1='4.93' y1='4.93' x2='7.76' y2='7.76'></line>
                                    <line x1='16.24' y1='16.24' x2='19.07' y2='19.07'></line>
                                    <line x1='2' y1='12' x2='6' y2='12'></line>
                                    <line x1='18' y1='12' x2='22' y2='12'></line>
                                    <line x1='4.93' y1='19.07' x2='7.76' y2='16.24'></line>
                                    <line x1='16.24' y1='7.76' x2='19.07' y2='4.93'></line>
                                </svg>
                            </div>
                        ) : (
                            props.children
                        )}
                    </div>

                    {props.buttons ? <div className={classes.ModalFooter}>{props.buttons}</div> : null}
                </div>
            </div>
        </div>
    );
};

export default Modal;
