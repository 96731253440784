import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';

import classes from './Businesses.module.css';
import BusinessRow from './BusinessRow';
import { getTrans } from '../../utils/utils';

const Businesses = (props) => {
    const [searchStr, setSearchStr] = useState('');
    const lang = useSelector((state) => state.system.lang);
    const translations = useSelector((state) => state.system.translations);

    const filteredBusinesses = useMemo(() => {
        let fbs = [...props.businesses];
        if (searchStr !== '') {
            fbs = props.businesses.filter(
                (b) =>
                    b.title.toLowerCase().includes(searchStr.toLowerCase()) ||
                    b.subtitle.toLowerCase().includes(searchStr.toLowerCase()) ||
                    b.description.toLowerCase().includes(searchStr.toLowerCase())
            );
        }

        return fbs;
    }, [props.businesses, searchStr]);

    const updateSearch = (str) => {
        setSearchStr(str);
        props.onSearchChange(str);
    };

    return (
        <>
            <div className={classes.SidebarTitle}>{getTrans(translations, 'businesses', lang)}</div>
            <div className={classes.Search}>
                <input placeholder={getTrans(translations, 'search', lang) + '...'} onChange={(e) => updateSearch(e.target.value)} />
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                >
                    <circle cx='11' cy='11' r='8'></circle>
                    <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                </svg>
            </div>
            <div className={classes.Businesses}>
                {filteredBusinesses.length === 0 ? (
                    <span className={classes.NoResults}>{getTrans(translations, 'no_results', lang)}</span>
                ) : (
                    <Scrollbars className={classes.Scroller} style={{ width: '100%', height: '100%' }}>
                        {filteredBusinesses.map((business, index) => {
                            return <BusinessRow key={index} business={business} findDirections={props.findDirections} />;
                        })}
                    </Scrollbars>
                )}
            </div>
        </>
    );
};

export default Businesses;
