import classes from './Title.module.css';

const Title = (props) => {
    return (
        <div className={classes.TitleWrapper}>
            {props.icon && props.icon}

            <div className={classes.Title}>
                <h2>{props.title}</h2>
                {props.subtitle && <span>{props.subtitle}</span>}
            </div>
        </div>
    );
};

export default Title;
