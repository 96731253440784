import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import Title from '../../features/Layout/Title';
import classes from './SuggestedRoutes.module.css';
import Widget from '../../features/Widget/Widget';
import MainButton from '../../features/UI/MainButton/MainButton';
import { getSuggestedRoutes } from './suggestedRoutesAsyncActions';
import { suggestedRoutesActions } from './suggestedRoutesSlice';
import { getTrans } from '../../utils/utils';

const SuggestedRoutes = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const suggestedRoutes = useSelector((state) => state.suggestedRoutes.suggestedRoutes);
    const isMobileWidth = useSelector((state) => state.layout.isMobileWidth);
    const isMobile = useMediaQuery({ query: '(max-width: ' + isMobileWidth + 'px)' });
    const lang = useSelector((state) => state.system.lang);
    const translations = useSelector((state) => state.system.translations);

    useEffect(() => {
        dispatch(getSuggestedRoutes());

        return () => {
            dispatch(suggestedRoutesActions.setSuggestedRoutes([]));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    return (
        <div className={isMobile ? [classes.SuggestedRoutes, classes.IsMobile].join(' ') : classes.SuggestedRoutes}>
            <Title
                title={getTrans(translations, 'menu_routes', lang)}
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <polygon points='1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6'></polygon>
                        <line x1='8' y1='2' x2='8' y2='18'></line>
                        <line x1='16' y1='6' x2='16' y2='22'></line>
                    </svg>
                }
            />

            <div className={classes.ContentInner}>
                <Widget>
                    <div className={classes.Routes}>
                        {suggestedRoutes.map((route, index) => {
                            return (
                                <div key={index} className={classes.Route}>
                                    <img className={classes.RouteImage} src={`/suggested_routes/${route.image}`} />

                                    <h3>{route[`name_${lang}`]}</h3>
                                    <span className={classes.RouteDescription}>{route[`description_${lang}`]}</span>

                                    <div className={classes.AdditionalData}>
                                        <div>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <path d='M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z'></path>
                                                <circle cx='12' cy='10' r='3'></circle>
                                            </svg>
                                            <span>{getTrans(translations, 'stops', lang)}</span>
                                            <span className={classes.AdditionalDataResult}>{route.stops}</span>
                                        </div>
                                        <div>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <circle cx='18' cy='5' r='3'></circle>
                                                <circle cx='6' cy='12' r='3'></circle>
                                                <circle cx='18' cy='19' r='3'></circle>
                                                <line x1='8.59' y1='13.51' x2='15.42' y2='17.49'></line>
                                                <line x1='15.41' y1='6.51' x2='8.59' y2='10.49'></line>
                                            </svg>
                                            <span>{getTrans(translations, 'distance', lang)}</span>
                                            <span className={classes.AdditionalDataResult}>{route.distance}</span>
                                            <span>km</span>
                                        </div>
                                    </div>

                                    <div className={classes.ButtonWrapper}>
                                        <MainButton
                                            label={getTrans(translations, 'view_route', lang)}
                                            color='action'
                                            onClick={() => navigate(`/suggested_routes_map/${route.id}`)}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Widget>
            </div>
        </div>
    );
};

export default SuggestedRoutes;
