import axiosApi from '../../axios-api';
import { useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';

import { systemActions } from '../../features/systemSlice';
import { loginActions } from './loginSlice';

const CONFIG = require('../../reactConfig.json');

export const checkLogin = (loginInfo) => {
    return (dispatch) => {
        dispatch(loginActions.setLoginLoading(true));

        axiosApi
            .post('checkLogin.php', loginInfo)
            .then((response) => {
                if (response.data.token !== undefined && response.data.refreshToken !== undefined) {
                    localStorage.setItem(CONFIG.userTokenName, JSON.stringify(response.data.token));
                    localStorage.setItem(CONFIG.userTokenRefreshName, JSON.stringify(response.data.refreshToken));

                    const user = jwt(response.data.token);
                    dispatch(loginActions.authSuccess(user));

                    const navigate = useNavigate();
                    navigate('/');

                    const currentTime = new Date().getTime() / 1000; // time in seconds
                    dispatch(checkAuthTimeout(user.exp - currentTime));
                }

                dispatch(loginActions.setLoginLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(loginActions.setLoginMessage({ message: message, type: 'error' }));
                dispatch(loginActions.setLoginLoading(false));
            });
    };
};

export const authCheckState = (showLoading) => {
    return (dispatch) => {
        if (showLoading) dispatch(systemActions.setPageLoading(true));

        const token = JSON.parse(localStorage.getItem(CONFIG.userTokenName));
        const refreshToken = JSON.parse(localStorage.getItem(CONFIG.userTokenRefreshName));

        const currentTime = new Date().getTime() / 1000; // time in seconds

        // No token in local storage -> logout
        if (!token) {
            dispatch(loginActions.logout());
            dispatch(systemActions.setPageLoading(false));
            return;
        }

        const decodedToken = jwt(token);
        const decodedRefreshToken = jwt(refreshToken);

        // The refresh token has expired -> logout
        if (decodedRefreshToken.exp < currentTime) {
            dispatch(loginActions.logout());
            dispatch(systemActions.setPageLoading(false));

            // The current access token is still valid
            // } else if (decodedToken.exp > currentTime) {
            //     dispatch(loginActions.authSuccess(decodedToken));
            //     dispatch(systemActions.setPageLoading(false));
            //     dispatch(checkAuthTimeout(decodedToken.exp - currentTime));

            // Token has expired and refresh token is ok -> get new tokens
        } else {
            axiosApi
                .post('tokensRefresh.php', { refreshToken: refreshToken })
                .then((response) => {
                    if (response.data.token !== undefined && response.data.refreshToken !== undefined) {
                        localStorage.setItem(CONFIG.userTokenName, JSON.stringify(response.data.token));
                        localStorage.setItem(CONFIG.userTokenRefreshName, JSON.stringify(response.data.refreshToken));

                        dispatch(loginActions.authSuccess(decodedToken));
                        dispatch(checkAuthTimeout(decodedToken.exp - currentTime));
                    } else {
                        dispatch(loginActions.logout());
                    }

                    dispatch(systemActions.setPageLoading(false));
                })
                .catch((error) => {
                    dispatch(loginActions.logout());
                    dispatch(systemActions.setPageLoading(false));
                });
        }
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(authCheckState());
        }, (expirationTime - 10) * 1000); // Run refresh before the expiration time of the user token
    };
};

export const register = (registerInfo) => {
    return (dispatch) => {
        dispatch(loginActions.setLoginLoading(true));

        axiosApi
            .post('register.php', registerInfo)
            .then((response) => {
                dispatch(loginActions.setLoginLoading(false));
                dispatch(loginActions.setRegisterSuccess(true));
                dispatch(loginActions.setLoginMessage({ message: 'You are now registered. An email is sent to activate your account', type: 'success' }));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(loginActions.setLoginMessage({ message: message, type: 'error' }));
                dispatch(loginActions.setLoginLoading(false));
            });
    };
};

export const activation = (activationKey) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('accountActivation.php', { activationKey: activationKey })
            .then((response) => {
                dispatch(loginActions.setActivationMessage({ message: response.data, type: 'success' }));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(loginActions.setActivationMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const sendResetPasswordEmail = (email) => {
    return (dispatch) => {
        dispatch(loginActions.setLoginLoading(true));

        axiosApi
            .post('sendResetPasswordEmail.php', { email: email })
            .then((response) => {
                dispatch(loginActions.setActivationMessage({ message: response.data, type: 'success' }));
                dispatch(loginActions.setLoginLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(loginActions.setActivationMessage({ message: message, type: 'error' }));
                dispatch(loginActions.setLoginLoading(false));
            });
    };
};

export const resetPassword = (resetKey, newPassword) => {
    return (dispatch) => {
        dispatch(loginActions.setLoginLoading(true));

        axiosApi
            .post('resetPassword.php', { resetKey: resetKey, newPassword: newPassword })
            .then((response) => {
                dispatch(loginActions.setResetMessage({ message: response.data, type: 'success' }));
                dispatch(loginActions.setResetPasswordSuccess(true));
                dispatch(loginActions.setLoginLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(loginActions.setResetMessage({ message: message, type: 'error' }));
                dispatch(loginActions.setLoginLoading(false));
            });
    };
};

export const getMunicipalities = () => {
    return (dispatch) => {
        axiosApi
            .get('getMunicipalities.php')
            .then((response) => {
                dispatch(loginActions.setMunicipalities(response.data));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(loginActions.setLoginMessage({ message: message, type: 'error' }));
                dispatch(loginActions.setLoginLoading(false));
            });
    };
};

export const deleteAccount = () => {
    return (dispatch) => {
        axiosApi
            .get('deleteAccount.php')
            .then((response) => {
                dispatch(loginActions.logout());
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(loginActions.setLoginMessage({ message: message, type: 'error' }));
                dispatch(loginActions.setLoginLoading(false));
            });
    };
};
