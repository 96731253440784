export function formatDatetime(datetime) {
    const time = ('0' + datetime.getHours()).slice(-2) + ':' + ('0' + datetime.getMinutes()).slice(-2) + ':' + ('0' + datetime.getSeconds()).slice(-2);
    const date = datetime.getFullYear() + '-' + ('0' + (datetime.getMonth() + 1)).slice(-2) + '-' + ('0' + datetime.getDate()).slice(-2);
    const formattedDate = date + ' ' + time;

    return formattedDate;
}

export function getStorageLang() {
    let lang = localStorage.getItem('lang');

    if (!lang) {
        setStorageLang('en');
        lang = 'en';
    }

    return lang;
}

export function setStorageLang(lang) {
    localStorage.setItem('lang', lang);
}

export function getTrans(translations, item, lang) {
    if (!translations[item]) return item;

    let transItem = translations[item]['trans_' + lang];
    if (!transItem) transItem = translations[item]['trans_en'];
    if (!transItem) transItem = item;

    return transItem;
}
