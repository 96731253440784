import React, { useState, useRef } from 'react';
import MaterialReactTable from 'material-react-table';

import MainSelect from '../MainSelect/MainSelect';
import classes from './MyTable.module.css';

const MyTable = (props) => {
    const tableInstanceRef = useRef(null);

    const [pagination, setPagination] = useState({ rowsPerPage: 5, showingPage: 1 });

    const updatePagination = (property, value) => {
        let update = { ...pagination };
        update[property] = value;
        setPagination(update);
    };

    // Hack to add message if no data is found. Styling in index.css
    // useEffect(() => {
    //     const table = document.querySelector('.MuiTable-root');

    //     if (!props.data.length) {
    //         table.style.minHeight = '230px';

    //         const tbody = table.getElementsByTagName('tbody')[0];

    //         // Remove previously appended
    //         const elem1 = document.querySelector('.NoDataMessage');
    //         if (elem1) elem1.remove();

    //         // Create the parent div
    //         let noData = document.createElement('div');
    //         noData.setAttribute('class', 'NoDataMessage');
    //         tbody.appendChild(noData);

    //         // Create the icon
    //         let icon = document.createElement('div');
    //         icon.innerHTML =
    //             "<svg width='120' height='100' viewBox='0 0 184 152' aria-hidden='true' focusable='false'><g fill='none' fillRule='evenodd'><g transform='translate(24 31.67)'><ellipse class='ant-empty-img-5' cx='67.797' cy='106.89' rx='67.797' ry='12.668' /><path class='ant-empty-img-1' d='M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z'/><path class='ant-empty-img-2' d='M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z' /><path class='ant-empty-img-3' d='M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z'/></g><path class='ant-empty-img-3' d='M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z'/><g class='ant-empty-img-4' transform='translate(149.65 15.383)'><ellipse cx='20.654' cy='3.167' rx='2.849' ry='2.815' /><path d='M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z' /></g></g></svg>";

    //         let noDataText = document.createElement('span');
    //         noDataText.innerHTML = 'No data were found';

    //         // Append to the parent div
    //         const noDataNode = document.querySelector('.NoDataMessage');
    //         noDataNode.appendChild(icon);
    //         noDataNode.appendChild(noDataText);
    //     } else {
    //         table.style.minHeight = 'unset';
    //         const elem = document.querySelector('.NoDataMessage');
    //         if (elem) elem.remove();
    //     }
    // }, [props.data]);

    const getTopbar = (table, buttons) => {
        const allButtons = (
            <div className={classes.TopLeftBar}>
                <div className={classes.RowsPerPage}>
                    <span>Results: </span>
                    <MainSelect
                        options={[5, 10, 20, 50]}
                        value={pagination.rowsPerPage}
                        onChange={(e) => {
                            updatePagination('rowsPerPage', e.target.value);
                            table.setPageSize(e.target.value);
                        }}
                        veryNarrow
                    />
                </div>

                {buttons}
            </div>
        );
        return allButtons;
    };

    const getPageBtn = (table, type, pageNum) => {
        let pageBtn;

        if (type === 'page') {
            pageBtn = (
                <li className={pagination.showingPage === pageNum ? [classes.PaginationPage, classes.Active].join(' ') : classes.PaginationPage} key={pageNum}>
                    <div
                        className={classes.PageLink}
                        onClick={() => {
                            updatePagination('showingPage', pageNum);
                            table.setPageIndex(pageNum - 1);
                        }}
                    >
                        {pageNum}
                    </div>
                </li>
            );
        } else {
            pageBtn = (
                <li className={classes.PaginationEllipsis} key={`el${pageNum}`}>
                    <div className={[classes.PageLink, classes.Disabled].join(' ')}>...</div>
                </li>
            );
        }

        return pageBtn;
    };

    const getBottombar = (table) => {
        let paginationPages = [];
        const pageCount = table.getPageCount();

        for (let i = 1; i <= pageCount; i++) {
            // Always show the first page
            if (i === 1) {
                paginationPages.push(getPageBtn(table, 'page', i));
            }

            // Add ellipsis before the 5 last pages
            if (pagination.showingPage > pageCount - 4 && i === pageCount - 6) {
                paginationPages.push(getPageBtn(table, 'ellipsis', i));
            }

            // Add the first 5 pages or the last 5 pages when the current page are in the coresponding areas
            if ((pagination.showingPage < 5 && i > 1 && i < 6) || (pagination.showingPage > pageCount - 4 && i < pageCount && i > pageCount - 5 && i > 6)) {
                paginationPages.push(getPageBtn(table, 'page', i));

                // Add ellipsis after the 5 first pages
                if (pagination.showingPage < 5 && i === 5) {
                    paginationPages.push(getPageBtn(table, 'ellipsis', i));
                }
            } else if (pagination.showingPage > 4 && pagination.showingPage < pageCount - 3) {
                if (i === pagination.showingPage) {
                    paginationPages.push(getPageBtn(table, 'ellipsis', i - 1));
                    paginationPages.push(getPageBtn(table, 'page', i - 1));
                    paginationPages.push(getPageBtn(table, 'page', i));
                    paginationPages.push(getPageBtn(table, 'page', i + 1));
                    paginationPages.push(getPageBtn(table, 'ellipsis', i + 1));
                }
            }

            // Always show the last page
            if (i === pageCount && i > 5) {
                paginationPages.push(
                    <li className={pagination.showingPage === i ? [classes.PaginationPage, classes.Active].join(' ') : classes.PaginationPage} key={i}>
                        <div
                            className={classes.PageLink}
                            onClick={() => {
                                updatePagination('showingPage', i);
                                table.setPageIndex(i - 1);
                            }}
                        >
                            {i}
                        </div>
                    </li>
                );
            }
        }

        const allButtons = (
            <div className={classes.BottomBar}>
                <div className={classes.PageCount}>
                    Showing page {pageCount === 0 ? 0 : pagination.showingPage} of {pageCount}
                </div>
                <div className={classes.Pagination}>
                    <ul>
                        <li
                            className={
                                pagination.showingPage < 2 || pageCount === 0
                                    ? [classes.PaginationPrevious, classes.Disabled].join(' ')
                                    : classes.PaginationPrevious
                            }
                        >
                            <div
                                onClick={() => {
                                    updatePagination('showingPage', pagination.showingPage - 1);
                                    table.previousPage();
                                }}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <line x1='19' y1='12' x2='5' y2='12'></line>
                                    <polyline points='12 19 5 12 12 5'></polyline>
                                </svg>
                            </div>
                        </li>

                        {paginationPages}

                        <li className={pagination.showingPage > pageCount - 1 ? [classes.PaginationNext, classes.Disabled].join(' ') : classes.PaginationNext}>
                            <div
                                onClick={() => {
                                    updatePagination('showingPage', pagination.showingPage + 1);
                                    table.nextPage();
                                }}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <line x1='5' y1='12' x2='19' y2='12'></line>
                                    <polyline points='12 5 19 12 12 19'></polyline>
                                </svg>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );

        return allButtons;
    };

    return (
        <div className={classes.MyTable}>
            <MaterialReactTable
                tableInstanceRef={tableInstanceRef}
                columns={props.columns}
                data={props.data}
                enableToolbarInternalActions={false}
                enableColumnActions={false}
                // enableRowNumbers
                enableTopToolbar={true}
                positionGlobalFilter='right'
                muiTableBodyRowProps={{ hover: false }}
                muiSearchTextFieldProps={{
                    placeholder: 'Search...',
                    sx: { minWidth: '18rem' },
                    variant: 'outlined',
                }}
                initialState={{
                    showGlobalFilter: true,
                    pagination: {
                        pageIndex: 0,
                        pageSize: pagination.rowsPerPage,
                    },
                }}
                filterFns={{
                    myCustomFilterFn: (row, id, filterValue) => {
                        let value = row.getValue(id);
                        if (typeof value === 'number') value = String(value);
                        updatePagination('showingPage', 1);
                        tableInstanceRef.current.setPageIndex(0);

                        return value?.toLowerCase().includes(filterValue);
                    },
                }}
                globalFilterFn='myCustomFilterFn' //set the global filter function to myCustomFilterFn
                renderTopToolbarCustomActions={({ table }) => getTopbar(table, props.buttons)}
                renderBottomToolbarCustomActions={({ table }) => getBottombar(table)}
            />
        </div>
    );
};

export default MyTable;
