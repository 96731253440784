import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';

import PdfDocument from './PdfDocument';
import { getElearningInitData } from '../elearningAsyncActions';
import classes from './CoursesOverview.module.css';
import { getTrans } from '../../../utils/utils';
import PdfViewer from '../../../features/PdfViewer/PdfViewer';

const CONFIG = require('../../../reactConfig.json');

const CoursesOverview = (props) => {
    const dispatch = useDispatch();

    const certificateAvailable = useSelector((state) => state.elearning.certificateAvailable);
    const isCompany = useSelector((state) => state.elearning.isCompany);
    const certificateName = useSelector((state) => state.elearning.certificateName);
    const lang = useSelector((state) => state.system.lang);
    const translations = useSelector((state) => state.system.translations);

    const [showPdf, setShowPdf] = useState(false);

    useEffect(() => {
        dispatch(getElearningInitData());
    }, [dispatch]);

    return (
        <div className={classes.CoursesOverview}>
            {showPdf && <PdfViewer file={process.env.PUBLIC_URL + '/pdf/deminimis.pdf'} onClose={() => setShowPdf(false)} />}

            <div className={classes.Overview}>
                <div className={classes.CoursesNum}>
                    <span>{getTrans(translations, 'courses', lang)}</span>
                    <span>{props.coursesProgress.total}</span>
                </div>
                <div className={classes.CoursesComplete}>
                    <span>{getTrans(translations, 'read', lang)}</span>
                    <span>{props.coursesProgress.read}</span>
                </div>
                <div className={classes.CoursesComplete}>
                    <span>{getTrans(translations, 'passed_assessment', lang)}</span>
                    <span>{props.coursesProgress.passed}</span>
                </div>
                <div className={classes.ProgressWrapper}>
                    <span>
                        {getTrans(translations, 'progress', lang)} - {Math.round(props.coursesProgress.progress * 100)}%
                    </span>
                    <div className={classes.Progress}>
                        <div style={{ width: `${props.coursesProgress.progress * 100}%` }} className={classes.ProgressBar}></div>
                    </div>
                </div>
            </div>

            {isCompany && (
                <div className={classes.Deminimis}>
                    <div className={classes.CertificationTitle}>
                        <span>De Minimis</span>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <path d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z'></path>
                            <polyline points='14 2 14 8 20 8'></polyline>
                            <line x1='16' y1='13' x2='8' y2='13'></line>
                            <line x1='16' y1='17' x2='8' y2='17'></line>
                            <polyline points='10 9 9 9 8 9'></polyline>
                        </svg>
                    </div>
                    <span className={classes.CertificationMessage}>
                        {getTrans(translations, 'if_not_done', lang)}{' '}
                        <span className={classes.PdfLink} onClick={() => setShowPdf(true)}>
                            {getTrans(translations, 'download', lang)}
                        </span>
                        {/* <a className={classes.PdfLink} href={process.env.PUBLIC_URL + '/pdf/deminimis.pdf'} target='_blank'>
                            <span>{getTrans(translations, 'download', lang)}</span>
                        </a> */}{' '}
                        {getTrans(translations, 'the_de_minimis', lang)}{' '}
                        <a className={classes.PdfLink} href={`mailto:${CONFIG.email}`}>
                            {CONFIG.email}
                        </a>
                    </span>
                </div>
            )}

            <div className={classes.Certification}>
                <div className={classes.CertificationTitle}>
                    <span>{getTrans(translations, 'certification', lang)}</span>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <circle cx='12' cy='8' r='7'></circle>
                        <polyline points='8.21 13.89 7 23 12 20 17 23 15.79 13.88'></polyline>
                    </svg>
                </div>

                {certificateAvailable ? (
                    <span>
                        {getTrans(translations, 'congratulations', lang)}{' '}
                        <PDFDownloadLink
                            document={<PdfDocument data={certificateName} />}
                            fileName='certificate.pdf'
                            style={{
                                textDecoration: 'none',
                                color: '#e2a03f',
                            }}
                        >
                            {({ loading }) =>
                                loading ? 'Loading document...' : <span className={classes.PdfLink}>{getTrans(translations, 'here', lang)}</span>
                            }
                        </PDFDownloadLink>
                        .
                    </span>
                ) : (
                    <span className={classes.CertificationMessage}>{getTrans(translations, 'not_received_certification', lang)}</span>
                )}
            </div>
        </div>
    );
};

export default CoursesOverview;
