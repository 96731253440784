import classes from './Admin.module.css';

import MainButton from '../../features/UI/MainButton/MainButton';

const AdminUserView = (props) => {
    return (
        <div className={classes.AdminUser}>
            <form>
                <div className={classes.FormRow}>
                    <label>ID</label>
                    <div>{props.selected.id}</div>
                </div>

                <div className={classes.FormRow}>
                    <label>Username</label>
                    <div>{props.selected.username}</div>
                </div>

                <div className={classes.FormRow}>
                    <label>Email</label>
                    <div>{props.selected.email}</div>
                </div>

                <div className={classes.FormRow}>
                    <label>Active</label>
                    <div>
                        {props.selected.active ? (
                            <div className={classes.IconWrapper}>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className={classes.Check}
                                >
                                    <polyline points='20 6 9 17 4 12'></polyline>
                                </svg>
                            </div>
                        ) : (
                            <div className={classes.IconWrapper}>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className={classes.NoCheck}
                                >
                                    <line x1='18' y1='6' x2='6' y2='18'></line>
                                    <line x1='6' y1='6' x2='18' y2='18'></line>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>

                <div className={classes.FormRow}>
                    <label>Registered</label>
                    <div>{props.selected.registered}</div>
                </div>

                <div className={classes.ButtonWrapper}>
                    <MainButton
                        label='Close'
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <line x1='18' y1='6' x2='6' y2='18'></line>
                                <line x1='6' y1='6' x2='18' y2='18'></line>
                            </svg>
                        }
                        color='grey'
                        onClick={props.onButtonClick}
                    />
                </div>
            </form>
        </div>
    );
};

export default AdminUserView;
