import { useEffect, useRef } from 'react';
import { MobilePDFReader } from 'reactjs-pdf-reader';

import classes from './PdfViewer.module.css';

const PdfViewer = (props) => {
    const pdfViewerRef = useRef(null);

    useEffect(() => {
        const pdfViewer = pdfViewerRef.current;

        // Create a button element
        const buttonElement = document.createElement('button');
        buttonElement.innerText = '';
        buttonElement.classList.add('toolbarButton');

        const footerElement = pdfViewer.querySelector('footer');

        const handleClick = () => {
            props.onClose();
        };
        buttonElement.addEventListener('click', handleClick);

        if (footerElement) {
            footerElement.appendChild(buttonElement);
        }

        return () => {
            if (footerElement) {
                footerElement.removeChild(buttonElement);
            }
            buttonElement.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        <div ref={pdfViewerRef} className={classes.PdfViewer}>
            <div className={classes.Background}></div>
            <MobilePDFReader showAllPage={true} url={props.file} />;
        </div>
    );
};

export default PdfViewer;
