import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import Loader from '../../../features/UI/Loader/Loader';
import { getCourseImages } from '../elearningAsyncActions';
import classes from './Viewer.module.css';
import { getTrans } from '../../../utils/utils';

const Viewer = (props) => {
    const dispatch = useDispatch();
    const swiperRef = useRef();

    const slideLoading = useSelector((state) => state.elearning.slideLoading);
    const [fullScreen, setFullScreen] = useState(false);
    const [pageNum, setPageNum] = useState(1);

    const slides = useSelector((state) => state.elearning.pages);
    const lang = useSelector((state) => state.system.lang);
    const translations = useSelector((state) => state.system.translations);

    useEffect(() => {
        if (!props.course) return;
        if (!props.course.pages) return;

        dispatch(getCourseImages(props.course, lang));

        if (swiperRef && swiperRef.current) swiperRef.current.slideTo(0);

        return () => {
            setPageNum(1);
        };
    }, [props.course]);

    return (
        <div className={fullScreen ? [classes.ViewerWrapper, classes.FullScreen].join(' ') : classes.ViewerWrapper}>
            <div className={classes.Viewer}>
                <div className={classes.LoaderWrapper}>
                    <Loader show={slideLoading} background='#000' />
                </div>

                <div className={classes.ImageCarousel}>
                    <Swiper
                        className={classes.Swiper}
                        pagination={true}
                        navigation={true}
                        onSwiper={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        onActiveIndexChange={(swiperCore) => setPageNum(swiperCore.activeIndex + 1)}
                        style={{
                            '--swiper-navigation-color': '#fff',
                            '--swiper-pagination-color': '#fff',
                        }}
                    >
                        {slides.map((image, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <img src={image.page} alt={`slide${index + 1}`} />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
            <div className={classes.Controls}>
                <div className={classes.Previous} onClick={() => swiperRef.current.slidePrev()}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <polyline points='15 18 9 12 15 6'></polyline>
                    </svg>
                    <span>{getTrans(translations, 'previous', lang)}</span>
                </div>
                <div className={classes.Next} onClick={() => swiperRef.current.slideNext()}>
                    <span>{getTrans(translations, 'next', lang)}</span>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <polyline points='9 18 15 12 9 6'></polyline>
                    </svg>
                </div>
                <div className={classes.PageNum}>
                    <span>
                        {pageNum} {getTrans(translations, 'of', lang)} {props?.course?.pagesNum}
                    </span>
                </div>

                {fullScreen === false && (
                    <div className={classes.Maximize} onClick={() => setFullScreen(true)}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <polyline points='15 3 21 3 21 9'></polyline>
                            <polyline points='9 21 3 21 3 15'></polyline>
                            <line x1='21' y1='3' x2='14' y2='10'></line>
                            <line x1='3' y1='21' x2='10' y2='14'></line>
                        </svg>
                    </div>
                )}

                {fullScreen === true && (
                    <div className={classes.Maximize} onClick={() => setFullScreen(false)}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <polyline points='4 14 10 14 10 20'></polyline>
                            <polyline points='20 10 14 10 14 4'></polyline>
                            <line x1='14' y1='10' x2='21' y2='3'></line>
                            <line x1='3' y1='21' x2='10' y2='14'></line>
                        </svg>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Viewer;
