import axiosApi from '../../axios-api';
import axiosApiImage from '../../axios-api-image';
import axiosRoot from 'axios';
import { Buffer } from 'buffer';

import { systemActions } from '../../features/systemSlice';
import { elearningActions } from './elearningSlice';

export const getCoursesData = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        const requestOne = axiosApi.get('getUserCourses.php');
        const requestTwo = axiosApi.get('getUserCoursesProgress.php');

        axiosRoot
            .all([requestOne, requestTwo])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];

                    if (responseOne.status !== 200 || responseTwo.status !== 200) {
                        dispatch(systemActions.setSystemMessage({ message: 'Something went wrong. Please try again.', type: 'error' }));
                        dispatch(systemActions.setLoading(false));
                        return;
                    }

                    dispatch(elearningActions.setCourses(responseOne.data));
                    dispatch(elearningActions.setUserCoursesProgress(responseTwo.data));

                    dispatch(systemActions.setLoading(false));
                })
            )
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getSelectedCourseData = (courseId, lang) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        // Get selected course data
        const requestOne = axiosApi.get('getUserCourses.php');
        const requestTwo = axiosApi.post('getSelectedCourse.php', { courseId: courseId, userLang: lang });

        axiosRoot
            .all([requestOne, requestTwo])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];

                    if (responseOne.status !== 200 || responseTwo.status !== 200) {
                        dispatch(systemActions.setSystemMessage({ message: 'Something went wrong. Please try again.', type: 'error' }));
                        dispatch(systemActions.setLoading(false));
                        return;
                    }

                    dispatch(elearningActions.setCourses(responseOne.data));
                    dispatch(elearningActions.setSelectedCourse(responseTwo.data));

                    dispatch(systemActions.setLoading(false));
                })
            )
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getCourseImages = (course, userLang) => {
    return (dispatch) => {
        dispatch(elearningActions.setSlideLoading(true));

        axiosApi
            .post('getCourseImages.php', { subFolder: 'course' + course.id + '_' + userLang, imagesArr: course.pages })
            .then((response) => {
                const images = response.data.map((imageData, pageIndex) => {
                    const imageSrc = `data:image/jpg;base64,${imageData}`;
                    return { pageIndex: pageIndex, page: imageSrc };
                });

                dispatch(elearningActions.setPages(images));
                dispatch(elearningActions.setSlideLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(elearningActions.setSlideLoading(false));
            });
    };
};

export const getImage = (folder, subFolder, imageName, pageIndex) => {
    return (dispatch) => {
        dispatch(elearningActions.setSlideLoading(true));

        axiosApiImage
            .post('getImage.php', { folder: folder, subFolder: subFolder, imageName: imageName })
            .then((response) => {
                var ext = imageName.split('.')[1];

                const base64ImageString = Buffer.from(response.data, 'binary').toString('base64');
                const imageSrc = `data:image/${ext};base64,${base64ImageString}`;

                dispatch(elearningActions.setPage({ pageIndex: pageIndex, page: imageSrc }));
                dispatch(elearningActions.setSlideLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(elearningActions.setSlideLoading(false));
            });
    };
};

export const getSelectedCourseAssessment = (courseId, lang) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        const requestOne = axiosApi.get('getUserCourses.php');
        const requestTwo = axiosApi.post('getSelectedCourseAssessment.php', { courseId: courseId, userLang: lang });

        axiosRoot
            .all([requestOne, requestTwo])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];

                    if (responseOne.status !== 200 || responseTwo.status !== 200) {
                        dispatch(systemActions.setSystemMessage({ message: 'Something went wrong. Please try again.', type: 'error' }));
                        dispatch(systemActions.setLoading(false));
                        return;
                    }

                    dispatch(elearningActions.setCourses(responseOne.data));
                    dispatch(elearningActions.setAssessmentData(responseTwo.data));
                    dispatch(systemActions.setLoading(false));
                })
            )
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const checkCourseAssessment = (courseId, selectedChoices, lang) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('checkCourseAssessment.php', { courseId: courseId, selectedChoices: selectedChoices })
            .then((response) => {
                dispatch(getSelectedCourseAssessment(courseId, lang));
                //dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getElearningInitData = () => {
    return (dispatch) => {
        //dispatch(systemActions.setLoading(true));

        axiosApi
            .get('getElearningInitData.php')
            .then((response) => {
                dispatch(elearningActions.setElearningInitData(response.data));
                //dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                //dispatch(systemActions.setLoading(false));
            });
    };
};

export const updateUserLang = (lang) => {
    return (dispatch) => {
        // dispatch(systemActions.setLoading(true));

        axiosApi
            .post('updateUserLang.php', { lang: lang })
            .then((response) => {
                // dispatch(systemActions.setSystemMessage({ message: 'The language was saved', type: 'success' }));
                // dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                // dispatch(systemActions.setLoading(false));
            });
    };
};

export const getUserLang = () => {
    return (dispatch) => {
        axiosApi
            .get('getUserLang.php')
            .then((response) => {
                dispatch(elearningActions.setUserLang(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};
