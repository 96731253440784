import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

import classes from './Admin.module.css';
import MainTextarea from '../../features/UI/MainTextarea/MainTextarea';
import MainInput from '../../features/UI/MainInput/MainInput';
import MainButton from '../../features/UI/MainButton/MainButton';
import MainSelect from '../../features/UI/MainSelect/MainSelect';
import MainCheckbox from '../../features/UI/MainCheckbox/MainCheckboxControlled';
import Dropzone from '../../features/Dropzone/Dropzone';
import { saveCourse, addCoursePagesToDb, getCourseTest, saveTest } from './adminAsyncActions';
import { adminActions } from './adminSlice';
import { dropzoneActions } from '../../features/Dropzone/dropzoneSlice';

const AdminCourseEdit = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const [data, setData] = useState({ ...props.selected });
    const [formValidated, setFormValidated] = useState(false);
    const [selLang, setSelLang] = useState('English');
    const [selLangValue, setSelLangValue] = useState('en');
    const [tab, setTab] = useState(1);

    const savedData = useSelector((state) => state.admin.savedData);
    const newId = useSelector((state) => state.admin.newId);
    const uploadedFiles = useSelector((state) => state.dropzone.uploadedFiles);
    const myFiles = useSelector((state) => state.dropzone.myFiles);
    const selectedQuestions = useSelector((state) => state.admin.selectedQuestions);

    const [questions, setQuestions] = useState([]);

    const [changedTest, setChangedTest] = useState(false);

    useEffect(() => {
        setQuestions(selectedQuestions);
    }, [selectedQuestions]);

    useEffect(() => {
        if (props.selected?.id !== '') {
            dispatch(getCourseTest(props.selected.id));
        }

        // This runs on component unmount
        return () => {
            dispatch(dropzoneActions.reset());
            dispatch(adminActions.setSavedData(false));
            dispatch(adminActions.setNewId(null));
            dispatch(adminActions.setSelectedQuestions([]));

            // if (window.ABORT_CONTROLLER) {
            //     window.ABORT_CONTROLLER.abort();
            // }
        };
    }, [dispatch]);

    const updateData = (property, value) => {
        let updateData = { ...data };
        updateData[property] = value;

        setData(updateData);
    };

    const saveData = () => {
        setFormValidated(true);

        if (data.title_en?.trim() === '' || data.title_gr?.trim() === '' || data.title_bg?.trim() === '') {
            enqueueSnackbar('Please fill in all required fields', { variant: 'error' });
        } else {
            if (myFiles.length === 0) {
                dispatch(saveCourse(data, true));
                props.onButtonClick();
            } else {
                dispatch(saveCourse(data, false));
            }

            if (changedTest) {
                dispatch(saveTest(data.id, questions));
                setChangedTest(false);
            }
        }
    };

    const onCompleteUpload = () => {
        dispatch(adminActions.setSavedData(false));

        let courseId = data.id;
        if (courseId === '' && newId) courseId = newId;

        dispatch(addCoursePagesToDb(uploadedFiles, courseId, data.pages_num, selLangValue));

        props.onButtonClick();
    };

    const updateLang = (lang) => {
        let langValue = 'en';
        if (lang === 'Greek') langValue = 'gr';
        else if (lang === 'Bulgarian') langValue = 'bg';

        setSelLang(lang);
        setSelLangValue(langValue);
    };

    const updateQuestion = (index, property, value) => {
        let updatedQuestions = _.cloneDeep(questions);
        updatedQuestions[index][property] = value;
        setChangedTest(true);
        setQuestions(updatedQuestions);
    };

    const updateChoice = (questionIndex, choiceIndex, property, value) => {
        let updatedQuestions = _.cloneDeep(questions);
        updatedQuestions[questionIndex].choices[choiceIndex][property] = value;
        setChangedTest(true);
        setQuestions(updatedQuestions);
    };

    const updateCorrect = (questionIndex, choiceIndex) => {
        let updatedQuestions = _.cloneDeep(questions);

        updatedQuestions[questionIndex].choices.forEach((choice, index) => {
            if (index === choiceIndex) choice.is_correct = !choice.is_correct;
            else choice.is_correct = false;
        });
        setChangedTest(true);
        setQuestions(updatedQuestions);
    };

    const addQuestion = () => {
        let updatedQuestions = _.cloneDeep(questions);
        const newQuestion = {
            id: 0,
            question_en: '',
            question_gr: '',
            question_bg: '',
            choices: [],
        };
        updatedQuestions.push(newQuestion);
        setChangedTest(true);
        setQuestions(updatedQuestions);
    };

    const addChoice = (index) => {
        let updatedQuestions = _.cloneDeep(questions);
        const newChoice = {
            id: 0,
            choice_en: '',
            choice_gr: '',
            choice_bg: '',
            is_correct: false,
        };
        updatedQuestions[index].choices.push(newChoice);
        setChangedTest(true);
        setQuestions(updatedQuestions);
    };

    const deleteChoice = (questionIndex, choiceIndex) => {
        let updatedQuestions = _.cloneDeep(questions);

        if (updatedQuestions[questionIndex].choices[choiceIndex].id === 0) {
            updatedQuestions[questionIndex].choices.splice(choiceIndex, 1);
        } else {
            updatedQuestions[questionIndex].choices[choiceIndex].toDelete = true;
        }

        setChangedTest(true);
        setQuestions(updatedQuestions);
    };

    const deleteQuestion = (questionIndex) => {
        let updatedQuestions = _.cloneDeep(questions);

        if (updatedQuestions[questionIndex].id === 0) {
            updatedQuestions.splice(questionIndex, 1);
        } else {
            updatedQuestions[questionIndex].toDelete = true;
        }

        setChangedTest(true);
        setQuestions(updatedQuestions);
    };

    return (
        <div className={classes.AdminUser}>
            {props.selected?.id !== '' && (
                <div className={classes.Tabs}>
                    <span className={tab === 1 ? [classes.Tab, classes.Active].join(' ') : classes.Tab} onClick={() => setTab(1)}>
                        Info
                    </span>

                    <span className={tab === 2 ? [classes.Tab, classes.Active].join(' ') : classes.Tab} onClick={() => setTab(2)}>
                        Pages
                    </span>
                    <span className={tab === 3 ? [classes.Tab, classes.Active].join(' ') : classes.Tab} onClick={() => setTab(3)}>
                        Test
                    </span>
                </div>
            )}

            {tab === 1 && (
                <form>
                    <div className={classes.FormRow}>
                        <label>ID</label>
                        <MainInput type='text' fullWidth value={data.id} disabled />
                    </div>

                    <div className={classes.FormRow}>
                        <label>Chapter</label>
                        <MainInput type='number' fullWidth defaultValue={data.chapter} onChange={(e) => updateData('chapter', parseInt(e.target.value))} />
                    </div>

                    <div className={classes.FormRow}>
                        <label>Subchapter</label>
                        <MainInput
                            type='number'
                            fullWidth
                            defaultValue={data.subchapter}
                            onChange={(e) => updateData('subchapter', parseInt(e.target.value))}
                        />
                    </div>

                    <div className={classes.FormRow}>
                        <label>Title english</label>
                        <MainInput
                            type='text'
                            fullWidth
                            value={data.title_en}
                            onChange={(e) => updateData('title_en', e.target.value)}
                            validated={formValidated}
                            isValid={data.title_en?.trim() !== ''}
                        />
                    </div>

                    <div className={classes.FormRow}>
                        <label>Title greek</label>
                        <MainInput
                            type='text'
                            fullWidth
                            value={data.title_gr}
                            onChange={(e) => updateData('title_gr', e.target.value)}
                            validated={formValidated}
                            isValid={data.title_gr?.trim() !== ''}
                        />
                    </div>

                    <div className={classes.FormRow}>
                        <label>Title bulgarian</label>
                        <MainInput
                            type='text'
                            fullWidth
                            value={data.title_bg}
                            onChange={(e) => updateData('title_bg', e.target.value)}
                            validated={formValidated}
                            isValid={data.title_bg?.trim() !== ''}
                        />
                    </div>

                    <div className={classes.FormRow}>
                        <label>Description</label>
                        <MainTextarea type='text' fullWidth rows={3} value={data.description} onChange={(e) => updateData('description', e.target.value)} />
                    </div>

                    <div className={classes.FormRow}>
                        <label>Video english</label>
                        <MainInput type='text' fullWidth value={data.video_en} onChange={(e) => updateData('video_en', e.target.value)} />
                    </div>
                    <div className={classes.FormRow}>
                        <label>Video greek</label>
                        <MainInput type='text' fullWidth value={data.video_gr} onChange={(e) => updateData('video_gr', e.target.value)} />
                    </div>
                    <div className={classes.FormRow}>
                        <label>Video bulgarian</label>
                        <MainInput type='text' fullWidth value={data.video_bg} onChange={(e) => updateData('video_bg', e.target.value)} />
                    </div>

                    <div className={classes.FormRow}>
                        <label>Added</label>
                        <MainInput type='text' fullWidth defaultValue={data.added} disabled />
                    </div>
                </form>
            )}

            {tab === 2 && (
                <div className={classes.DropzoneWrapper}>
                    <label>Select language (you can upload one language at a time)</label>
                    <MainSelect options={['English', 'Greek', 'Bulgarian']} value={selLang} onChange={(e) => updateLang(e.target.value)} fullWidth />

                    <p>
                        <b>
                            Pages in {selLang} ({data['numofpages_' + selLangValue]})
                        </b>
                    </p>
                    <Dropzone
                        label='Drag & drop new pages'
                        acceptedFiles={{
                            'application/jpg': ['.jpg'],
                            'application/png': ['.png'],
                        }}
                        maxSize={10485760}
                        onCompleteUpload={onCompleteUpload}
                        uploadsDir='courses'
                        uploadsSubdir={newId ? `course${newId}_${selLangValue}` : `course${data.id}_${selLangValue}`}
                        startUploading={savedData}
                    />
                </div>
            )}

            {tab === 3 && (
                <div className={classes.QuestionsWrapper}>
                    <div className={classes.Questions}>
                        {questions.map((question, index) => {
                            if (question.toDelete) return null;

                            return (
                                <div key={index} className={classes.Question}>
                                    <div className={classes.FirstRow}>
                                        <div className={classes.QuestionNumber}>
                                            <span>{index + 1}</span>
                                        </div>

                                        <div className={classes.QuestionButtons}>
                                            <MainButton label='Add choice' color='success' onClick={() => addChoice(index)} />
                                            <MainButton label='Delete question' color='danger' onClick={() => deleteQuestion(index)} />
                                        </div>
                                    </div>

                                    <div className={classes.QuestionLang}>
                                        <label>Question english</label>
                                        <MainTextarea
                                            type='text'
                                            fullWidth
                                            rows={3}
                                            value={question.question_en}
                                            onChange={(e) => updateQuestion(index, 'question_en', e.target.value)}
                                        />

                                        <div className={classes.ChoicesLang}>
                                            {question.choices.map((choice, choiceIndex) => {
                                                if (choice.toDelete) return null;

                                                return (
                                                    <div className={classes.ChoiceItem} key={choiceIndex}>
                                                        <div className={classes.Bullet}></div>
                                                        <MainInput
                                                            type='text'
                                                            fullWidth
                                                            value={choice.choice_en}
                                                            onChange={(e) => updateChoice(index, choiceIndex, 'choice_en', e.target.value)}
                                                        />
                                                        <MainCheckbox
                                                            label=''
                                                            name='correct'
                                                            checked={choice.is_correct}
                                                            onChange={() => updateCorrect(index, choiceIndex)}
                                                        />
                                                        <svg
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            onClick={() => deleteChoice(index, choiceIndex)}
                                                        >
                                                            <polyline points='3 6 5 6 21 6'></polyline>
                                                            <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'></path>
                                                        </svg>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className={classes.QuestionLang}>
                                        <label>Question greek</label>
                                        <MainTextarea
                                            type='text'
                                            fullWidth
                                            rows={3}
                                            value={question.question_gr}
                                            onChange={(e) => updateQuestion(index, 'question_gr', e.target.value)}
                                        />
                                        <div className={classes.ChoicesLang}>
                                            {question.choices.map((choice, choiceIndex) => {
                                                if (choice.toDelete) return null;

                                                return (
                                                    <div className={classes.ChoiceItem} key={choiceIndex}>
                                                        <div className={classes.Bullet}></div>
                                                        <MainInput
                                                            type='text'
                                                            fullWidth
                                                            value={choice.choice_gr}
                                                            onChange={(e) => updateChoice(index, choiceIndex, 'choice_gr', e.target.value)}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className={classes.QuestionLang}>
                                        <label>Question bulgarian</label>
                                        <MainTextarea
                                            type='text'
                                            fullWidth
                                            rows={3}
                                            value={question.question_bg}
                                            onChange={(e) => updateQuestion(index, 'question_bg', e.target.value)}
                                        />
                                        <div className={classes.ChoicesLang}>
                                            {question.choices.map((choice, choiceIndex) => {
                                                if (choice.toDelete) return null;

                                                return (
                                                    <div className={classes.ChoiceItem} key={choiceIndex}>
                                                        <div className={classes.Bullet}></div>
                                                        <MainInput
                                                            type='text'
                                                            fullWidth
                                                            value={choice.choice_bg}
                                                            onChange={(e) => updateChoice(index, choiceIndex, 'choice_bg', e.target.value)}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <MainButton label='Add question' color='success' onClick={addQuestion} />
                </div>
            )}

            <div className={classes.ButtonWrapper}>
                <MainButton
                    label='Save'
                    icon={
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                            <polyline points='17 21 17 13 7 13 7 21'></polyline>
                            <polyline points='7 3 7 8 15 8'></polyline>
                        </svg>
                    }
                    color='action'
                    onClick={saveData}
                />
            </div>
        </div>
    );
};

export default AdminCourseEdit;
