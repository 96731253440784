import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import classes from './Navigation.module.css';
import { layoutActions } from '../Layout/layoutSlice';
import { getTrans } from '../../utils/utils';

const Navigation = () => {
    const dispatch = useDispatch();
    const menuIsClosed = useSelector((state) => state.layout.menuIsClosed);
    const isMobileWidth = useSelector((state) => state.layout.isMobileWidth);
    const user = useSelector((state) => state.login.user);
    const translations = useSelector((state) => state.system.translations);
    const lang = useSelector((state) => state.system.lang);

    const isMobile = useMediaQuery({ query: '(max-width: ' + isMobileWidth + 'px)' });

    const { pathname } = useLocation();
    const slashIndex = pathname.lastIndexOf('/');

    let pathnameNoParams = pathname;
    if (slashIndex > 0) pathnameNoParams = pathname.slice(0, slashIndex);

    useEffect(() => {
        if (isMobile) {
            dispatch(layoutActions.setMenuClosed(true));
        }
    }, [pathname]);

    return (
        <ul className={isMobile ? [classes.Navigation, classes.IsMobile].join(' ') : classes.Navigation}>
            <li className={classes.NavigationItem}>
                <NavLink to='home' className={({ isActive }) => (isActive || ['/', '/home'].includes(pathnameNoParams) ? classes.active : classes.NavLink)}>
                    <span className={menuIsClosed ? [classes.ItemTitle, classes.Hide].join(' ') : classes.ItemTitle}>
                        {getTrans(translations, 'menu_home', lang)}
                    </span>
                </NavLink>
            </li>

            <li className={classes.NavigationItem}>
                {user ? (
                    <NavLink
                        to='elearning'
                        className={({ isActive }) =>
                            isActive || ['/elearning', '/elearning-view', '/elearning-assessment'].includes(pathnameNoParams) ? classes.active : classes.NavLink
                        }
                    >
                        <span className={menuIsClosed ? [classes.ItemTitle, classes.Hide].join(' ') : classes.ItemTitle}>
                            {getTrans(translations, 'menu_e_learning', lang)}
                        </span>
                    </NavLink>
                ) : (
                    <NavLink to='login'>
                        <span className={menuIsClosed ? [classes.ItemTitle, classes.Hide].join(' ') : classes.ItemTitle}>
                            {getTrans(translations, 'menu_e_learning', lang)}
                        </span>
                    </NavLink>
                )}
            </li>

            <li className={classes.NavigationItem}>
                <NavLink
                    to='sommelier'
                    className={({ isActive }) => (isActive || ['/sommelier'].includes(pathnameNoParams) ? classes.active : classes.NavLink)}
                >
                    <span className={menuIsClosed ? [classes.ItemTitle, classes.Hide].join(' ') : classes.ItemTitle}>
                        {getTrans(translations, 'menu_sommelier', lang)}
                    </span>
                </NavLink>
            </li>

            <li className={classes.NavigationItem}>
                <NavLink to='webgis' className={({ isActive }) => (isActive || ['/webgis'].includes(pathnameNoParams) ? classes.active : classes.NavLink)}>
                    <span className={menuIsClosed ? [classes.ItemTitle, classes.Hide].join(' ') : classes.ItemTitle}>
                        {getTrans(translations, 'menu_businesses', lang)}
                    </span>
                </NavLink>
            </li>

            <li className={classes.NavigationItem}>
                <NavLink
                    to='suggestedroutes'
                    className={({ isActive }) =>
                        isActive || ['/suggestedroutes', '/suggested_routes_map'].includes(pathnameNoParams) ? classes.active : classes.NavLink
                    }
                >
                    <span className={menuIsClosed ? [classes.ItemTitle, classes.Hide].join(' ') : classes.ItemTitle}>
                        {getTrans(translations, 'menu_routes', lang)}
                    </span>
                </NavLink>
            </li>

            <li className={classes.NavigationItem}>
                {user ? (
                    <NavLink
                        to='profile'
                        className={({ isActive }) => (isActive || ['/profile'].includes(pathnameNoParams) ? classes.active : classes.NavLink)}
                    >
                        <span className={menuIsClosed ? [classes.ItemTitle, classes.Hide].join(' ') : classes.ItemTitle}>
                            {getTrans(translations, 'menu_profile', lang)}
                        </span>
                    </NavLink>
                ) : null}
            </li>

            <li className={classes.NavigationItem}>
                {user && user.role === 'Administrator' ? (
                    <NavLink to='admin' className={({ isActive }) => (isActive || ['/admin'].includes(pathnameNoParams) ? classes.active : classes.NavLink)}>
                        <span className={menuIsClosed ? [classes.ItemTitle, classes.Hide].join(' ') : classes.ItemTitle}>
                            {getTrans(translations, 'menu_admin', lang)}
                        </span>
                    </NavLink>
                ) : null}
            </li>
        </ul>
    );
};

export default Navigation;
