import React, { useCallback, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import axiosApi from '../../axios-api-upload';
import { CancelToken, isCancel } from 'axios';

import { dropzoneActions } from './dropzoneSlice';
import { systemActions } from '../systemSlice';
import classes from './Dropzone.module.css';

const MyDropzone = (props) => {
    const dispatch = useDispatch();
    // const [uploadProgress, setUploadProgress] = useState({});

    const uploadStatus = useSelector((state) => state.dropzone.uploadStatus);
    const fileUploadStatus = useSelector((state) => state.dropzone.fileUploadStatus);
    const percentUploaded = useSelector((state) => state.dropzone.percentUploaded);
    const myFiles = useSelector((state) => state.dropzone.myFiles);
    const rejectedFiles = useSelector((state) => state.dropzone.rejectedFiles);

    const cancelFileUpload = useRef(null);
    const startUploading = props.startUploading;

    useEffect(() => {
        if (startUploading) {
            for (let i = 0; i < myFiles.length; i++) {
                const myFile = myFiles[i];
                if (myFile.error) continue;

                uploadFile(myFile, i);
            }
        }
    }, [startUploading]);

    useEffect(() => {
        // All files are finished uploading
        if (myFiles.length > 0 && fileUploadStatus.length === myFiles.length) {
            props.onCompleteUpload();
            dispatch(dropzoneActions.setUploadStatus('uploaded'));
            dispatch(dropzoneActions.reset());
        }
    }, [fileUploadStatus]);

    const uploadFile = (fileToUpload, fileIndex) => {
        dispatch(dropzoneActions.setUploadStatus('uploading'));

        let formData = new FormData();
        formData.append('file', fileToUpload);

        const fileNameAppend = new Blob([''], { type: 'text/plain' });
        formData.append('fileName', fileNameAppend, fileToUpload.name);

        const uploadsDirAppend = new Blob([''], { type: 'text/plain' });
        formData.append('uploadsDir', uploadsDirAppend, props.uploadsDir);

        const uploadsSubdirAppend = new Blob([''], { type: 'text/plain' });
        formData.append('uploadsSubdir', uploadsSubdirAppend, props.uploadsSubdir);

        const fileIndexAppend = new Blob([''], { type: 'text/plain' });
        formData.append('fileIndex', fileIndexAppend, fileIndex);

        const config = {
            onUploadProgress: function (progressEvent) {
                const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                // updateUploadProgress(fileIndex, uploadProgress);
                dispatch(dropzoneActions.setFilePercentUploaded({ fileIndex: fileIndex, percent: uploadProgress }));
            },
            cancelToken: new CancelToken((cancel) => (cancelFileUpload.current = cancel)),
        };

        // Upload the file to server
        axiosApi
            .post('upload.php', formData, config)
            .then((response) => {
                dispatch(dropzoneActions.addUploadedFile(response.data));
                dispatch(dropzoneActions.addFileUploaded());
            })
            .catch((error) => {
                if (isCancel(error)) {
                    dispatch(systemActions.setSystemMessage({ message: error.message, type: 'info' }));
                } else {
                    dispatch(dropzoneActions.setUploadStatus('generalError'));
                }
                dispatch(systemActions.setLoading(false));
            });
    };

    const onDrop = useCallback(
        (acceptedFiles, fileRejections) => {
            if (uploadStatus === 'uploading') return;

            const filesList = [...acceptedFiles];

            let rejectedFiles = [];
            if (fileRejections.length) {
                for (let i = 0; i < fileRejections.length; i++) {
                    const rejection = fileRejections[i];
                    const rejectionFile = rejection.file;
                    const errors = rejection.errors;
                    const firstErrorCode = errors[0].code;
                    let firstError;
                    if (firstErrorCode === 'file-invalid-type')
                        firstError = { text: 'Wrong file type', subText: 'Allowed file types are .png, .jpg and .jpeg' };
                    if (firstErrorCode === 'file-too-large') firstError = { text: 'File is too large', subText: 'Maximum file size is 10 MB' };
                    if (firstErrorCode === 'too-many-files') firstError = { text: 'Too many files', subText: 'Only one file may be selected' };

                    rejectionFile.error = firstError;
                    rejectedFiles.push(rejectionFile);
                }
            }
            dispatch(dropzoneActions.setRejectedFiles(rejectedFiles));

            for (let i = 0; i < filesList.length; i++) {
                const thisFile = filesList[i];
                if (!thisFile.error) thisFile.fileIndex = i;
            }

            dispatch(dropzoneActions.setMyFiles(filesList));
            dispatch(dropzoneActions.setUploadStatus('ready'));

            let updateProgress = {};
            for (let i = 0; i < filesList.length; i++) {
                updateProgress[i] = 0;
            }
            dispatch(dropzoneActions.setPercentUploaded(updateProgress));
        },
        [dispatch, uploadFile, uploadStatus]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: props.maxFiles,
        accept: props.acceptedFiles,
        maxSize: props.maxSize,
    });

    const removeFile = (fileIndex) => {
        let updatedFiles = [...myFiles];
        updatedFiles.splice(fileIndex, 1);

        dispatch(dropzoneActions.setMyFiles(updatedFiles));
    };

    const files = myFiles.map((file, i) => (
        <div className={classes.FileItem} key={i}>
            <span className={classes.FileIndex}>{i + 1}.</span>
            <span className={classes.FileName}>{file.name}</span>
            <span className={classes.FileSize}>
                {file.size < 1000000 ? '(' + Math.round((file.size / 1024) * 100) / 100 + 'KB)' : '(' + Math.round((file.size / 1048576) * 100) / 100 + 'MB)'}
            </span>
            <div className={classes.FileProgress}>
                <div className={classes.ProgressBar} style={{ width: percentUploaded[i] + '%' }}></div>
            </div>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                className={classes.FileRemove}
                onClick={(e) => {
                    e.stopPropagation();
                    removeFile(i);
                }}
            >
                <line x1='18' y1='6' x2='6' y2='18'></line>
                <line x1='6' y1='6' x2='18' y2='18'></line>
            </svg>
        </div>
    ));

    const rejections = rejectedFiles.map((rejectedFile, i) => (
        <div className={classes.FileItem} key={i}>
            <div className={classes.ErrorFileItem}>
                <span className={classes.FileIndex}>-.</span>
                <span className={classes.FileName}>{rejectedFile.name}</span>
                <span className={classes.FileSize}>
                    {rejectedFile.size < 1000000
                        ? '(' + Math.round((rejectedFile.size / 1024) * 100) / 100 + 'KB)'
                        : '(' + Math.round((rejectedFile.size / 1048576) * 100) / 100 + 'MB)'}
                </span>
            </div>

            <div className={classes.ErrorText}>{rejectedFile.error.text}</div>
        </div>
    ));

    let classNames = [classes.DropzoneWrapper];
    if (uploadStatus === 'ready') classNames.push(classes.Ready);
    if (uploadStatus === 'uploading') classNames.push(classes.Uploading);
    else if (uploadStatus === 'uploaded') classNames.push(classes.Uploaded);
    else if (uploadStatus === 'error' || uploadStatus === 'generalError') classNames.push(classes.Error);

    return (
        <section className={classNames.join(' ')}>
            <div
                {...getRootProps({
                    className: classes.Dropzone,
                    onClick: (event) => {
                        if (uploadStatus === 'uploading') event.stopPropagation();
                    },
                })}
            >
                <input {...getInputProps()} />
                <span className={classes.Label}>{props.label}</span>
            </div>

            <div className={classes.FilesWrapper}>
                {files}
                {rejections}
            </div>
        </section>
    );
};

export default MyDropzone;
