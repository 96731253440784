import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userInfo: null,
    userCoursesProgress: {
        total: 0,
        certification: false,
        read: 0,
        passed: 0,
        progress: 0,
    },
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        setUserCoursesProgress: (state, action) => {
            state.userCoursesProgress = action.payload;
        },
        resetUserProfile: (state, action) => {
            state.userInfo = null;
            state.userCoursesProgress = {
                total: 0,
                certification: false,
                read: 0,
                passed: 0,
                progress: 0,
            };
        },
    },
});

export const profileActions = profileSlice.actions;

export default profileSlice;
