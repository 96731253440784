import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    suggestedRoutes: [],
    selectedRoute: null,
};

export const suggestedRoutesSlice = createSlice({
    name: 'suggestedRoutes',
    initialState,
    reducers: {
        setSuggestedRoutes: (state, action) => {
            state.suggestedRoutes = action.payload;
        },
        setSelectedRoute: (state, action) => {
            state.selectedRoute = action.payload;
        },
    },
});

export const suggestedRoutesActions = suggestedRoutesSlice.actions;

export default suggestedRoutesSlice;
