import classes from './Admin.module.css';

import MainButton from '../../features/UI/MainButton/MainButton';

const AdminCourseView = (props) => {
    return (
        <div className={classes.AdminUser}>
            <form>
                <div className={classes.FormRow}>
                    <label>ID</label>
                    <div>{props.selected.id}</div>
                </div>

                <div className={classes.FormRow}>
                    <label>Chapter</label>
                    <div>{props.selected.chapter}</div>
                </div>

                <div className={classes.FormRow}>
                    <label>Subchapter</label>
                    <div>{props.selected.subchapter}</div>
                </div>

                <div className={classes.FormRow}>
                    <label>Title english</label>
                    <div>{props.selected.title_en}</div>
                </div>

                <div className={classes.FormRow}>
                    <label>Title greek</label>
                    <div>{props.selected.title_gr}</div>
                </div>

                <div className={classes.FormRow}>
                    <label>Title bulgarian</label>
                    <div>{props.selected.title_bg}</div>
                </div>

                <div className={classes.FormRow}>
                    <label>Description</label>
                    <div>{props.selected.description}</div>
                </div>

                <div className={classes.FormRow}>
                    <label>Number of pages</label>
                    <div>{props.selected.numofpages_en}</div>
                </div>

                <div className={classes.FormRow}>
                    <label>Notes english</label>
                    <div>{props.selected.notes_en}</div>
                </div>
                <div className={classes.FormRow}>
                    <label>Notes greek</label>
                    <div>{props.selected.notes_gr}</div>
                </div>
                <div className={classes.FormRow}>
                    <label>Notes bulgarian</label>
                    <div>{props.selected.notes_bg}</div>
                </div>

                <div className={classes.FormRow}>
                    <label>Video english</label>
                    <div>{props.selected.video_en}</div>
                </div>
                <div className={classes.FormRow}>
                    <label>Video greek</label>
                    <div>{props.selected.video_gr}</div>
                </div>
                <div className={classes.FormRow}>
                    <label>Video bulgarian</label>
                    <div>{props.selected.video_bg}</div>
                </div>

                <div className={classes.FormRow}>
                    <label>Added</label>
                    <div>{props.selected.added}</div>
                </div>

                <div className={classes.ButtonWrapper}>
                    <MainButton
                        label='Close'
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <line x1='18' y1='6' x2='6' y2='18'></line>
                                <line x1='6' y1='6' x2='18' y2='18'></line>
                            </svg>
                        }
                        color='grey'
                        onClick={props.onButtonClick}
                    />
                </div>
            </form>
        </div>
    );
};

export default AdminCourseView;
