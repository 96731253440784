import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useSnackbar } from 'notistack';
import { useMediaQuery } from 'react-responsive';
import L from 'leaflet';
import { useLocation } from 'react-router-dom';
import axiosApi from '../../axios-api';

import Loader from '../UI/Loader/Loader';
import Topbar from './Topbar';
import Footer from './Footer';
import classes from './Layout.module.css';
import { systemActions } from '../systemSlice';
import { getStorageLang } from '../../utils/utils';
import NavigationMobile from '../Navigation/NavigationMobile';

// Fix marker not showing
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Layout = (props) => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const initLoading = useSelector((state) => state.system.initLoading);
    const pageLoading = useSelector((state) => state.system.pageLoading);
    const loading = useSelector((state) => state.system.loading);
    const systemMessage = useSelector((state) => state.system.systemMessage);
    const menuIsClosed = useSelector((state) => state.layout.menuIsClosed);
    const isMobileWidth = useSelector((state) => state.layout.isMobileWidth);
    const isMobile = useMediaQuery({ query: '(max-width: ' + isMobileWidth + 'px)' });

    useEffect(() => {
        const lang = getStorageLang();
        dispatch(systemActions.setLang(lang));

        // Get translations
        axiosApi
            .get('getTranslations.php')
            .then((response) => {
                dispatch(systemActions.setTranslations(response.data));
                dispatch(systemActions.setInitLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setInitLoading(false));
            });
    }, []);

    // Messages
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (systemMessage) {
            enqueueSnackbar(systemMessage.message, { variant: systemMessage.type });
        }
    }, [systemMessage, enqueueSnackbar]);

    let layoutClasses = [classes.Layout];
    if (isMobile) layoutClasses.push('IsMobile');
    if (menuIsClosed) layoutClasses.push('MenuIsClosed');
    if (loading || pageLoading) layoutClasses.push(classes.NoOverflow);

    const noPaddingPages = useMemo(() => {
        return ['/', '/home', '/login'];
    }, []);

    return initLoading ? (
        <Loader show={true} />
    ) : (
        <div className={layoutClasses.join(' ')}>
            {/* {isMobile ? (
                <>
                    <Sidebar />
                    <Backdrop />
                </>
            ) : null} */}

            <div className={noPaddingPages.includes(pathname) ? [classes.ContentWrapper, classes.NoPadding].join(' ') : classes.ContentWrapper}>
                <Topbar />

                <div className={classes.ScrollWrapper} id='layout-content'>
                    <Loader show={pageLoading || loading} />

                    <Scrollbars className={classes.Scroller} style={{ width: '100%', height: '100%' }}>
                        <div className={classes.Content}>{props.children}</div>
                        {pathname !== '/login' && <Footer />}
                    </Scrollbars>
                </div>

                {isMobile && <NavigationMobile />}
            </div>
        </div>
    );
};

export default Layout;
