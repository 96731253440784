import { createSlice } from '@reduxjs/toolkit';

import { setStorageLang } from '../utils/utils';

const initialState = {
    initLoading: true,
    loading: false,
    pageLoading: false,
    systemMessage: null,
    tileLayer: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    tileLayerAttribution: '',
    minPasswordLength: 8,
    translations: null,
    lang: 'en',
};

export const systemSlice = createSlice({
    name: 'system',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setPageLoading: (state, action) => {
            state.pageLoading = action.payload;
        },
        setSystemMessage: (state, action) => {
            state.systemMessage = action.payload;
        },
        setInitLoading: (state, action) => {
            state.initLoading = action.payload;
        },
        setTranslations: (state, action) => {
            state.translations = action.payload;
        },
        setLang: (state, action) => {
            setStorageLang(action.payload);
            state.lang = action.payload;
        },
    },
});

export const systemActions = systemSlice.actions;

export default systemSlice;
