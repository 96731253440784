import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';

import classes from './Directions.module.css';
import { getTrans } from '../../../utils/utils';

const Directions = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const translations = useSelector((state) => state.system.translations);
    const lang = useSelector((state) => state.system.lang);

    const startingPointsMenuRef = useRef(null);
    const [startingPointFocus, setStartingPointFocus] = useState(false);

    return (
        <>
            <div className={classes.SidebarTitle}>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    onClick={() => navigate('/suggestedroutes')}
                >
                    <line x1='19' y1='12' x2='5' y2='12'></line>
                    <polyline points='12 19 5 12 12 5'></polyline>
                </svg>
                {getTrans(translations, 'directions', lang)}
            </div>
            <div className={classes.Directions}>
                <Scrollbars className={classes.Scroller} style={{ width: '100%', height: '100%' }}>
                    <div id='path-results' className={classes.PathResults}></div>
                </Scrollbars>
            </div>
        </>
    );
};

export default Directions;
