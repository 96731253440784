import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
    users: [],
    courses: [],
    businesses: [],
    roles: [],
    rolesOptions: [],
    newId: null,
    savedData: false,
    businessImages: [],
    selectedQuestions: [],
};

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        reset: (state, action) => {
            state.users = [];
            state.courses = [];
            state.businesses = [];
            state.roles = [];
            state.rolesOptions = [];
            state.newId = null;
            state.savedData = false;
            state.businessImages = [];
        },
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setCourses: (state, action) => {
            state.courses = action.payload;
        },
        setBusinesses: (state, action) => {
            state.businesses = action.payload;
        },
        setNewId: (state, action) => {
            state.newId = action.payload;
        },
        setSavedData: (state, action) => {
            state.savedData = action.payload;
        },
        setBusinessImages: (state, action) => {
            state.businessImages = action.payload;
        },
        setSelectedQuestions: (state, action) => {
            state.selectedQuestions = action.payload;
        },
        updateBusinessImages: (state, action) => {
            const imageId = action.payload.imageId;
            const foundIndex = state.businessImages.findIndex((p) => p.imageId === imageId);
            if (foundIndex > -1) state.businessImages.splice(foundIndex, 1);

            const businessId = action.payload.businessId;
            const foundBusinessIndex = state.businesses.findIndex((b) => b.id === businessId);
            if (foundBusinessIndex > -1) {
                const images = state.businesses[foundBusinessIndex].images;
                const foundImageIndex = images.findIndex((i) => i.id === imageId);
                if (foundImageIndex > -1) images.splice(foundImageIndex, 1);

                state.businesses[foundBusinessIndex].images = images;
            }

            // console.log(current(state));
        },
        setImage: (state, action) => {
            const image = action.payload;
            const found = state.businessImages.find((p) => p.imageName === image.imageName);

            if (!found) state.businessImages.push(image);
        },

        setRoles: (state, action) => {
            state.roles = action.payload;
            let options = [];

            for (const role of action.payload) {
                options.push(role.role_name);
            }
            state.roles = action.payload;
            state.rolesOptions = options;
        },
    },
});

export const adminActions = adminSlice.actions;

export default adminSlice;
