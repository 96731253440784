import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import YoutubeEmbed from '../../features/YoutubeEmbed/YoutubeEmbed';
import Widget from '../../features/Widget/Widget';
import Title from '../../features/Layout/Title';
import classes from './Sommelier.module.css';
import Modal from '../../features/UI/Modal/Modal';
import red_wine_bg from '../../assets/images/red_wine_BG.png';
import red_wine_en from '../../assets/images/red_wine_EN.png';
import red_wine_gr from '../../assets/images/red_wine_GR.png';
import white_wine_bg from '../../assets/images/white_wine_BG.png';
import white_wine_en from '../../assets/images/white_wine_EN.png';
import white_wine_gr from '../../assets/images/white_wine_GR.png';
import rose_wine_bg from '../../assets/images/rose_wine_BG.png';
import rose_wine_en from '../../assets/images/rose_wine_EN.png';
import rose_wine_gr from '../../assets/images/rose_wine_GR.png';
import sommelier_guidelines_gr from '../../assets/pdf/sommelier_guidelines_gr.pdf';
import sommelier_guidelines_en from '../../assets/pdf/sommelier_guidelines_en.pdf';
import sommelier_guidelines_bg from '../../assets/pdf/sommelier_guidelines_bg.pdf';
import { getTrans } from '../../utils/utils';
import PdfViewer from '../../features/PdfViewer/PdfViewer';

const Sommelier = () => {
    const [showVideo, setShowVideo] = useState(null);
    const [showPdf, setShowPdf] = useState(null);

    const lang = useSelector((state) => state.system.lang);
    const translations = useSelector((state) => state.system.translations);

    return (
        <div className={classes.Sommelier}>
            <Modal title={showVideo} size='autoWidth' show={showVideo !== null} onClose={() => setShowVideo(null)}>
                {showVideo === 'White wine (Bulgarian)' && <YoutubeEmbed embedId='szxD6X178Yo' />}
                {showVideo === 'Rose wine (Bulgarian)' && <YoutubeEmbed embedId='CshQp3bDkoA' />}
                {showVideo === 'Red wine (Bulgarian)' && <YoutubeEmbed embedId='OmqeY6Koi3o' />}

                {showVideo === 'White wine (English voice over)' && <YoutubeEmbed embedId='Kgqe5bAe878' />}
                {showVideo === 'Rose wine (English voice over)' && <YoutubeEmbed embedId='hNz56VrHYEQ' />}
                {showVideo === 'Red wine (English voice over)' && <YoutubeEmbed embedId='TQ3Nmu9feGs' />}

                {showVideo === 'White wine (Greek voice over)' && <YoutubeEmbed embedId='wuu9-zJas8U' />}
                {showVideo === 'Rose wine (Greek voice over)' && <YoutubeEmbed embedId='4lCG0Pp8nPk' />}
                {showVideo === 'Red wine (Greek voice over)' && <YoutubeEmbed embedId='kSZKRW3zxKA' />}
            </Modal>

            {showPdf && <PdfViewer file={showPdf} onClose={() => setShowPdf(null)} />}

            <Title
                title={getTrans(translations, 'menu_sommelier', lang)}
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 15.04 24.6'
                        stroke='currentColor'
                        strokeWidth='1'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className={classes.CustomIcon}
                    >
                        <path d='M14.39,8C13.73,5.59,13,3.24,12.31.88A.46.46,0,0,0,11.8.5C10.38.51,9,.5,7.53.5H3.33c-.46,0-.5,0-.63.48Q1.86,3.77,1,6.56A7.1,7.1,0,0,0,.54,9.65,4.14,4.14,0,0,0,2,12.39a11.24,11.24,0,0,0,5,2.26c.18,0,.24.09.23.28v6.61a.32.32,0,0,1-.18.32L4.51,23.4a.35.35,0,0,0-.19.44.34.34,0,0,0,.37.26h5.66a.35.35,0,0,0,.37-.26.35.35,0,0,0-.17-.43l-.15-.1L8.05,21.85c-.1-.07-.17-.12-.17-.26q0-3.36,0-6.71c0-.15,0-.2.19-.22a12.37,12.37,0,0,0,4.51-1.88A4.43,4.43,0,0,0,14.39,8ZM2.24,5.07C2.61,3.85,3,2.63,3.33,1.41c0-.12.09-.18.23-.18h7.91c.11,0,.19,0,.23.14.42,1.44.85,2.87,1.27,4.31,0,0,0,.06,0,.11l-1-.06a12.6,12.6,0,0,0-4.22.59,6.11,6.11,0,0,1-5.4-1A.21.21,0,0,1,2.24,5.07ZM9.06,23.35H6l1.42-.89a.23.23,0,0,1,.26,0Zm3.18-11.24A11.31,11.31,0,0,1,7.6,14a2.88,2.88,0,0,1-1.12-.2,11.37,11.37,0,0,1-3.72-1.72A3.59,3.59,0,0,1,1.26,8.8a10.93,10.93,0,0,1,.57-2.32c0-.16.09-.31.15-.48A7.25,7.25,0,0,0,4.62,7.16a7,7,0,0,0,3.71-.22,10.51,10.51,0,0,1,4.76-.36c.09,0,.17,0,.19.14a9.63,9.63,0,0,1,.49,2A3.59,3.59,0,0,1,12.24,12.11Z' />
                    </svg>
                }
            />

            <div className={classes.ContentInner}>
                <Widget>
                    <p className={classes.Guidelines}>
                        <span>Sommelier's guidelines </span>

                        <span className={classes.Link} onClick={() => setShowPdf(sommelier_guidelines_en)}>
                            english
                        </span>
                        {', '}
                        <span className={classes.Link} onClick={() => setShowPdf(sommelier_guidelines_gr)}>
                            greek
                        </span>
                        {', '}
                        <span className={classes.Link} onClick={() => setShowPdf(sommelier_guidelines_bg)}>
                            bulgarian
                        </span>

                        {/* <a className={classes.Link} href={sommelier_guidelines_en} target='_blank' rel='noopener noreferrer'>
                            english
                        </a>
                        {', '}
                        <a className={classes.Link} href={sommelier_guidelines_gr} target='_blank' rel='noopener noreferrer'>
                            greek
                        </a>
                        {', '}
                        <a className={classes.Link} href={sommelier_guidelines_bg} target='_blank' rel='noopener noreferrer'>
                            bulgarian
                        </a> */}
                    </p>

                    <div className={classes.WineType}>
                        <div className={classes.WineSubs}>
                            <h4>White wine (Bulgarian)</h4>
                            <img src={white_wine_bg} alt='White wine (Bulgarian) video' onClick={() => setShowVideo('White wine (Bulgarian)')} />
                        </div>
                        <div className={classes.WineSubs}>
                            <h4>Rose wine (Bulgarian)</h4>
                            <img src={rose_wine_bg} alt='Rose wine (Bulgarian) video' onClick={() => setShowVideo('Rose wine (Bulgarian)')} />
                        </div>
                        <div className={classes.WineSubs}>
                            <h4>Red wine (Bulgarian)</h4>
                            <img src={red_wine_bg} alt='Red wine (Bulgarian) video' onClick={() => setShowVideo('Red wine (Bulgarian)')} />
                        </div>
                    </div>

                    <div className={classes.WineType}>
                        <div className={classes.WineSubs}>
                            <h4>White wine (English voice over)</h4>
                            <img
                                src={white_wine_en}
                                alt='White wine (English voice over) video'
                                onClick={() => setShowVideo('White wine (English voice over)')}
                            />
                        </div>
                        <div className={classes.WineSubs}>
                            <h4>Rose wine (English voice over)</h4>
                            <img src={rose_wine_en} alt='Rose wine (English voice over) video' onClick={() => setShowVideo('Rose wine (English voice over)')} />
                        </div>
                        <div className={classes.WineSubs}>
                            <h4>Red wine (English voice over)</h4>
                            <img src={red_wine_en} alt='Red wine (English voice over) video' onClick={() => setShowVideo('Red wine (English voice over)')} />
                        </div>
                    </div>

                    <div className={classes.WineType}>
                        <div className={classes.WineSubs}>
                            <h4>White wine (Greek voice over)</h4>
                            <img src={white_wine_gr} alt='White wine (Greek voice over) video' onClick={() => setShowVideo('White wine (Greek voice over)')} />
                        </div>

                        <div className={classes.WineSubs}>
                            <h4>Rose wine (Greek voice over)</h4>
                            <img src={rose_wine_gr} alt='Rose wine (Greek voice over) video' onClick={() => setShowVideo('Rose wine (Greek voice over)')} />
                        </div>
                        <div className={classes.WineSubs}>
                            <h4>Red wine (Greek voice over)</h4>
                            <img src={red_wine_gr} alt='Red wine (Greek voice over) video' onClick={() => setShowVideo('Red wine (Greek voice over)')} />
                        </div>
                    </div>
                </Widget>
            </div>
        </div>
    );
};

export default Sommelier;
