import classes from './MainRadio.module.css';

const MainRadio = (props) => {
    return (
        <div className={classes.MainRadioWrapper}>
            <label className={classes.MainRadio}>
                <input type='radio' name={props.name} onClick={props.onClick} />
                <span className={classes.MainRadioIndicator}></span>
                <span className={classes.MainRadioText}>{props.text}</span>
            </label>
        </div>
    );
};

export default MainRadio;
