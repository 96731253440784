import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { useMediaQuery } from 'react-responsive';

import Logo from '../Logo/Logo';
import LoginButtons from '../../pages/Login/LoginButtons';
import Navigation from '../Navigation/Navigation';
import { loginActions } from '../../pages/Login/loginSlice';
import classes from './Topbar.module.css';
import { layoutActions } from '../Layout/layoutSlice';
import { systemActions } from '../systemSlice';
import MainLangSelect from '../UI/MainSelect/MainLangSelect';

const Topbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.login.user);
    const isMobileWidth = useSelector((state) => state.layout.isMobileWidth);
    const isMobile = useMediaQuery({ query: '(max-width: ' + isMobileWidth + 'px)' });

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible((prevState) => !prevState);
    };
    const closeDropdown = () => {
        setDropdownVisible(false);
    };

    const logout = () => {
        dispatch(loginActions.logout());
        navigate('/');
    };

    // Click outside
    const useOutsideClick = (callback) => {
        const ref = useRef();

        useEffect(() => {
            const handleClick = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    callback();
                }
            };

            document.addEventListener('click', handleClick);

            return () => {
                document.removeEventListener('click', handleClick);
            };
        }, [ref, callback]);

        return ref;
    };

    const ref = useOutsideClick(closeDropdown);

    return (
        <div className={classes.TopbarWrapper}>
            <div className={classes.Topbar}>
                <header className={classes.Header}>
                    {/* <MediaQuery maxWidth={isMobileWidth}>
                        <div className={classes.MobileMenuButton} onClick={() => dispatch(layoutActions.toggleMenuClosed())}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <line x1='3' y1='12' x2='21' y2='12'></line>
                                <line x1='3' y1='6' x2='21' y2='6'></line>
                                <line x1='3' y1='18' x2='21' y2='18'></line>
                            </svg>
                        </div>
                    </MediaQuery> */}

                    <div className={classes.HeaderNav}>
                        {isMobile ? (
                            <Logo size='small' />
                        ) : (
                            <>
                                <Logo />
                                <Navigation />
                            </>
                        )}

                        <MainLangSelect onChange={(newLang) => dispatch(systemActions.setLang(newLang))} />

                        {user ? (
                            <div className={classes.MoreIconWrapper} ref={ref} onClick={toggleDropdown}>
                                <div className={classes.Avatar}>{user?.username?.slice(0, 1).toUpperCase()}</div>

                                <div className={dropdownVisible ? [classes.DropdownMenu, classes.Show].join(' ') : classes.DropdownMenu}>
                                    <div className={classes.UserProfile}>
                                        <h5>{user && user.username}</h5>
                                        <span>{user && user.role}</span>
                                    </div>
                                    <div className={classes.UserLinks}>
                                        <div className={classes.UserLink} onClick={() => navigate('/profile')}>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2'></path>
                                                <circle cx='12' cy='7' r='4'></circle>
                                            </svg>
                                            <span>Profile</span>
                                        </div>

                                        <div className={classes.UserLink} onClick={() => logout()}>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4'></path>
                                                <polyline points='16 17 21 12 16 7'></polyline>
                                                <line x1='21' y1='12' x2='9' y2='12'></line>
                                            </svg>
                                            <span>Sign out</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={classes.LoginButtonsWrapper}>
                                <LoginButtons />
                            </div>
                        )}
                    </div>
                </header>
            </div>
        </div>
    );
};

export default Topbar;
