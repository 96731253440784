import { useState, useRef, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import classes from './MainLangSelect.module.css';
import flag_en from '../../../assets/images/flags/gb.svg';
import flag_gr from '../../../assets/images/flags/gr.svg';
import flag_bg from '../../../assets/images/flags/bg.svg';

const MainLangSelect = (props) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const langs = [
        { value: 'en', image: flag_en, label: 'English' },
        { value: 'gr', image: flag_gr, label: 'Greek' },
        { value: 'bg', image: flag_bg, label: 'Bulgarian' },
    ];

    const lang = useSelector((state) => state.system.lang);

    const toggleDropdown = () => {
        setDropdownVisible((prevState) => !prevState);
    };
    const closeDropdown = () => {
        setDropdownVisible(false);
    };

    // Click outside
    const useOutsideClick = (callback) => {
        const ref = useRef();

        useEffect(() => {
            const handleClick = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    callback();
                }
            };

            document.addEventListener('click', handleClick);

            return () => {
                document.removeEventListener('click', handleClick);
            };
        }, [ref, callback]);

        return ref;
    };

    const ref = useOutsideClick(closeDropdown);

    const selectedLang = useMemo(() => {
        return langs.find((l) => l.value === lang);
    }, [lang]);

    return (
        <div className={classes.MainLangWrapper} ref={ref} onClick={toggleDropdown}>
            <img src={selectedLang.image} alt={selectedLang.value} />

            <div className={dropdownVisible ? [classes.DropdownMenu, classes.Show].join(' ') : classes.DropdownMenu}>
                {langs.map((l, index) => {
                    if (l.value === lang) return null;

                    return <img key={index} src={l.image} alt={l.value} onClick={() => props.onChange(l.value)} />;
                })}
            </div>
        </div>
    );
};

export default MainLangSelect;
