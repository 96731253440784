import axiosApi from '../../axios-api';
import axiosApiImage from '../../axios-api-image';
import axiosRoot from 'axios';
import { Buffer } from 'buffer';

import { systemActions } from '../../features/systemSlice';
import { adminActions } from './adminSlice';

export const getTableData = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        const requestOne = axiosApi.get('adminGetUsers.php');
        const requestTwo = axiosApi.get('adminGetCourses.php');
        const requestThree = axiosApi.get('getBusinesses.php');
        const requestFour = axiosApi.get('adminGetRoles.php');

        axiosRoot
            .all([requestOne, requestTwo, requestThree, requestFour])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];
                    const responseThree = responses[2];
                    const responseFour = responses[3];

                    if (responseOne.status !== 200 || responseTwo.status !== 200 || responseThree.status !== 200 || responseFour.status !== 200) {
                        dispatch(systemActions.setSystemMessage({ message: 'Something went wrong. Please try again.', type: 'error' }));
                        dispatch(systemActions.setLoading(false));
                        return;
                    }

                    dispatch(adminActions.setUsers(responseOne.data));
                    dispatch(adminActions.setCourses(responseTwo.data));
                    dispatch(adminActions.setBusinesses(responseThree.data));
                    dispatch(adminActions.setRoles(responseFour.data));

                    dispatch(systemActions.setLoading(false));
                })
            )
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

// Users
export const getUsers = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('adminGetUsers.php')
            .then((response) => {
                dispatch(adminActions.setUsers(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const saveUser = (data) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('adminSaveUser.php', data)
            .then((response) => {
                console.log(response.data);
                dispatch(getUsers());
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const deleteUser = (id) => {
    return (dispatch) => {
        axiosApi
            .post('adminDeleteUser.php', id)
            .then((response) => {
                dispatch(getUsers());
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

// Businesses
export const getBusinesses = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('getBusinesses.php')
            .then((response) => {
                dispatch(adminActions.setBusinesses(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const saveBusiness = (data, noFiles) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('adminSaveBusiness.php', data)
            .then((response) => {
                dispatch(adminActions.setNewId(response.data));

                if (noFiles) {
                    dispatch(getBusinesses());
                    dispatch(adminActions.setSavedData(false));
                } else {
                    dispatch(adminActions.setSavedData(true));
                }
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const deleteBusiness = (id) => {
    return (dispatch) => {
        axiosApi
            .post('adminDeleteBusiness.php', id)
            .then((response) => {
                dispatch(getBusinesses());
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const addBusinessImagesToDb = (uploadedFiles, businessId, currentPagesLength) => {
    return (dispatch) => {
        axiosApi
            .post('addBusinessImagesToDb.php', { uploadedFiles: uploadedFiles, businessId: businessId, currentPagesLength: currentPagesLength })
            .then((response) => {
                dispatch(getBusinesses());
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getImage = (folder, subFolder, imageName, imageId) => {
    return (dispatch) => {
        // dispatch(adminActions.setSlideLoading(true));

        axiosApiImage
            .post('getImage.php', { folder: folder, subFolder: subFolder, imageName: imageName })
            .then((response) => {
                var ext = imageName.split('.')[1];

                const base64ImageString = Buffer.from(response.data, 'binary').toString('base64');
                const imageSrc = `data:image/${ext};base64,${base64ImageString}`;

                dispatch(adminActions.setImage({ imageName: imageName, image: imageSrc, imageId: imageId }));
                // dispatch(adminActions.setSlideLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
                dispatch(adminActions.setSlideLoading(false));
            });
    };
};

export const deleteBusinessImage = (imageId, businessId, imageName) => {
    return (dispatch) => {
        axiosApiImage
            .post('adminDeleteBusinessImage.php', { imageId: imageId, businessId: businessId, imageName: imageName })
            .then((response) => {
                dispatch(adminActions.updateBusinessImages({ businessId: businessId, imageId: imageId }));
                // dispatch(getBusinesses());
                dispatch(systemActions.setSystemMessage({ message: 'The image was deleted', type: 'success' }));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

// Courses
export const getCourses = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('adminGetCourses.php')
            .then((response) => {
                dispatch(adminActions.setCourses(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getCourseTest = (courseId) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('adminGetCourseTest.php', courseId)
            .then((response) => {
                dispatch(adminActions.setSelectedQuestions(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const saveCourse = (data, noFiles) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('adminSaveCourse.php', data)
            .then((response) => {
                dispatch(adminActions.setNewId(response.data));

                if (noFiles) {
                    dispatch(getCourses());
                    dispatch(adminActions.setSavedData(false));
                } else {
                    dispatch(adminActions.setSavedData(true));
                }
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};
export const saveTest = (courseId, questions) => {
    return (dispatch) => {
        axiosApi
            .post('adminSaveTest.php', { courseId: courseId, questions: questions })
            .then((response) => {})
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const deleteCourse = (id) => {
    return (dispatch) => {
        axiosApi
            .post('adminDeleteCourse.php', id)
            .then((response) => {
                dispatch(getCourses());
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const addCoursePagesToDb = (uploadedFiles, courseId, currentPagesLength, lang) => {
    return (dispatch) => {
        axiosApi
            .post('adminAddCoursePageToDb.php', { uploadedFiles: uploadedFiles, courseId: courseId, lang: lang, currentPagesLength: currentPagesLength })
            .then((response) => {
                dispatch(getCourses());
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};
