import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoggedIn: false,
    isAuthenticated: false,
    isVisible: false,
    showing: 'login',
    user: null,
    loginMessage: null,
    activationMessage: null,
    resetMessage: null,
    loginLoading: false,
    registerSuccess: false,
    resetPasswordSuccess: false,
    municipalities: [],
    municipalitiesOptions: [],
};

const CONFIG = require('../../reactConfig.json');

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setIsVisible: (state, action) => {
            state.isVisible = action.payload;
        },
        setShowing: (state, action) => {
            state.showing = action.payload;
        },
        setLoginMessage: (state, action) => {
            state.loginMessage = action.payload;
        },
        setActivationMessage: (state, action) => {
            state.activationMessage = action.payload;
        },
        setResetMessage: (state, action) => {
            state.resetMessage = action.payload;
        },
        setLoginLoading: (state, action) => {
            state.loginLoading = action.payload;
        },
        setRegisterSuccess: (state, action) => {
            state.registerSuccess = action.payload;
        },
        setResetPasswordSuccess: (state, action) => {
            state.resetPasswordSuccess = action.payload;
        },
        authSuccess: (state, action) => {
            state.user = action.payload;
            state.isAuthenticated = true;
            state.loginMessage = null;
        },
        setMunicipalities: (state, action) => {
            state.municipalities = action.payload.municipalities;
            state.municipalitiesOptions = action.payload.municipalitiesOptions;
        },

        logout: (state) => {
            localStorage.removeItem(CONFIG.userTokenName);
            localStorage.removeItem(CONFIG.userTokenRefreshName);

            state.isAuthenticated = false;
            state.showing = 'login';
            state.user = null;

            // window.location.href = CONFIG.frontend;
        },
    },
});

export const loginActions = loginSlice.actions;

export default loginSlice;
