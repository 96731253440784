import axiosApi from '../../axios-api';
import axiosRoot from 'axios';

import { systemActions } from '../../features/systemSlice';
import { profileActions } from './profileSlice';
import { loginActions } from '../Login/loginSlice';

// Get
// --------------------------------------------------------------------
export const getUserDetails = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        const requestOne = axiosApi.get('getUserInfo.php');
        const requestTwo = axiosApi.get('getUserCoursesProgress.php');

        axiosRoot
            .all([requestOne, requestTwo])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];

                    if (responseOne.status !== 200 || responseTwo.status !== 200) {
                        dispatch(systemActions.setSystemMessage({ message: 'Something went wrong. Please try again.', type: 'error' }));
                        dispatch(systemActions.setLoading(false));
                        return;
                    }

                    dispatch(profileActions.setUserInfo(responseOne.data));
                    dispatch(profileActions.setUserCoursesProgress(responseTwo.data));

                    dispatch(systemActions.setLoading(false));
                })
            )
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getUserInfo = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('getUserInfo.php')
            .then((response) => {
                dispatch(profileActions.setUserInfo(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

// export const getUserCoursesProgress = () => {
//     return (dispatch) => {
//         dispatch(systemActions.setLoading(true));

//         axiosApi
//             .get('getUserCoursesProgress.php')
//             .then((response) => {
//                 dispatch(profileActions.setUserCoursesProgress(response.data));
//                 dispatch(systemActions.setLoading(false));
//             })
//             .catch((error) => {
//                 let message = 'Something went wrong. Please try again.';
//                 if (error?.response?.data?.message) message = error.response.data.message;
//                 if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
//                 dispatch(systemActions.setLoading(false));
//             });
//     };
// };

// Update
// --------------------------------------------------------------------
export const updateUserInfo = (email, fullname, company, tin) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('updateUserInfo.php', { email, fullname, company, tin })
            .then((response) => {
                dispatch(getUserInfo());
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const updateUserPassword = (currentPassword, newPassword, minPasswordLength) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('updateUserPassword.php', { currentPassword: currentPassword, newPassword: newPassword, minPasswordLength: minPasswordLength })
            .then((response) => {
                dispatch(systemActions.setLoading(false));
                dispatch(loginActions.logout());
                dispatch(systemActions.setSystemMessage({ message: 'Login with the new password', type: 'info' }));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};
