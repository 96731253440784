import { Outlet, Navigate } from 'react-router-dom';

const PrivateRoute = ({ isAllowed, redirectPath = '/login', children }) => {
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};

export default PrivateRoute;
