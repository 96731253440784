import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { loginActions } from './loginSlice';
import classes from './LoginButtons.module.css';
import { getTrans } from '../../utils/utils';

const Navigation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const translations = useSelector((state) => state.system.translations);
    const lang = useSelector((state) => state.system.lang);

    return (
        <div className={classes.LoginButtons}>
            <div
                role='button'
                className={classes.LoginButton}
                onClick={() => {
                    dispatch(loginActions.setShowing('login'));
                    navigate('/login');
                    // dispatch(loginActions.setIsVisible(true));
                }}
            >
                {getTrans(translations, 'sign_in', lang)}
            </div>
            <div
                role='button'
                className={classes.RegisterButton}
                onClick={() => {
                    dispatch(loginActions.setShowing('register'));
                    navigate('/login');
                }}
            >
                {getTrans(translations, 'register', lang)}
            </div>
        </div>
    );
};

export default Navigation;
