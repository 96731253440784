import React from 'react';

import classes from './YoutubeEmbed.module.css';

const YoutubeEmbed = (props) => {
    return (
        <div className={classes.VideoResponsive}>
            <iframe
                width='853'
                height='370'
                src={`https://www.youtube.com/embed/${props.embedId}?playlist=${props.embedId}&loop=1&rel=0`}
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
                title='Embedded youtube'
                listtype='playlist'
                rel={0}
            />
        </div>
    );
};

export default YoutubeEmbed;
