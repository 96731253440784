import axiosApi from '../../axios-api';

import { systemActions } from '../../features/systemSlice';
import { suggestedRoutesActions } from './suggestedRoutesSlice';

export const getSuggestedRoutes = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('getSuggestedRoutes.php')
            .then((response) => {
                dispatch(suggestedRoutesActions.setSuggestedRoutes(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getSelectedRoute = (routeId) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('getSelectedRoute.php', { routeId: routeId })
            .then((response) => {
                dispatch(suggestedRoutesActions.setSelectedRoute(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};
