import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import MainButton from '../../features/UI/MainButton/MainButton';
import { loginActions } from './loginSlice';
import { activation } from './loginAsyncActions';
import classes from './Activation.module.css';

const Activation = () => {
    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const activationMessage = useSelector((state) => state.login.activationMessage);

    useEffect(() => {
        dispatch(activation(id));

        // This runs on component unmount
        return () => {
            dispatch(loginActions.setActivationMessage(null));
        };
    }, [dispatch, id]);

    return (
        <div className={classes.ActivationWrapper}>
            {activationMessage && activationMessage.type === 'error' && (
                <div className={classes.ErrorActivation}>
                    <div className={classes.IconWrapper}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <line x1='12' y1='8' x2='12' y2='12'></line>
                            <line x1='12' y1='16' x2='12.01' y2='16'></line>
                        </svg>
                    </div>

                    <h3>{activationMessage.message}</h3>

                    <div className={classes.ButtonWrapper}>
                        <MainButton label='Goto login' color='action' onClick={() => navigate('/login')} />
                    </div>
                </div>
            )}

            {activationMessage && activationMessage.type === 'success' && (
                <div className={classes.SuccessActivation}>
                    <div className={classes.IconWrapper}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <polyline points='20 6 9 17 4 12'></polyline>
                        </svg>
                    </div>

                    <h3>{activationMessage.message}</h3>

                    <div className={classes.ButtonWrapper}>
                        <MainButton label='Goto login' color='action' onClick={() => navigate('/login')} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Activation;
