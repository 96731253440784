import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { MapContainer, ZoomControl, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

import classes from './Admin.module.css';
import MainTextarea from '../../features/UI/MainTextarea/MainTextarea';
import MainInput from '../../features/UI/MainInput/MainInput';
import MainButton from '../../features/UI/MainButton/MainButton';
import ModalYesNo from '../../features/UI/Modal/ModalYesNo';
import Dropzone from '../../features/Dropzone/Dropzone';
import { saveBusiness, getImage, deleteBusinessImage, addBusinessImagesToDb } from './adminAsyncActions';
import { adminActions } from './adminSlice';
import { dropzoneActions } from '../../features/Dropzone/dropzoneSlice';

const CONFIG = require('../../reactConfig.json');

const AdminBusinessEdit = (props) => {
    const dispatch = useDispatch();
    const mapRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();

    const [data, setData] = useState({ ...props.selected });
    const [formValidated, setFormValidated] = useState(false);
    const [deleteImage, setDeleteImage] = useState(null);

    const uploadedFiles = useSelector((state) => state.dropzone.uploadedFiles);
    const newId = useSelector((state) => state.admin.newId);
    const businessImages = useSelector((state) => state.admin.businessImages);
    const savedData = useSelector((state) => state.admin.savedData);
    const myFiles = useSelector((state) => state.dropzone.myFiles);

    useEffect(() => {
        if (props.selected.images) {
            for (let i = 0; i < props.selected.images.length; i++) {
                const image = props.selected.images[i];
                dispatch(getImage('businesses', 'business' + props.selected.id, image.imageName, image.id));
            }
        }

        return () => {
            dispatch(dropzoneActions.reset());
            dispatch(adminActions.setBusinessImages([]));
            dispatch(adminActions.setNewId(null));
            dispatch(adminActions.setSavedData(false));
            // if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    const updateData = (property, value) => {
        let updateData = { ...data };
        updateData[property] = value;

        setData(updateData);
    };

    const updateCoordinates = (index, value) => {
        let updateData = { ...data };
        updateData.position[index] = parseFloat(value);

        setData(updateData);

        if (updateData.position && updateData.position.length && updateData.position[0] && updateData.position[1]) {
            mapRef?.current?.setView(updateData.position, 16);
        }
    };

    const markerDraggedHandler = (latlng) => {
        const lon = +parseFloat(latlng.lng).toFixed(5);
        const lat = +parseFloat(latlng.lat).toFixed(5);
        const updatedPosition = [lat, lon];

        updateData('position', updatedPosition);
    };

    const saveData = () => {
        setFormValidated(true);

        if (data.title?.trim() === '' || data.description?.trim() === '') {
            enqueueSnackbar('Please fill in all required fields', { variant: 'error' });
        } else {
            if (myFiles.length === 0) {
                dispatch(saveBusiness(data, true));
                props.onButtonClick();
            } else {
                dispatch(saveBusiness(data, false));
            }
        }
    };

    const prepareDeleteImage = () => {
        if (!deleteImage) return;
        dispatch(deleteBusinessImage(deleteImage.imageId, props.selected.id, deleteImage.imageName));
        setDeleteImage(null);
    };

    const onCompleteUpload = () => {
        dispatch(adminActions.setSavedData(false));

        let businessId = data.id;
        if (businessId === '' && newId) businessId = newId;

        dispatch(addBusinessImagesToDb(uploadedFiles, businessId, data.images.length));

        props.onButtonClick();
    };

    return (
        <div className={classes.AdminUser}>
            <form>
                <div className={classes.FormRow}>
                    <label>ID</label>
                    <MainInput type='text' fullWidth value={data.id} disabled />
                </div>

                <div className={classes.FormRow}>
                    <label>Title *</label>
                    <MainInput
                        type='text'
                        fullWidth
                        value={data.title}
                        onChange={(e) => updateData('title', e.target.value)}
                        validated={formValidated}
                        isValid={data.title?.trim() !== ''}
                    />
                </div>

                <div className={classes.FormRow}>
                    <label>Description *</label>
                    <MainTextarea
                        type='text'
                        fullWidth
                        rows={3}
                        value={data.description}
                        onChange={(e) => updateData('description', e.target.value)}
                        validated={formValidated}
                        isValid={data.description?.trim() !== ''}
                    />
                </div>

                <div className={classes.FormRow}>
                    <label>Added</label>
                    <MainInput type='text' fullWidth defaultValue={data.added} disabled />
                </div>

                <div className={classes.MapWrapper}>
                    <span>Map</span>
                    <div className={classes.FormRow}>
                        <label>Longitude *</label>
                        <MainInput
                            type='number'
                            fullWidth
                            value={data.position && data.position.length && data.position[1] ? data.position[1] : ''}
                            onChange={(e) => updateCoordinates(1, e.target.value)}
                            validated={formValidated}
                            isValid={data.position && data.position.length > 0 && data.position[1]}
                        />
                    </div>
                    <div className={classes.FormRow}>
                        <label>Latitude *</label>
                        <MainInput
                            type='number'
                            fullWidth
                            value={data.position && data.position.length && data.position[0] ? data.position[0] : ''}
                            onChange={(e) => updateCoordinates(0, e.target.value)}
                            validated={formValidated}
                            isValid={data.position && data.position.length > 0 && data.position[0]}
                        />
                    </div>
                    <div className={classes.Map}>
                        <MapContainer
                            ref={mapRef}
                            center={data.position && data.position.length && data.position[0] && data.position[1] ? data.position : [41, 22]}
                            zoom={data.position && data.position.length && data.position[0] && data.position[1] ? 13 : 6}
                            maxZoom={17}
                            boundsOptions={{ padding: [50, 50] }}
                            zoomControl={false}
                        >
                            <ReactLeafletGoogleLayer apiKey={CONFIG.mapKey} type={'roadmap'} />

                            {data.position && data.position.length && data.position[0] && data.position[1] && (
                                <Marker
                                    position={[data.position[0], data.position[1]]}
                                    draggable={true}
                                    eventHandlers={{
                                        dragend(e) {
                                            const latlng = e.target.getLatLng();
                                            markerDraggedHandler(latlng);
                                        },
                                    }}
                                ></Marker>
                            )}

                            <ZoomControl position='topright' />
                        </MapContainer>
                    </div>
                </div>

                <div className={classes.ImagesWrapper}>
                    <span>Images</span>
                    <div className={classes.AddNewImage}>
                        <Dropzone
                            label='+'
                            acceptedFiles={{
                                'application/jpg': ['.jpg'],
                                'application/png': ['.png'],
                            }}
                            maxSize={10485760}
                            onCompleteUpload={onCompleteUpload}
                            uploadsDir='businesses'
                            uploadsSubdir={newId ? `business${newId}` : `business${data.id}`}
                            startUploading={savedData}
                        />
                    </div>

                    <div className={classes.ImagesList}>
                        {businessImages.map((image, index) => {
                            return (
                                <div style={{ backgroundImage: `url(${image.image})` }} className={classes.ImageItem} key={index}>
                                    <div className={classes.DeleteImage} onClick={() => setDeleteImage(image)}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <polyline points='3 6 5 6 21 6'></polyline>
                                            <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'></path>
                                        </svg>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </form>

            <div className={classes.ButtonWrapper}>
                <MainButton
                    label='Save'
                    icon={
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                            <polyline points='17 21 17 13 7 13 7 21'></polyline>
                            <polyline points='7 3 7 8 15 8'></polyline>
                        </svg>
                    }
                    color='action'
                    onClick={saveData}
                />
            </div>

            <ModalYesNo
                show={deleteImage}
                title='Are you sure?'
                subtitle={`The image with id ${deleteImage?.imageId} will be deleted`}
                onYes={prepareDeleteImage}
                onCancel={() => setDeleteImage(null)}
            />
        </div>
    );
};

export default AdminBusinessEdit;
