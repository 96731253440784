import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import classes from './Geolocation.module.css';
import { getTrans } from '../../utils/utils';

const Geolocation = (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const translations = useSelector((state) => state.system.translations);
    const lang = useSelector((state) => state.system.lang);

    const action = (snackbarId) => (
        <>
            <div
                className={classes.LearnMoreButton}
                onClick={() =>
                    window.open(
                        'https://support.google.com/maps/answer/2839911?hl=en&authuser=0&visit_id=638079224355221383-3252913396&co=GENIE.Platform%3DDesktop&oco=&p=browser_lp&rd=1#permission',
                        '_blank',
                        'noopener,noreferrer'
                    )
                }
            >
                {getTrans(translations, 'learn_more', lang)}
            </div>
            <svg
                onClick={() => closeSnackbar(snackbarId)}
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            >
                <line x1='18' y1='6' x2='6' y2='18'></line>
                <line x1='6' y1='6' x2='18' y2='18'></line>
            </svg>
        </>
    );

    const checkGeolocation = (e) => {
        e.preventDefault();
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    props.setLocation([position.coords.latitude, position.coords.longitude]);
                },
                function (error) {
                    enqueueSnackbar(error.message, { action, variant: 'error' });
                }
            );
        } else {
            enqueueSnackbar('Geolocation is not available', { variant: 'error' });
        }
    };

    return (
        <div id='geolocation' className={classes.Geolocation} onClick={checkGeolocation}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            >
                <circle cx='12' cy='12' r='10'></circle>
                <line x1='22' y1='12' x2='18' y2='12'></line>
                <line x1='6' y1='12' x2='2' y2='12'></line>
                <line x1='12' y1='6' x2='12' y2='2'></line>
                <line x1='12' y1='22' x2='12' y2='18'></line>
            </svg>
            <span>{getTrans(translations, 'your_location', lang)}</span>
        </div>
    );
};

export default Geolocation;
