import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import LoginPart from './LoginPart';
import RegisterPart from './RegisterPart';
import ForgotPart from './ForgotPart';
import classes from './Login.module.css';
import { getMunicipalities } from './loginAsyncActions';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const showing = useSelector((state) => state.login.showing);
    const isMobileWidth = useSelector((state) => state.layout.isMobileWidth);
    const isMobile = useMediaQuery({ query: '(max-width: ' + isMobileWidth + 'px)' });
    const isAuthenticated = useSelector((state) => state.login.isAuthenticated);

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        } else {
            dispatch(getMunicipalities());
        }
    }, [isAuthenticated, navigate]);

    return (
        <div className={isMobile ? [classes.LoginWrapper, classes.IsMobile].join(' ') : classes.LoginWrapper}>
            {showing === 'login' && <LoginPart />}
            {showing === 'register' && <RegisterPart />}
            {showing === 'forgot' && <ForgotPart />}
        </div>
    );
};

export default Login;
