import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import classes from './NavigationMobile.module.css';
import { getTrans } from '../../utils/utils';

const NavigationMobile = () => {
    const user = useSelector((state) => state.login.user);
    const translations = useSelector((state) => state.system.translations);
    const lang = useSelector((state) => state.system.lang);

    const { pathname } = useLocation();
    const slashIndex = pathname.lastIndexOf('/');

    let pathnameNoParams = pathname;
    if (slashIndex > 0) pathnameNoParams = pathname.slice(0, slashIndex);

    return (
        <div className={classes.NavigationMobile}>
            <div className={classes.Navigation}>
                <div className={classes.NavigationItem}>
                    <NavLink to='home' className={({ isActive }) => (isActive || ['/', '/home'].includes(pathnameNoParams) ? classes.active : classes.NavLink)}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <path d='M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z'></path>
                            <polyline points='9 22 9 12 15 12 15 22'></polyline>
                        </svg>
                        <span className={classes.ItemTitle}>{getTrans(translations, 'menu_home', lang)}</span>
                    </NavLink>
                </div>

                <div className={classes.NavigationItem}>
                    {user ? (
                        <NavLink
                            to='elearning'
                            className={({ isActive }) =>
                                isActive || ['/elearning', '/elearning-view', '/elearning-assessment'].includes(pathnameNoParams)
                                    ? classes.active
                                    : classes.NavLink
                            }
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <path d='M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z'></path>
                                <path d='M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z'></path>
                            </svg>
                            <span className={classes.ItemTitle}>{getTrans(translations, 'menu_e_learning', lang)}</span>
                        </NavLink>
                    ) : (
                        <NavLink to='login'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <path d='M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z'></path>
                                <path d='M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z'></path>
                            </svg>
                            <span className={classes.ItemTitle}>{getTrans(translations, 'menu_e_learning', lang)}</span>
                        </NavLink>
                    )}
                </div>

                <div className={classes.NavigationItem}>
                    <NavLink
                        to='sommelier'
                        className={({ isActive }) => (isActive || ['/sommelier'].includes(pathnameNoParams) ? classes.active : classes.NavLink)}
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 15.04 24.6'
                            stroke='currentColor'
                            strokeWidth='1'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className={classes.CustomIcon}
                        >
                            <path d='M14.39,8C13.73,5.59,13,3.24,12.31.88A.46.46,0,0,0,11.8.5C10.38.51,9,.5,7.53.5H3.33c-.46,0-.5,0-.63.48Q1.86,3.77,1,6.56A7.1,7.1,0,0,0,.54,9.65,4.14,4.14,0,0,0,2,12.39a11.24,11.24,0,0,0,5,2.26c.18,0,.24.09.23.28v6.61a.32.32,0,0,1-.18.32L4.51,23.4a.35.35,0,0,0-.19.44.34.34,0,0,0,.37.26h5.66a.35.35,0,0,0,.37-.26.35.35,0,0,0-.17-.43l-.15-.1L8.05,21.85c-.1-.07-.17-.12-.17-.26q0-3.36,0-6.71c0-.15,0-.2.19-.22a12.37,12.37,0,0,0,4.51-1.88A4.43,4.43,0,0,0,14.39,8ZM2.24,5.07C2.61,3.85,3,2.63,3.33,1.41c0-.12.09-.18.23-.18h7.91c.11,0,.19,0,.23.14.42,1.44.85,2.87,1.27,4.31,0,0,0,.06,0,.11l-1-.06a12.6,12.6,0,0,0-4.22.59,6.11,6.11,0,0,1-5.4-1A.21.21,0,0,1,2.24,5.07ZM9.06,23.35H6l1.42-.89a.23.23,0,0,1,.26,0Zm3.18-11.24A11.31,11.31,0,0,1,7.6,14a2.88,2.88,0,0,1-1.12-.2,11.37,11.37,0,0,1-3.72-1.72A3.59,3.59,0,0,1,1.26,8.8a10.93,10.93,0,0,1,.57-2.32c0-.16.09-.31.15-.48A7.25,7.25,0,0,0,4.62,7.16a7,7,0,0,0,3.71-.22,10.51,10.51,0,0,1,4.76-.36c.09,0,.17,0,.19.14a9.63,9.63,0,0,1,.49,2A3.59,3.59,0,0,1,12.24,12.11Z' />
                        </svg>
                        <span className={classes.ItemTitle}>{getTrans(translations, 'sommelier', lang)}</span>
                    </NavLink>
                </div>

                <div className={classes.NavigationItem}>
                    <NavLink to='webgis' className={({ isActive }) => (isActive || ['/webgis'].includes(pathnameNoParams) ? classes.active : classes.NavLink)}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <path d='M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z'></path>
                            <circle cx='12' cy='10' r='3'></circle>
                        </svg>
                        <span className={classes.ItemTitle}>{getTrans(translations, 'businesses', lang)}</span>
                    </NavLink>
                </div>

                <div className={classes.NavigationItem}>
                    <NavLink
                        to='suggestedroutes'
                        className={({ isActive }) =>
                            isActive || ['/suggestedroutes', '/suggested_routes_map'].includes(pathnameNoParams) ? classes.active : classes.NavLink
                        }
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <polygon points='1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6'></polygon>
                            <line x1='8' y1='2' x2='8' y2='18'></line>
                            <line x1='16' y1='6' x2='16' y2='22'></line>
                        </svg>
                        <span className={classes.ItemTitle}>{getTrans(translations, 'routes', lang)}</span>
                    </NavLink>
                </div>

                {user && (
                    <div className={classes.NavigationItem}>
                        <NavLink
                            to='profile'
                            className={({ isActive }) => (isActive || ['/profile'].includes(pathnameNoParams) ? classes.active : classes.NavLink)}
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2'></path>
                                <circle cx='12' cy='7' r='4'></circle>
                            </svg>
                            <span className={classes.ItemTitle}>{getTrans(translations, 'menu_profile', lang)}</span>
                        </NavLink>
                    </div>
                )}

                {user && user.role === 'Administrator' && (
                    <div className={classes.NavigationItem}>
                        <NavLink
                            to='admin'
                            className={({ isActive }) => (isActive || ['/admin'].includes(pathnameNoParams) ? classes.active : classes.NavLink)}
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <path d='M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2'></path>
                                <circle cx='8.5' cy='7' r='4'></circle>
                                <polyline points='17 11 19 13 23 9'></polyline>
                            </svg>
                            <span className={classes.ItemTitle}>{getTrans(translations, 'menu_admin', lang)}</span>
                        </NavLink>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NavigationMobile;
