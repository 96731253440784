import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import L from 'leaflet';

import classes from './Directions.module.css';
import MainInput from '../../features/UI/MainInput/MainInput';
import dots from '../../assets/images/dots.png';
import Geolocation from './Geolocation';
import listenForOutsideClicks from '../../features/ListenForOutsideClicks/ListenForOutsideClicks';
import { webGisActions } from './webGisSlice';
import GooglePlaces from './features/GooglePlaces/GooglePlaces';
import { getTrans } from '../../utils/utils';

const Directions = (props) => {
    const dispatch = useDispatch();

    const translations = useSelector((state) => state.system.translations);
    const lang = useSelector((state) => state.system.lang);

    const startingPointsMenuRef = useRef(null);
    const [startingPointFocus, setStartingPointFocus] = useState(false);
    const [listening, setListening] = useState(false);
    const [searchStr, setSearchStr] = useState('');

    const setLocation = (coordinates) => {
        if (coordinates) {
            const startingCoords = L.latLng(coordinates[0], coordinates[1]);
            const endingCoords = L.latLng(props.business.position[0], props.business.position[1]);
            const wPoints = [startingCoords, endingCoords];
            dispatch(webGisActions.setWaypoints(wPoints));
            setStartingPointFocus(false);
        }
    };

    useEffect(listenForOutsideClicks(listening, setListening, startingPointsMenuRef, setStartingPointFocus));

    const onFocus = () => {
        const pacCont = document.getElementsByClassName('pac-container')[0];
        const listCont = document.getElementById('resultsWrapper');

        listCont.innerHTML = '';
        listCont.append(pacCont);
        setStartingPointFocus(true);
    };

    const onUpdateSearch = (str) => {
        setSearchStr(str);
        if (str !== '') {
            document.getElementById('geolocation').classList.add('hidden');
        } else {
            document.getElementById('geolocation').classList.remove('hidden');
        }
    };

    return (
        <>
            <div className={classes.SidebarTitle}>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    onClick={props.closeDirections}
                >
                    <line x1='19' y1='12' x2='5' y2='12'></line>
                    <polyline points='12 19 5 12 12 5'></polyline>
                </svg>
                {getTrans(translations, 'directions', lang)}
            </div>
            <div className={classes.Directions}>
                <div className={classes.DirectionsInner}>
                    <div className={classes.DirectionsIcons}>
                        <div className={classes.StartingPoint}></div>
                        <div>
                            <img src={dots} alt='Dots' />
                        </div>
                        <div className={classes.EndingPoint}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <path d='M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z'></path>
                                <circle cx='12' cy='10' r='3'></circle>
                            </svg>
                        </div>
                    </div>

                    <div className={classes.DirectionsInputs}>
                        <GooglePlaces onFocus={() => onFocus()} onUpdateSearch={(str) => onUpdateSearch(str)} onSelectPlace={(coords) => setLocation(coords)} />
                        {/* <GoogleGeocoder onResults={(searchStr, data) => onResults(searchStr, data)} onFocus={() => setStartingPointFocus(true)} /> */}
                        <MainInput type='text' fullWidth value={props.business.title} disabled />
                    </div>
                </div>

                <div
                    ref={startingPointsMenuRef}
                    className={startingPointFocus ? [classes.SelectStartingPoint, classes.Show].join(' ') : classes.SelectStartingPoint}
                >
                    {/* Your location */}
                    <Geolocation setLocation={(coords) => setLocation(coords)} />

                    {/* Businesses */}
                    {searchStr === '' && (
                        <div className={classes.BusinessesList}>
                            {props.businesses.map((business, index) => {
                                if (business.id === props.business.id) return null;
                                return (
                                    <div className={classes.BusinessSearchItem} key={`business_${index}`} onClick={() => setLocation(business.position)}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <path d='M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z'></path>
                                            <polyline points='9 22 9 12 15 12 15 22'></polyline>
                                        </svg>
                                        <span>
                                            {business.title && business.subtitle
                                                ? business.title + ' - ' + business.subtitle
                                                : business.title + business.subtitle}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    {/* No results */}
                    {/* {searchStr !== '' && results.length === 0 && <span className={classes.NoResults}>No results</span>} */}

                    {/* Results */}
                    <div id='resultsWrapper' className={classes.ResultsWrapper}>
                        {/* {results.map((result, index) => {
                                return (
                                    <div key={index} className={classes.Location} onClick={() => setLocation([result.y, result.x])}>
                                        {result.label}
                                    </div>
                                );
                            })}
                            {results.length > 0 && (
                                <div className={classes.LocationIQ}>
                                    <a href='https://locationiq.com/' rel='noopener noreferrer' target='_blank'>
                                        Search by LocationIQ.com
                                    </a>
                                </div>
                            )} */}
                    </div>
                </div>

                <Scrollbars className={classes.Scroller} style={{ width: '100%', height: '100%' }}>
                    <div id='path-results' className={classes.PathResults}></div>
                </Scrollbars>
            </div>
        </>
    );
};

export default Directions;
