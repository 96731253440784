import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useMediaQuery } from 'react-responsive';

import classes from './Profile.module.css';
import Title from '../../features/Layout/Title';
import MainInput from '../../features/UI/MainInput/MainInput';
import MainButton from '../../features/UI/MainButton/MainButton';
import MainSwitch from '../../features/UI/MainSwitch/MainSwitch';
import Widget from '../../features/Widget/Widget';
import CoursesOverview from '../ELearning/features/CoursesOverview';
import { getUserDetails, updateUserInfo, updateUserPassword } from './profileAsyncActions';
import { profileActions } from './profileSlice';
import { getTrans } from '../../utils/utils';
import HighlightedText from '../../features/HighlightedText/HighlightedText';
import ModalYesNo from '../../features/UI/Modal/ModalYesNo';
import { deleteAccount } from '../Login/loginAsyncActions';

const { Entropy } = require('entropy-string');

const Profile = () => {
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const minPasswordLength = useSelector((state) => state.system.minPasswordLength);
    const user = useSelector((state) => state.login.user);
    const coursesProgress = useSelector((state) => state.profile.userCoursesProgress);
    const userInfo = useSelector((state) => state.profile.userInfo);
    const lang = useSelector((state) => state.system.lang);
    const translations = useSelector((state) => state.system.translations);
    const isMobileWidth = useSelector((state) => state.layout.isMobileWidth);
    const isMobile = useMediaQuery({ query: '(max-width: ' + isMobileWidth + 'px)' });

    const [email, setEmail] = useState('');
    const [fullname, setFullname] = useState('');
    const [company, setCompany] = useState('');
    const [tin, setTin] = useState('');
    const [infoFormValidated, setInfoFormValidated] = useState(false);

    const [passwords, setPasswords] = useState({ activePassword: '', newPassword: '', confirmNewPassword: '' });
    const [showPasswords, setShowPasswords] = useState(false);
    const [passwordFormValidated, setPasswordFormValidated] = useState(false);
    const [showDeleteAccount, setShowDeleteAccount] = useState(false);

    useEffect(() => {
        if (!user) return;
        dispatch(getUserDetails());

        return () => {
            dispatch(profileActions.resetUserProfile());

            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch, user]);

    useEffect(() => {
        if (!userInfo) return;

        setEmail(userInfo.email);
        setFullname(userInfo.fullname);
        setCompany(userInfo.company);
        setTin(userInfo.tin);
    }, [userInfo]);

    const handleUpdatePassword = () => {
        setPasswordFormValidated(true);

        if (passwords.activePassword.trim() === '' || passwords.newPassword.trim() === '' || passwords.confirmNewPassword.trim() === '') {
            enqueueSnackbar('Please fill in all required fields', { variant: 'error' });
        } else if (passwords.confirmNewPassword.trim() !== passwords.newPassword.trim()) {
            enqueueSnackbar('The password confirmation does not match the password', { variant: 'error' });
        } else if (passwords.newPassword.trim().length < minPasswordLength) {
            enqueueSnackbar('The password must be at least ' + minPasswordLength + ' characters long', { variant: 'error' });
        } else {
            dispatch(updateUserPassword(passwords.activePassword, passwords.newPassword, minPasswordLength));

            setPasswords({ activePassword: '', newPassword: '', confirmNewPassword: '' });
            setPasswordFormValidated(false);
        }
    };

    const handleSetPasswords = (property, value) => {
        let tempPasswords = { ...passwords };
        tempPasswords[property] = value.trim();

        setPasswords(tempPasswords);
    };

    const handleUpdateUserInfo = () => {
        setInfoFormValidated(true);

        if (email.trim() === '') {
            enqueueSnackbar('Please fill in all required fields', { variant: 'error' });
        } else if (fullname.trim() === '' && company.trim() === '') {
            enqueueSnackbar('Please fill in all required fields', { variant: 'error' });
        } else if (company.trim() !== '' && tin.trim() === '') {
            enqueueSnackbar('Please fill in all required fields', { variant: 'error' });
        } else {
            dispatch(updateUserInfo(email, fullname, company, tin));
            setInfoFormValidated(false);
        }
    };

    const showPasswordsHandler = () => {
        setShowPasswords((prevState) => !prevState);
    };

    const generateRandomPassword = () => {
        // Creates a random string
        let entropy;
        let charset;

        charset = '234689bdfghmnpqrBDFGHJLMNR!@#_)(';
        // const specialCharsArr = ["!","@","#","_",")","("];

        const bits = minPasswordLength * 5; // The first number is the minimum characters necessary

        entropy = new Entropy({ charset: charset, bits: bits });
        const string = entropy.string();

        let tempPasswords = { ...passwords };
        tempPasswords.newPassword = string;
        tempPasswords.confirmNewPassword = string;

        setPasswords(tempPasswords);
    };

    const onDeleteAccount = () => {
        setShowDeleteAccount(false);
        dispatch(deleteAccount());
    };

    return (
        <div className={isMobile ? [classes.IsMobile, classes.Profile].join(' ') : classes.Profile}>
            <ModalYesNo
                show={showDeleteAccount}
                title={getTrans(translations, 'are_you_sure', lang)}
                subtitle={getTrans(translations, 'account_will_be_deleted', lang)}
                onYes={onDeleteAccount}
                onCancel={() => setShowDeleteAccount(false)}
            />

            <Title
                title={getTrans(translations, 'menu_profile', lang)}
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2'></path>
                        <circle cx='12' cy='7' r='4'></circle>
                    </svg>
                }
            />
            <div className={classes.ContentInner}>
                <div className={classes.Widgets}>
                    <Widget>
                        <div className={classes.TitleRow}>
                            <h2>{getTrans(translations, 'info', lang)}</h2>
                            <MainButton
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                                        <polyline points='17 21 17 13 7 13 7 21'></polyline>
                                        <polyline points='7 3 7 8 15 8'></polyline>
                                    </svg>
                                }
                                type='circle'
                                color='action'
                                onClick={() => handleUpdateUserInfo()}
                            />
                        </div>

                        <div className={classes.WidgetContent}>
                            <form>
                                <div className={classes.FormRow}>
                                    <label>{getTrans(translations, 'username', lang)}</label>
                                    <MainInput type='text' fullWidth value={userInfo ? userInfo.username : ''} disabled />
                                </div>

                                {userInfo && userInfo.fullname !== '' ? (
                                    <div className={classes.FormRow}>
                                        <label>{getTrans(translations, 'full_name', lang)}</label>
                                        <MainInput
                                            type='text'
                                            fullWidth
                                            value={fullname}
                                            onChange={(e) => setFullname(e.target.value)}
                                            validated={infoFormValidated}
                                            isValid={fullname !== ''}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <div className={classes.FormRow}>
                                            <label>{getTrans(translations, 'company', lang)}</label>
                                            <MainInput
                                                type='text'
                                                fullWidth
                                                value={company}
                                                onChange={(e) => setCompany(e.target.value)}
                                                validated={infoFormValidated}
                                                isValid={company !== ''}
                                            />
                                        </div>
                                        <div className={classes.FormRow}>
                                            <label>{getTrans(translations, 'tin', lang)}</label>
                                            <MainInput
                                                type='text'
                                                fullWidth
                                                value={tin}
                                                onChange={(e) => setTin(e.target.value)}
                                                validated={infoFormValidated}
                                                isValid={tin !== ''}
                                            />
                                        </div>
                                    </>
                                )}

                                <div className={classes.FormRow}>
                                    <label>Email</label>
                                    <MainInput
                                        type='text'
                                        fullWidth
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        validated={infoFormValidated}
                                        isValid={email !== ''}
                                    />
                                </div>

                                <div className={classes.FormRow}>
                                    <label>{getTrans(translations, 'registration_date', lang)}</label>
                                    <MainInput type='text' fullWidth value={userInfo ? userInfo.registration_date : ''} disabled />
                                </div>
                            </form>
                        </div>
                    </Widget>

                    <Widget>
                        <div className={classes.TitleRow}>
                            <div className={classes.TitleRowCol1}>
                                <h2>{getTrans(translations, 'change_password', lang)}</h2>
                                <span>{getTrans(translations, 'at_least_characters', lang)}</span>
                            </div>

                            <div className={classes.TitleRowCol2}>
                                <MainButton
                                    icon={
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                                            <polyline points='17 21 17 13 7 13 7 21'></polyline>
                                            <polyline points='7 3 7 8 15 8'></polyline>
                                        </svg>
                                    }
                                    type='circle'
                                    color='action'
                                    onClick={handleUpdatePassword}
                                    // loading={loginLoading}
                                />
                            </div>
                        </div>

                        <div className={classes.WidgetContent}>
                            <div className={classes.ButtonsWrapper}>
                                <MainSwitch
                                    name='Show passwords'
                                    label={getTrans(translations, 'show_passwords', lang)}
                                    checked={showPasswords}
                                    onChange={showPasswordsHandler}
                                />

                                <div className={classes.RandomPasswordWrapper}>
                                    <MainButton
                                        icon={
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <path d='M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4'></path>
                                            </svg>
                                        }
                                        type='circle'
                                        color='success'
                                        onClick={generateRandomPassword}
                                        // loading={loginLoading}
                                    />
                                    <label>{getTrans(translations, 'random_password', lang)}</label>
                                </div>
                            </div>

                            <form>
                                <div className={classes.FormRow}>
                                    <label>{getTrans(translations, 'current_password', lang)}</label>
                                    <MainInput
                                        type={showPasswords ? 'text' : 'password'}
                                        fullWidth
                                        value={passwords.activePassword}
                                        onChange={(e) => handleSetPasswords('activePassword', e.target.value)}
                                        validated={passwordFormValidated}
                                        isValid={passwords.activePassword !== ''}
                                    />
                                </div>

                                <div className={classes.FormRow}>
                                    <label>{getTrans(translations, 'new_password', lang)}</label>
                                    <MainInput
                                        type={showPasswords ? 'text' : 'password'}
                                        fullWidth
                                        value={passwords.newPassword}
                                        onChange={(e) => handleSetPasswords('newPassword', e.target.value)}
                                        validated={passwordFormValidated}
                                        isValid={passwords.newPassword !== '' && passwords.newPassword.length >= minPasswordLength}
                                    />
                                </div>

                                <div className={classes.FormRow}>
                                    <label>{getTrans(translations, 'confirm_password', lang)}</label>
                                    <MainInput
                                        type={showPasswords ? 'text' : 'password'}
                                        fullWidth
                                        value={passwords.confirmNewPassword}
                                        onChange={(e) => handleSetPasswords('confirmNewPassword', e.target.value)}
                                        validated={passwordFormValidated}
                                        isValid={
                                            passwords.confirmNewPassword !== '' &&
                                            passwords.confirmNewPassword.length >= minPasswordLength &&
                                            passwords.confirmNewPassword === passwords.newPassword
                                        }
                                    />
                                </div>
                            </form>
                        </div>
                    </Widget>

                    {user && user.role === 'User' && (
                        <Widget>
                            <div className={classes.TitleRow}>
                                <div className={classes.TitleRowCol1}>
                                    <h2>{getTrans(translations, 'delete_account', lang)}</h2>
                                    <span>{getTrans(translations, 'will_be_deleted', lang)}</span>
                                </div>
                            </div>

                            <div className={classes.WidgetContent}>
                                <HighlightedText type='warning' text={getTrans(translations, 'irreversible', lang)} />

                                <div className={classes.ButtonsWrapper}>
                                    <MainButton
                                        icon={
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <polyline points='3 6 5 6 21 6'></polyline>
                                                <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'></path>
                                            </svg>
                                        }
                                        label={getTrans(translations, 'delete', lang)}
                                        color='danger'
                                        onClick={() => setShowDeleteAccount(true)}
                                    />
                                </div>
                            </div>
                        </Widget>
                    )}

                    <Widget noPadding>
                        <CoursesOverview coursesProgress={coursesProgress} />
                    </Widget>
                </div>
            </div>
        </div>
    );
};

export default Profile;
