import { useRef, useEffect } from 'react';

import classes from './GooglePlaces.module.css';

const GooglePlaces = (props) => {
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        fields: ['address_components', 'geometry', 'icon', 'name'],
    };

    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);

        autoCompleteRef.current.addListener('place_changed', async function () {
            const place = await autoCompleteRef.current.getPlace();
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();

            props.onSelectPlace([lat, lng]);
        });
    }, []);

    return (
        <div className={classes.GooglePlaces}>
            <input
                id='startingPointInput'
                ref={inputRef}
                className={classes.GooglePlacesInput}
                onFocus={() => props.onFocus()}
                onChange={(e) => {
                    props.onUpdateSearch(e.target.value);
                }}
            />
        </div>
    );
};
export default GooglePlaces;
