import axiosApi from '../../axios-api';
import axiosApiImage from '../../axios-api-image';
import { Buffer } from 'buffer';

import { systemActions } from '../../features/systemSlice';
import { webGisActions } from './webGisSlice';

export const getBusinesses = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('getBusinesses.php')
            .then((response) => {
                dispatch(webGisActions.setBusinesses(response.data));

                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getImage = (folder, subFolder, imageName) => {
    return (dispatch) => {
        dispatch(webGisActions.setSlideLoading(true));

        axiosApiImage
            .post('getImage.php', { folder: folder, subFolder: subFolder, imageName: imageName })
            .then((response) => {
                var ext = imageName.split('.')[1];

                const base64ImageString = Buffer.from(response.data, 'binary').toString('base64');

                const imageSrc = `data:image/${ext};base64,${base64ImageString}`;

                dispatch(webGisActions.setImage({ imageName: imageName, image: imageSrc }));
                dispatch(webGisActions.setSlideLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(systemActions.setSystemMessage({ message: message, type: 'error' }));
                dispatch(webGisActions.setSlideLoading(false));
            });
    };
};
