import axios from 'axios';

const CONFIG = require('./reactConfig.json');

const fetchClient = () => {
    const defaultOptions = {
        baseURL: CONFIG.backend,
        crossDomain: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        },
    };

    // Create instance
    let instance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
        const controller = new AbortController();
        window.ABORT_CONTROLLER = controller;

        const token = JSON.parse(localStorage.getItem(CONFIG.userTokenName));
        config.headers.Authorization = token;

        const cfg = {
            ...config,
            signal: controller.signal,
        };

        return cfg;
    });

    // instance.interceptors.response.use((response) => {
    //     if(response.status === 401) {
    //          alert("You are not authorized");
    //     }
    //     return response;
    // }, (error) => {
    //     if (error.response && error.response.data) {
    //         return Promise.reject(error.response.data);
    //     }
    //     return Promise.reject(error.message);
    // });

    return instance;
};

export default fetchClient();
