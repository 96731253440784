import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classes from './ELearning.module.css';
import Widget from '../../features/Widget/Widget';
import Title from '../../features/Layout/Title';
import CourseRow from './features/CourseRow';
import CoursesOverview from './features/CoursesOverview';
import { elearningActions } from './elearningSlice';
import { getCoursesData } from './elearningAsyncActions';
import { getTrans } from '../../utils/utils';

const ELearning = () => {
    const dispatch = useDispatch();

    const courses = useSelector((state) => state.elearning.courses);
    const coursesProgress = useSelector((state) => state.elearning.userCoursesProgress);
    const lang = useSelector((state) => state.system.lang);
    const translations = useSelector((state) => state.system.translations);

    useEffect(() => {
        dispatch(getCoursesData());

        return () => {
            dispatch(elearningActions.resetElearning());
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    return (
        <div className={classes.ELearning}>
            <Title
                title={getTrans(translations, 'menu_e_learning', lang)}
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <path d='M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z'></path>
                        <path d='M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z'></path>
                    </svg>
                }
            />
            <div className={classes.ContentInner}>
                <div className={classes.Widgets}>
                    <Widget noPadding>
                        <CoursesOverview coursesProgress={coursesProgress} />
                    </Widget>

                    <Widget noPadding>
                        <div className={classes.CoursesWrapper}>
                            {courses.map((course, index) => {
                                return <CourseRow key={index} course={course} index={index} />;
                            })}
                        </div>
                    </Widget>
                </div>
            </div>
        </div>
    );
};

export default ELearning;
