import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapContainer, ZoomControl, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

import classes from './Admin.module.css';
import MainButton from '../../features/UI/MainButton/MainButton';
import { getImage } from './adminAsyncActions';
import { adminActions } from './adminSlice';

const CONFIG = require('../../reactConfig.json');

const AdminBusinessView = (props) => {
    const dispatch = useDispatch();

    const businessImages = useSelector((state) => state.admin.businessImages);

    useEffect(() => {
        if (props.selected.images) {
            for (let i = 0; i < props.selected.images.length; i++) {
                const image = props.selected.images[i];
                dispatch(getImage('businesses', 'business' + props.selected.id, image.imageName));
            }
        }

        return () => {
            dispatch(adminActions.setBusinessImages([]));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    return (
        <div className={classes.AdminUser}>
            <form>
                <div className={classes.FormRow}>
                    <label>ID</label>
                    <div>{props.selected.id}</div>
                </div>

                <div className={classes.FormRow}>
                    <label>Title</label>
                    <div>{props.selected.title}</div>
                </div>

                <div className={classes.FormRow}>
                    <label>Description</label>
                    <div>{props.selected.description}</div>
                </div>

                <div className={classes.FormRow}>
                    <label>Added</label>
                    <div>{props.selected.added}</div>
                </div>

                <div className={classes.MapWrapper}>
                    <span>Map</span>
                    <div className={classes.Coordinates}>
                        <div>Longitude: {props.selected.position[1]}</div>
                        <div>Latitude: {props.selected.position[0]}</div>
                    </div>

                    <div className={classes.Map}>
                        <MapContainer center={props.selected.position} zoom={13} maxZoom={17} boundsOptions={{ padding: [50, 50] }} zoomControl={false}>
                            <ReactLeafletGoogleLayer apiKey={CONFIG.mapKey} type={'roadmap'} />

                            <Marker position={props.selected.position} />

                            <ZoomControl position='topright' />
                        </MapContainer>
                    </div>
                </div>

                {businessImages.length > 0 ? (
                    <div className={classes.ImagesWrapper}>
                        <span>Images</span>
                        <div className={classes.ImagesList}>
                            {businessImages.map((image, index) => {
                                return <div style={{ backgroundImage: `url(${image.image})` }} className={classes.ImageItem} key={index}></div>;
                            })}
                        </div>
                    </div>
                ) : null}

                <div className={classes.ButtonWrapper}>
                    <MainButton
                        label='Close'
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <line x1='18' y1='6' x2='6' y2='18'></line>
                                <line x1='6' y1='6' x2='18' y2='18'></line>
                            </svg>
                        }
                        color='grey'
                        onClick={props.onButtonClick}
                    />
                </div>
            </form>
        </div>
    );
};

export default AdminBusinessView;
