import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import classes from './CourseRow.module.css';

const CourseRow = (props) => {
    const lang = useSelector((state) => state.system.lang);

    const courseTitle = useMemo(() => {
        let ct = '';
        if (props.course) {
            ct = props.course['title_' + lang] ? props.course['title_' + lang] : props.course['title_en'];
        }

        return ct;
    }, [props.course, lang]);

    return (
        <Link to={`/elearning-view/${props.course.id}`} style={{ textDecoration: 'none' }}>
            <div className={props.selected ? [classes.CourseRow, classes.Selected].join(' ') : classes.CourseRow}>
                <span className={classes.Number}>{props.course.chapter + '.' + props.course.subchapter}.</span>
                <div className={classes.Title}>
                    <span>{courseTitle}</span>
                    <span>{props.course.description}</span>
                </div>

                <div className={classes.IconsWrapper}>
                    {props.course.passed_assessment === 1 && (
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className={classes.PassedAssessment}
                        >
                            <circle cx='12' cy='8' r='7'></circle>
                            <polyline points='8.21 13.89 7 23 12 20 17 23 15.79 13.88'></polyline>
                        </svg>
                    )}

                    {props.course.read === 1 && (
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className={classes.ReadCourse}
                        >
                            <polyline points='20 6 9 17 4 12'></polyline>
                        </svg>
                    )}
                </div>
            </div>
        </Link>
    );
};

export default CourseRow;
