import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import elearningImage from '../../assets/images/elearning-portals-cover-picture.svg';
import project_logo from '../../assets/images/project_logo.png';
import classes from './Home.module.css';
import MainButton from '../../features/UI/MainButton/MainButton';
import { useNavigate } from 'react-router-dom';
import { getTrans } from '../../utils/utils';

const Home = () => {
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery({ query: '(max-width: 700px)' });

    const translations = useSelector((state) => state.system.translations);
    const lang = useSelector((state) => state.system.lang);

    return (
        <div className={classes.Home}>
            <div className={classes.ContentInner}>
                {isSmallScreen ? (
                    <div className={[classes.TopPartWrapper, classes.IsSmallScreen].join(' ')}>
                        <div className={classes.TopPart}>
                            <div className={classes.ImageWrapper}>
                                <img src={elearningImage} alt='Elearning' />
                            </div>
                            <div className={classes.TitleWrapper}>
                                <h1 dangerouslySetInnerHTML={{ __html: getTrans(translations, 'welcome', lang) }} />
                                <span>{getTrans(translations, 'project_aim', lang)}</span>

                                <div className={classes.HomeButtonsMobile}>
                                    <MainButton
                                        label='E-learning'
                                        color='warning'
                                        icon={
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <path d='M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z'></path>
                                                <path d='M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z'></path>
                                            </svg>
                                        }
                                        onClick={() => navigate('/elearning')}
                                    />
                                    <MainButton
                                        label='Businesses'
                                        color='action'
                                        outline
                                        icon={
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <path d='M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z'></path>
                                                <circle cx='12' cy='10' r='3'></circle>
                                            </svg>
                                        }
                                        onClick={() => navigate('/webgis')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={classes.TopPartWrapper}>
                        <div className={classes.TopPart}>
                            <div className={classes.TitleWrapper}>
                                <h1 dangerouslySetInnerHTML={{ __html: getTrans(translations, 'welcome', lang) }} />
                                <span>{getTrans(translations, 'project_aim', lang)}</span>

                                <div className={classes.HomeButtons}>
                                    <MainButton
                                        label='E-learning'
                                        color='warning'
                                        icon={
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <path d='M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z'></path>
                                                <path d='M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z'></path>
                                            </svg>
                                        }
                                        onClick={() => navigate('/elearning')}
                                    />
                                    <MainButton
                                        label={getTrans(translations, 'businesses', lang)}
                                        color='action'
                                        outline
                                        icon={
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <path d='M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z'></path>
                                                <circle cx='12' cy='10' r='3'></circle>
                                            </svg>
                                        }
                                        onClick={() => navigate('/webgis')}
                                    />
                                </div>
                            </div>
                            <div className={classes.ImageWrapper}>
                                <img src={elearningImage} alt='Elearning' />
                            </div>
                        </div>
                    </div>
                )}

                <div className={classes.RestPartWrapper}>
                    <div className={classes.RestPart}>
                        <div className={classes.LogoWrapper}>
                            <img src={project_logo} alt='Project logo' />
                        </div>

                        <div dangerouslySetInnerHTML={{ __html: getTrans(translations, 'home_text', lang) }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
