import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

import certificateImage from '../../../assets/images/certificate.jpg';

const PdfDocument = (props) => {
    const styles = StyleSheet.create({
        page: {
            margin: 0,
        },
        text: {
            textAlign: 'center',
            position: 'absolute',
            marginHorizontal: 'auto',
            justifyContent: 'center',
        },
        name: {
            fontSize: 30,
            top: '220px',
            left: '0px',
            right: '0px',
        },
        date: {
            fontSize: 14,
            top: '330px',
            left: '100px',
            right: '0px',
        },
    });

    const today = new Date();
    const date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();

    return (
        <Document>
            <Page size='A4' orientation='landscape' style={styles.page}>
                <View>
                    <Image
                        style={{
                            height: '99%',
                            width: '99%',
                            marginHorizontal: 'auto',
                        }}
                        src={certificateImage}
                        alt=''
                    ></Image>
                </View>

                <Text style={{ ...styles.name, ...styles.text }}>{props.data}</Text>
                <Text style={{ ...styles.date, ...styles.text }}>{date}</Text>
            </Page>
        </Document>
    );
};

export default PdfDocument;
