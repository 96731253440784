import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import classes from './Admin.module.css';
import MainCheckbox from '../../features/UI/MainCheckbox/MainCheckboxControlled';
import MainInput from '../../features/UI/MainInput/MainInput';
import MainButton from '../../features/UI/MainButton/MainButton';
import MainSelect from '../../features/UI/MainSelect/MainSelect';
import { saveUser } from './adminAsyncActions';

const { Entropy } = require('entropy-string');

const AdminUserEdit = (props) => {
    const minPasswordLength = useSelector((state) => state.system.minPasswordLength);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [data, setData] = useState({ ...props.selected });
    const [formValidated, setFormValidated] = useState(false);

    const roles = useSelector((state) => state.admin.roles);
    const rolesOptions = useSelector((state) => state.admin.rolesOptions);

    const generateRandomPassword = () => {
        // Creates a random string
        let entropy;
        let charset;

        charset = '234689bdfghmnpqrBDFGHJLMNR!@#_)(';

        const bits = minPasswordLength * 5; // The first number is the minimum characters necessary

        entropy = new Entropy({ charset: charset, bits: bits });
        const string = entropy.string();

        updateData('password', string);
    };

    const updateData = (property, value) => {
        let updateData = { ...data };
        updateData[property] = value;

        setData(updateData);
    };

    const updateRole = (value) => {
        const role = roles.find((r) => r.role_name === value);
        const role_id = role.role_id;

        updateData('role_id', role_id);
    };

    const saveData = () => {
        setFormValidated(true);

        if (
            data.username?.trim() === '' ||
            data.email?.trim() === '' ||
            (data.id !== '' && data.password?.trim() !== '' && data.password?.trim().length < minPasswordLength)
        ) {
            enqueueSnackbar('Please fill in all required fields', { variant: 'error' });
        } else if (data.id === '' && data.password?.trim().length < minPasswordLength) {
            enqueueSnackbar(`The password must have at least ${minPasswordLength} characters`, { variant: 'error' });
        } else {
            dispatch(saveUser(data));
            props.onButtonClick(data);
        }
    };

    return (
        <div className={classes.AdminUser}>
            <form>
                <div className={classes.FormRow}>
                    <label>ID</label>
                    <MainInput type='text' fullWidth value={data.id} disabled />
                </div>

                <div className={classes.FormRow}>
                    <label>Username *</label>
                    <MainInput
                        type='text'
                        fullWidth
                        value={data.username}
                        onChange={(e) => updateData('username', e.target.value)}
                        validated={formValidated}
                        isValid={data.username?.trim() !== ''}
                    />
                </div>

                <div className={classes.FormRow}>
                    <label>Email *</label>
                    <MainInput
                        type='text'
                        fullWidth
                        value={data.email}
                        onChange={(e) => updateData('email', e.target.value)}
                        validated={formValidated}
                        isValid={data.email?.trim() !== ''}
                    />
                </div>

                <div className={classes.FormRow}>
                    <label>{data.id === '' ? 'Password *' : 'Password'}</label>
                    <span className={classes.MinimumPasswordText}>Minimum {minPasswordLength} characters</span>

                    <MainInput
                        type='text'
                        fullWidth
                        value={data.password}
                        onChange={(e) => updateData('password', e.target.value)}
                        validated={formValidated}
                        isValid={
                            data.id === ''
                                ? data.password?.length >= minPasswordLength
                                : data.password?.trim() == ''
                                ? true
                                : data.password?.trim() !== ''
                                ? data.password?.length >= minPasswordLength
                                : false
                        }
                    />
                    <MainButton
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <path d='M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4'></path>
                            </svg>
                        }
                        type='circle'
                        color='success'
                        onClick={(e) => {
                            e.preventDefault();
                            generateRandomPassword();
                        }}
                    />
                </div>

                <div className={classes.FormRow}>
                    <label>Role *</label>
                    <MainSelect
                        options={rolesOptions}
                        value={roles.find((r) => r.role_id === data.role_id)['role_name']}
                        onChange={(e) => updateRole(e.target.value)}
                        fullWidth
                    />
                </div>

                <div className={classes.FormRow}>
                    <label>Active</label>
                    <MainCheckbox name='active' checked={data.active} onChange={(value) => updateData('active', value)} />
                </div>

                <div className={classes.FormRow}>
                    <label>Registered</label>
                    <MainInput type='text' fullWidth defaultValue={data.registered} disabled />
                </div>
            </form>

            <div className={classes.ButtonWrapper}>
                <MainButton
                    label='Save'
                    icon={
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                            <polyline points='17 21 17 13 7 13 7 21'></polyline>
                            <polyline points='7 3 7 8 15 8'></polyline>
                        </svg>
                    }
                    color='action'
                    onClick={saveData}
                />
            </div>
        </div>
    );
};

export default AdminUserEdit;
