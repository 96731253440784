import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    courses: [],
    userCoursesProgress: {
        total: 0,
        certification: false,
        read: 0,
        passed: 0,
        progress: 0,
    },
    selectedCourse: null,
    pages: [],
    slideLoading: false,
    assessmentData: null,
    certificateAvailable: false,
    certificateName: '',
    isCompany: false,
    userLang: 'en',
};

export const elearningSlice = createSlice({
    name: 'elearning',
    initialState,
    reducers: {
        setCourses: (state, action) => {
            state.courses = action.payload;
        },
        setUserCoursesProgress: (state, action) => {
            state.userCoursesProgress = action.payload;
        },
        setUserLang: (state, action) => {
            state.userLang = action.payload;
        },
        setSelectedCourse: (state, action) => {
            state.selectedCourse = action.payload;
        },
        setPage: (state, action) => {
            const page = action.payload;
            const found = state.pages.find((p) => p.pageIndex === page.pageIndex);

            if (!found) state.pages.push(page);
        },
        setPages: (state, action) => {
            state.pages = action.payload;
        },
        setSlideLoading: (state, action) => {
            state.slideLoading = action.payload;
        },
        setAssessmentData: (state, action) => {
            state.assessmentData = action.payload;
        },
        setElearningInitData: (state, action) => {
            state.certificateAvailable = action.payload.certificateAvailable;
            state.isCompany = action.payload.isCompany;
            state.certificateName = action.payload.certificateName;
        },

        resetElearning: (state, action) => {
            state.courses = [];
            state.userCoursesProgress = {
                total: 0,
                certification: false,
                read: 0,
                passed: 0,
                progress: 0,
            };
            state.selectedCourse = null;
            state.pages = [];
            state.slideLoading = false;
            state.certificateAvailable = false;
            state.isCompany = false;
            state.certificateName = '';
        },
    },
});

export const elearningActions = elearningSlice.actions;

export default elearningSlice;
