import { useEffect, useRef, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import L from 'leaflet';
import { MapContainer, ZoomControl, FeatureGroup, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import { useMediaQuery } from 'react-responsive';

import classes from './WebGis.module.css';
import Title from '../../features/Layout/Title';
import Directions from './Directions';
import Businesses from './Businesses';
import { getBusinesses } from './webGisAsyncActions';
import { webGisActions } from './webGisSlice';
import BusinessPopup from './features/BusinessPopup/BusinessPopup';
import { getTrans } from '../../utils/utils';

const CONFIG = require('../../reactConfig.json');

const WebGis = () => {
    const dispatch = useDispatch();

    const featureGroupRef = useRef();
    const waypoints = useSelector((state) => state.webGis.waypoints);
    const isMobileWidth = useSelector((state) => state.layout.isMobileWidth);
    const isMobile = useMediaQuery({ query: '(max-width: ' + isMobileWidth + 'px)' });
    const lang = useSelector((state) => state.system.lang);
    const translations = useSelector((state) => state.system.translations);

    const [sidebarClosed, setSidebarClosed] = useState(false);
    const [map, setMap] = useState(null);
    const [viewDirections, setViewDirections] = useState(false);
    const [selectedBusiness, setSelectedBusiness] = useState(null);
    const [routingControl, setRoutingControl] = useState(null);
    const [tab, setTab] = useState('map');

    const businesses = useSelector((state) => state.webGis.businesses);
    const [searchStr, setSearchStr] = useState('');
    const filteredBusinesses = useMemo(() => {
        let fbs = [...businesses];
        if (searchStr !== '') {
            fbs = businesses.filter(
                (b) => b.title.toLowerCase().includes(searchStr.toLowerCase()) || b.description.toLowerCase().includes(searchStr.toLowerCase())
            );
        }

        return fbs;
    }, [businesses, searchStr]);

    useEffect(() => {
        return () => {
            dispatch(webGisActions.setWaypoints(null));
        };
    }, [dispatch]);

    const toggleSidebar = () => {
        setSidebarClosed((prev) => !prev);

        setTimeout(() => {
            map.invalidateSize({ pan: false, animate: false });
        }, 300);
    };

    useEffect(() => {
        dispatch(getBusinesses());

        return () => {
            dispatch(webGisActions.setBusinesses([]));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    useEffect(() => {
        if (!map) return;
        if (!featureGroupRef.current) return;
        map.fitBounds(featureGroupRef.current.getBounds());
    }, [map, businesses, featureGroupRef?.current]);

    const findDirections = (businessId) => {
        const business = businesses.find((b) => b.id === businessId);
        setViewDirections(true);
        setSelectedBusiness(business);
    };

    const closeDirections = () => {
        setViewDirections(false);
        setSelectedBusiness(null);
        setSearchStr('');
    };

    useEffect(() => {
        if (!map) return;
        if (map && routingControl) {
            map.removeControl(routingControl);
            setRoutingControl(null);
        }
        const pathResultsEl = document.getElementById('path-results');
        if (pathResultsEl) pathResultsEl.innerHTML = '';

        if (waypoints) {
            // Routing
            const control = L.Routing.control({
                waypoints: waypoints,
                lineOptions: {
                    styles: [{ color: '#6FA1EC', weight: 4 }],
                },
                show: false,
                addWaypoints: false,
                routeWhileDragging: true,
                draggableWaypoints: true,
                fitSelectedRoutes: true,
                showAlternatives: false,
            });
            setRoutingControl(control);

            control._map = map;

            const controlDiv = control.onAdd(map);
            document.getElementById('path-results').appendChild(controlDiv);
        }
    }, [map, waypoints]);

    let contentClasses = [classes.ContentInner];
    if (isMobile) {
        contentClasses.push(classes.IsMobile);

        if (tab === 'map') {
            contentClasses.push(classes.ShowMap);
        } else {
            contentClasses.push(classes.ShowBusinesses);
        }
    }

    const onChangeTab = (tab) => {
        if (tab === 'map') {
            setTimeout(() => {
                map.invalidateSize({ pan: false, animate: false });
            }, 300);
        }
        //setViewDirections(false);
        setTab(tab);
    };

    return (
        <div className={classes.WebGis}>
            <Title
                title={getTrans(translations, 'menu_businesses', lang)}
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <path d='M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z'></path>
                        <circle cx='12' cy='10' r='3'></circle>
                    </svg>
                }
            />
            <div className={contentClasses.join(' ')}>
                {isMobile ? (
                    <div className={classes.MapTabs}>
                        <div className={tab === 'map' ? classes.Active : null} onClick={() => onChangeTab('map')}>
                            {getTrans(translations, 'view_map', lang)}
                        </div>
                        <div className={tab === 'businesses' ? classes.Active : null} onClick={() => setTab('businesses')}>
                            {getTrans(translations, 'view_businesses', lang)}
                        </div>
                    </div>
                ) : null}

                <div className={sidebarClosed ? [classes.MapSidebar, classes.Closed].join(' ') : classes.MapSidebar}>
                    {!viewDirections && (
                        <Businesses businesses={businesses} findDirections={findDirections} onSearchChange={(searchStr) => setSearchStr(searchStr)} />
                    )}
                    {viewDirections && <Directions businesses={businesses} business={selectedBusiness} closeDirections={closeDirections} map={map} />}

                    <div className={classes.CloseSidebar} onClick={toggleSidebar}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <polyline points='15 18 9 12 15 6'></polyline>
                        </svg>
                    </div>
                </div>

                <div className={classes.Map}>
                    <MapContainer
                        center={[41, 23]}
                        zoom={13}
                        maxZoom={17}
                        whenReady={(map) => {
                            setMap(map.target);
                        }}
                        boundsOptions={{ padding: [50, 50] }}
                        zoomControl={false}
                    >
                        <ReactLeafletGoogleLayer apiKey={CONFIG.mapKey} type={'roadmap'} />

                        {filteredBusinesses.length > 0 && (
                            <FeatureGroup ref={featureGroupRef}>
                                {filteredBusinesses.map((business, index) => {
                                    return (
                                        <Marker key={index} position={business.position}>
                                            <Popup>
                                                <BusinessPopup findDirections={(businessId) => findDirections(businessId)} business={business} />
                                            </Popup>
                                        </Marker>
                                    );
                                })}
                            </FeatureGroup>
                        )}
                        <ZoomControl position='topright' />
                    </MapContainer>
                </div>
            </div>
        </div>
    );
};

export default WebGis;
