import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import MainInput from '../../features/UI/MainInput/MainInput';
import MainButton from '../../features/UI/MainButton/MainButton';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { loginActions } from './loginSlice';
import { resetPassword } from './loginAsyncActions';
import classes from './ResetPassword.module.css';

const ResetPassword = () => {
    const { id } = useParams();

    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

    const minPasswordLength = useSelector((state) => state.system.minPasswordLength);
    const resetMessage = useSelector((state) => state.login.resetMessage);
    const resetPasswordSuccess = useSelector((state) => state.login.resetPasswordSuccess);
    const loginLoading = useSelector((state) => state.login.loginLoading);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('Reset password'));
    }, [dispatch]);

    const resetPasswordHandler = () => {
        let error = '';
        if (newPassword.trim() === '' || newPasswordConfirm.trim() === '') error = 'Please complete all fields';
        else if (newPassword.trim() !== newPasswordConfirm.trim()) error = 'The password confirm does not match with password';
        else if (newPassword.trim().length < minPasswordLength) error = `The password must have at least ${minPasswordLength} characters`;

        if (error) {
            enqueueSnackbar(error, {
                variant: 'error',
            });
        } else {
            dispatch(resetPassword(id, newPassword));
        }
    };

    useEffect(() => {
        if (resetMessage) {
            enqueueSnackbar(resetMessage.message, {
                variant: resetMessage.type,
            });
        }

        if (resetPasswordSuccess) {
            navigate('/');
        }

        // This runs on component unmount
        return () => {
            dispatch(loginActions.setResetMessage(null));
            dispatch(loginActions.setResetPasswordSuccess(false));
        };
    }, [dispatch, enqueueSnackbar, resetMessage, resetPasswordSuccess, navigate]);

    return (
        <div className={classes.ResetPasswordWrapper}>
            <div className={classes.ResetPasswordInner}>
                <h2>Insert the new password</h2>
                <div className={classes.VerticalSeparator}></div>

                <MainInput type='password' label='Password *' onChange={(e) => setNewPassword(e.target.value)} />
                <div className={classes.VerticalSeparator}></div>

                <MainInput type='password' label='Password confirm *' onChange={(e) => setNewPasswordConfirm(e.target.value)} />

                <div className={classes.ButtonWrapper}>
                    <MainButton
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                                <polyline points='17 21 17 13 7 13 7 21'></polyline>
                                <polyline points='7 3 7 8 15 8'></polyline>
                            </svg>
                        }
                        label='SAVE'
                        color='action'
                        fullWidth
                        onClick={resetPasswordHandler}
                        loading={loginLoading}
                    />
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
