import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import CourseRow from './features/CourseRow';
import MainButton from '../../features/UI/MainButton/MainButton';
import Widget from '../../features/Widget/Widget';
import Title from '../../features/Layout/Title';
import MainRadio from '../../features/UI/MainRadio/MainRadio';
import classes from './ELearningView.module.css';
import { getSelectedCourseAssessment, checkCourseAssessment } from './elearningAsyncActions';
import { elearningActions } from './elearningSlice';
import { getTrans } from '../../utils/utils';

const ELearningAssessment = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const assessmentData = useSelector((state) => state.elearning.assessmentData);
    const courses = useSelector((state) => state.elearning.courses);
    const user = useSelector((state) => state.login.user);
    const lang = useSelector((state) => state.system.lang);
    const translations = useSelector((state) => state.system.translations);

    const [showCoursesList, setShowCoursesList] = useState(false);
    const [retakeTest, setRetakeTest] = useState(false);
    const [selectedChoices, setSelectedChoices] = useState({});

    const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });

    let coursesListClasses = [classes.Col12];
    if (isSmallScreen && showCoursesList) coursesListClasses.push(classes.ShowCoursesList);

    useEffect(() => {
        dispatch(getSelectedCourseAssessment(id, lang));

        return () => {
            dispatch(elearningActions.setAssessmentData(null));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch, id, lang]);

    const updateChoice = (question, choice) => {
        const questionId = question.id;
        const choiceId = choice.id;
        let updatedChoices = { ...selectedChoices };
        updatedChoices[questionId] = choiceId;

        setSelectedChoices(updatedChoices);
    };

    const onCheckCourseAssessment = (e) => {
        e.preventDefault();
        dispatch(checkCourseAssessment(id, selectedChoices, lang));
        setRetakeTest(false);
    };

    const success = useMemo(() => {
        return (
            <div className={classes.TestResultWrapper}>
                <div className={classes.TestUser}>
                    <h3>{user?.username.toUpperCase()}</h3>
                    <span>{getTrans(translations, 'your_result', lang).toUpperCase()}</span>
                </div>
                <div className={classes.SuccessCircle}>{`${parseInt(assessmentData?.course?.assessment_score * 100)}%`}</div>
                <div className={classes.TestResultMessage}>
                    <h3>{getTrans(translations, 'congratulations_assessment', lang).toUpperCase()}</h3>
                    <span>{getTrans(translations, 'you_passed_assessment', lang)}</span>
                </div>
            </div>
        );
    }, [assessmentData, user]);

    const failure = useMemo(() => {
        return (
            <div className={classes.TestResultWrapper}>
                <div className={classes.TestUser}>
                    <h3>{user?.username.toUpperCase()}</h3>
                    <span>{getTrans(translations, 'your_result', lang).toUpperCase()}</span>
                </div>
                <div className={classes.FailureCircle}>{`${Math.round(assessmentData?.course?.assessment_score * 100)}%`}</div>
                <div className={classes.TestResultMessage}>
                    <h3>{getTrans(translations, 'you_failed', lang)}</h3>
                    <span>{getTrans(translations, 'you_need_percent', lang)}</span>
                </div>

                <div className={classes.ButtonWrapper}>
                    <MainButton
                        label={getTrans(translations, 'retake', lang)}
                        color='action'
                        onClick={() => {
                            setRetakeTest(true);
                        }}
                    />
                </div>
            </div>
        );
    }, [assessmentData, user]);

    const questions = (
        <form className={classes.QuestionsWrapper} onSubmit={onCheckCourseAssessment}>
            {assessmentData?.questions?.map((question, index) => {
                return (
                    <div key={index} className={classes.QuestionWrapper}>
                        <div className={classes.Question}>
                            {index + 1}. {question.question}
                        </div>
                        <div className={classes.Choices}>
                            {question.choices.map((choice, choiceIndex) => {
                                return (
                                    <div key={choiceIndex}>
                                        <MainRadio name={`question${index}_choices`} text={choice.choice} onClick={() => updateChoice(question, choice)} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}

            <div className={classes.ButtonWrapper}>
                <MainButton type='submit' label={getTrans(translations, 'submit_test', lang)} color='action' />
            </div>
        </form>
    );

    const courseTitle = useMemo(() => {
        if (!assessmentData) return '';

        let ct = '';
        ct = assessmentData.course['title_' + lang] ? assessmentData.course['title_' + lang] : assessmentData.course['title_en'];
        ct = assessmentData.course.chapter + '.' + assessmentData.course.subchapter + ' ' + ct;

        return ct;
    }, [assessmentData, lang]);

    return (
        <div className={classes.ELearningView}>
            <Title title={courseTitle} />
            <div className={classes.ContentInner}>
                <div className={classes.Widgets}>
                    <div className={coursesListClasses.join(' ')}>
                        <Widget noPadding>
                            <div
                                className={classes.CoursesListTitle}
                                onClick={
                                    isSmallScreen
                                        ? () => {
                                              setShowCoursesList((prev) => !prev);
                                          }
                                        : null
                                }
                            >
                                {getTrans(translations, 'courses_list', lang)}
                            </div>
                            <div className={classes.CoursesWrapper}>
                                {courses.map((course, index) => {
                                    return <CourseRow key={index} course={course} index={index} selected={course.id === assessmentData?.course?.id} />;
                                })}
                            </div>
                        </Widget>
                    </div>

                    <div className={classes.Col25}>
                        <div className={classes.WidgetsCol}>
                            <Widget>
                                <h3 className={classes.AssessmentTitle}>
                                    {getTrans(translations, 'assessment_for_course', lang)} {courseTitle}
                                </h3>
                                <span dangerouslySetInnerHTML={{ __html: getTrans(translations, 'assessment_text', lang) }} />
                                {/* <span>
                                    The following assessment contains questions relevant to the course. You have to answer correctly at least{' '}
                                    <strong>70%</strong> of the questions, in order to pass the assessment.
                                </span> */}
                            </Widget>

                            <div className={classes.Flex1}>
                                <Widget>
                                    {assessmentData?.course?.assessed === 1 &&
                                        assessmentData?.course?.passed_assessment === 0 &&
                                        retakeTest === false &&
                                        failure}

                                    {assessmentData?.course?.assessed === 1 && assessmentData?.course?.passed_assessment === 1 && success}

                                    {assessmentData?.course?.assessed === 1 &&
                                        assessmentData?.course?.passed_assessment === 0 &&
                                        retakeTest === true &&
                                        questions}

                                    {assessmentData?.course?.assessed === 0 && questions}
                                </Widget>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ELearningAssessment;
