import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    percentUploaded: {},
    uploadStatus: '',
    fileUploadStatus: [],
    myFiles: [],
    fileError: null,
    uploadedFiles: [],
    rejectedFiles: [],
};

export const dropzoneSlice = createSlice({
    name: 'dropzone',
    initialState,
    reducers: {
        reset: (state) => {
            state.percentUploaded = 0;
            state.uploadStatus = '';
            state.fileUploadStatus = [];
            state.fileError = null;
            state.myFiles = [];
            state.uploadedFiles = [];
            state.rejectedFiles = [];
        },
        setPercentUploaded: (state, action) => {
            state.percentUploaded = action.payload;
        },
        setFilePercentUploaded: (state, action) => {
            const fileIndex = action.payload.fileIndex;
            const percent = action.payload.percent;
            state.percentUploaded[fileIndex] = percent;
        },
        setUploadStatus: (state, action) => {
            state.uploadStatus = action.payload;
        },
        setMyFiles: (state, action) => {
            state.myFiles = action.payload;
        },
        setFileError: (state, action) => {
            state.fileError = action.payload;
        },
        addUploadedFile: (state, action) => {
            state.uploadedFiles.push(action.payload);
        },
        // setFileUploadStatus: (state, action) => {
        //     const thisFile = state.myFiles.find((f) => f.name === action.payload.file.name);
        //     thisFile.uploadStatus = action.payload.status;
        // },
        addFileUploaded: (state, action) => {
            state.fileUploadStatus.push('uploaded');
        },
        setRejectedFiles: (state, action) => {
            state.rejectedFiles = action.payload;
        },
    },
});

export const dropzoneActions = dropzoneSlice.actions;

export default dropzoneSlice;
