import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import YoutubeEmbed from '../../features/YoutubeEmbed/YoutubeEmbed';
import classes from './ELearningView.module.css';
import Widget from '../../features/Widget/Widget';
import Title from '../../features/Layout/Title';
import CourseRow from './features/CourseRow';
import Viewer from './features/Viewer';
import MainButton from '../../features/UI/MainButton/MainButton';
import { elearningActions } from './elearningSlice';
import { getSelectedCourseData } from './elearningAsyncActions';
import { getTrans } from '../../utils/utils';
import PdfViewer from '../../features/PdfViewer/PdfViewer';

const ELearningView = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const courses = useSelector((state) => state.elearning.courses);
    const selectedCourse = useSelector((state) => state.elearning.selectedCourse);
    const translations = useSelector((state) => state.system.translations);
    const lang = useSelector((state) => state.system.lang);

    const [viewerTab, setViewerTab] = useState('slides');
    const [showPdf, setShowPdf] = useState(false);

    const [showCoursesList, setShowCoursesList] = useState(false);
    const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });

    let coursesListClasses = [classes.Col12];
    if (isSmallScreen && showCoursesList) coursesListClasses.push(classes.ShowCoursesList);

    useEffect(() => {
        dispatch(getSelectedCourseData(id, lang));

        return () => {
            dispatch(elearningActions.setSelectedCourse(null));
            dispatch(elearningActions.setCourses([]));
            dispatch(elearningActions.setPages([]));
            dispatch(elearningActions.setSlideLoading(false));
            // setTab(1);
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch, id, lang]);

    const courseTitle = useMemo(() => {
        if (!selectedCourse) return '';

        let ct = '';
        ct = selectedCourse['title_' + lang] ? selectedCourse['title_' + lang] : selectedCourse['title_en'];
        ct = selectedCourse.chapter + '.' + selectedCourse.subchapter + ' ' + ct;

        return ct;
    }, [selectedCourse, lang]);

    const courseNotes = useMemo(() => {
        if (!selectedCourse) return '';

        const cn = selectedCourse['notes_' + lang] !== '' ? selectedCourse['notes_' + lang] : selectedCourse['notes_en'];

        return cn;
    }, [selectedCourse, lang]);

    return (
        <div className={classes.ELearningView}>
            <div className={classes.TitleRow}>
                <Title title={courseTitle} subtitle={selectedCourse?.description} />

                {showPdf && <PdfViewer file={require(`../../assets/pdf/courses_notes/${courseNotes}`)} onClose={() => setShowPdf(false)} />}

                {selectedCourse && (
                    <div className={classes.NotesWrapper} onClick={() => setShowPdf(true)}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <path d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z'></path>
                            <polyline points='14 2 14 8 20 8'></polyline>
                            <line x1='16' y1='13' x2='8' y2='13'></line>
                            <line x1='16' y1='17' x2='8' y2='17'></line>
                            <polyline points='10 9 9 9 8 9'></polyline>
                        </svg>
                        <span>{getTrans(translations, 'download_notes', lang)}</span>
                    </div>
                    // <a href={require(`../../assets/pdf/courses_notes/${courseNotes}`)} target='_blank'>
                    //     <svg
                    //         xmlns='http://www.w3.org/2000/svg'
                    //         width='24'
                    //         height='24'
                    //         viewBox='0 0 24 24'
                    //         fill='none'
                    //         stroke='currentColor'
                    //         strokeWidth='2'
                    //         strokeLinecap='round'
                    //         strokeLinejoin='round'
                    //     >
                    //         <path d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z'></path>
                    //         <polyline points='14 2 14 8 20 8'></polyline>
                    //         <line x1='16' y1='13' x2='8' y2='13'></line>
                    //         <line x1='16' y1='17' x2='8' y2='17'></line>
                    //         <polyline points='10 9 9 9 8 9'></polyline>
                    //     </svg>
                    //     <span>{getTrans(translations, 'download_notes', lang)}</span>
                    // </a>
                )}
            </div>

            <div className={classes.ContentInner}>
                <div className={classes.Widgets}>
                    <div className={coursesListClasses.join(' ')}>
                        <Widget noPadding>
                            <div
                                className={classes.CoursesListTitle}
                                onClick={
                                    isSmallScreen
                                        ? () => {
                                              setShowCoursesList((prev) => !prev);
                                          }
                                        : null
                                }
                            >
                                {getTrans(translations, 'courses_list', lang)}
                            </div>
                            <div className={classes.CoursesWrapper}>
                                {selectedCourse?.passed_assessment === 0 && (
                                    <div className={classes.CertificationSection}>
                                        <h4>{getTrans(translations, 'take_assessment', lang)}</h4>
                                        <span>{getTrans(translations, 'pass_all_courses', lang)}</span>
                                        <MainButton
                                            label={getTrans(translations, 'launch_assessment', lang)}
                                            color='warning'
                                            onClick={() => navigate(`/elearning-assessment/${selectedCourse?.id}`)}
                                        />
                                    </div>
                                )}

                                {courses.map((course, index) => {
                                    return <CourseRow key={index} course={course} index={index} selected={course.id === selectedCourse?.id} />;
                                })}
                            </div>
                        </Widget>
                    </div>

                    <div className={classes.Col25}>
                        <Widget noPadding>
                            <div className={classes.ViewerTabs}>
                                <div className={viewerTab === 'slides' ? classes.Active : null} onClick={() => setViewerTab('slides')}>
                                    {getTrans(translations, 'view_lesson', lang)}
                                </div>
                                <div className={viewerTab === 'video' ? classes.Active : null} onClick={() => setViewerTab('video')}>
                                    {getTrans(translations, 'view_video', lang)}
                                </div>
                            </div>
                            <div className={classes.ViewerTabsContent}>
                                {viewerTab === 'slides' && <Viewer course={selectedCourse} />}
                                {viewerTab === 'video' &&
                                    (selectedCourse && selectedCourse['video_' + lang] ? (
                                        <YoutubeEmbed embedId={selectedCourse['video_' + lang]} />
                                    ) : (
                                        <div className={classes.NoVideo}>{getTrans(translations, 'no_video', lang)}</div>
                                    ))}
                            </div>
                        </Widget>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ELearningView;
