import { useSelector } from 'react-redux';
import Title from '../../features/Layout/Title';
import Widget from '../../features/Widget/Widget';

import classes from './Terms.module.css';
import { getTrans } from '../../utils/utils';

const Terms = () => {
    const translations = useSelector((state) => state.system.translations);
    const lang = useSelector((state) => state.system.lang);

    return (
        <div className={classes.Terms}>
            <Title
                title='Privacy policy'
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <path d='M12 19l7-7 3 3-7 7-3-3z'></path>
                        <path d='M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z'></path>
                        <path d='M2 2l7.586 7.586'></path>
                        <circle cx='11' cy='11' r='2'></circle>
                    </svg>
                }
            />

            <div className={classes.ContentInner}>
                <Widget>
                    <div dangerouslySetInnerHTML={{ __html: getTrans(translations, 'terms', lang) }} />

                    {/* <div className={classes.TermsPart}>
                        <h4>A. Introduction</h4>
                        <p>
                            Our privacy policy will help you understand what information we collect, how we use it, and what choices you have. If you choose to
                            use our services, then you agree to the collection and use of information in relation with this policy. The personal information
                            that we collect are used for providing and improving our services. We will not use or share your information with anyone except as
                            described in this Privacy Policy.
                        </p>
                    </div>

                    <div className={classes.TermsPart}>
                        <h4>B. Collection of Personal Information</h4>
                        <p>The following types of personal information may be collected, stored and used:</p>
                        <ul>
                            <li>Information such as your email address that you enter when registering on the website</li>
                            <li>Company information, such as Name, Address, Tax ID, Tax ID, telephone numbers and email</li>
                            <li>
                                Information contained in any communication you make with us via email or through the website, including the content of the
                                communication and metadata
                            </li>
                            <li>Information you enter when using services on our website</li>
                            <li>Information that occurs when you use the website, such as when, how often and under what circumstances you use it</li>
                            <li>Any other personal information you send us</li>
                        </ul>
                        <p>
                            Before you disclose any personal information of a third party, you must obtain that person's consent to the disclosure and
                            processing of their personal information in accordance with this policy.
                        </p>
                    </div>

                    <div className={classes.TermsPart}>
                        <h4>C. Use of your Personal Information</h4>
                        <p>
                            Personal information submitted to us is used for the purposes set out in this policy on the relevant pages of the website. We may
                            use personal information for the following purposes:
                        </p>
                        <ul>
                            <li>Administration of the website</li>
                            <li>Personalized website configuration for you</li>
                            <li>Enabling the use of services available on our website</li>
                            <li>Provision of services you request through our website</li>
                            <li>Sending non-advertising communications</li>
                            <li>Send email notifications about your requests</li>
                            <li>Maintaining the security of the website and preventing any fraud</li>
                            <li>Other uses</li>
                        </ul>
                        <p>We will not pass your personal information without your consent to any third party.</p>
                    </div>

                    <div className={classes.TermsPart}>
                        <h4>D. Disclosure of Personal Information</h4>
                        <p>
                            We may share your personal information with any of our employees, officers, insurers, or professional advisors, agents, suppliers,
                            or subcontractors as necessary for the purposes stated in this policy.
                        </p>
                        <p>We may share your personal information:</p>
                        <ul>
                            <li>to the extent required by law</li>
                            <li>in connection with any current or future legal proceedings</li>
                            <li>for the purpose of establishing, exercising or defending our legal rights</li>
                            <li>
                                to anyone we reasonably believe may be subject to judicial or any other competent authority regarding the disclosure of
                                information in the event that we believe the relevant authority will require the disclosure of that particular personal
                                information.
                            </li>
                        </ul>
                        <p>Also as stated in this policy, we will not provide your personal information to third parties.</p>
                    </div>

                    <div className={classes.TermsPart}>
                        <h4>E. Security of your personal data</h4>
                        <p>
                            1. We will take the necessary technical and organizational precautions to prevent the loss, misuse or alteration of your personal
                            data. <br />
                            2. We will store all personal data you provide on our secure (password and firewall protected) servers. <br />
                            3. You accept that the transmission of information over the internet is inherently insecure and we cannot guarantee the security of
                            data transmitted over the internet. <br />
                            4. You are responsible for keeping the password you use confidential. We will not ask you for your password (except when you log in
                            to our website).
                        </p>
                    </div>

                    <div className={classes.TermsPart}>
                        <h4>F. Cookies</h4>
                        <p>
                            Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser
                            from the website that you visit and are stored on your devices’s internal memory.
                        </p>
                        <p>
                            This Services does not uses these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies”
                            to collection information and to improve their services. You have the option to either accept or refuse these cookies, and know when
                            a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this
                            Service.
                        </p>
                    </div>

                    <div className={classes.TermsPart}>
                        <h4>G. Location Information</h4>
                        <p>
                            Some of the services may use location information transmitted from users’ mobile phones. We only use this information within the
                            scope necessary for the designated service.
                        </p>
                    </div>

                    <div className={classes.TermsPart}>
                        <h4>H. Children’s Privacy</h4>
                        <p>
                            This Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children
                            under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from
                            our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please
                            contact us so that we will be able to do necessary actions.
                        </p>
                    </div>

                    <div className={classes.TermsPart}>
                        <h4>I. Amendments</h4>
                        <p>
                            We may update our policy from time to time by posting a new version on our website. You should check the page occasionally to make
                            sure you understand any change to our policy. We may notify you of changes to our policy by email.
                        </p>
                    </div>

                    <div className={classes.TermsPart}>
                        <h4>J. Your Rights</h4>
                        <p>You may ask us to give you any information we hold about you.</p>
                        <p>We may retain the personal information you request for the period permitted by law.</p>
                    </div>

                    <div className={classes.TermsPart}>
                        <h4>K. Information Update</h4>
                        <p>Please let us know if the information we have about you needs updating or correction.</p>
                    </div> */}
                </Widget>
            </div>
        </div>
    );
};

export default Terms;
