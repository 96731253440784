import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import jwt from 'jwt-decode';
import axiosApi from '../../axios-api';
import { Scrollbars } from 'react-custom-scrollbars-2';

import MainInput from '../../features/UI/MainInput/MainInput';
import MainCheckbox from '../../features/UI/MainCheckbox/MainCheckboxControlled';
import MainButton from '../../features/UI/MainButton/MainButton';
import project_logo from '../../assets/images/project_logo.png';
import elearningImage from '../../assets/images/elearning-portals-cover-picture.svg';
import { loginActions } from './loginSlice';
// import { checkLogin } from './loginAsyncActions';
import classes from './Login.module.css';
import { checkAuthTimeout } from './loginAsyncActions';
import { getTrans } from '../../utils/utils';

const CONFIG = require('../../reactConfig.json');

const LoginPart = () => {
    const dispatch = useDispatch();

    const [loginUsername, setLoginUsername] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [rememberMe, toggleRememberMe] = useState(false);

    const loginLoading = useSelector((state) => state.login.loginLoading);
    const loginMessage = useSelector((state) => state.login.loginMessage);
    const { enqueueSnackbar } = useSnackbar();

    const translations = useSelector((state) => state.system.translations);
    const lang = useSelector((state) => state.system.lang);

    const rememberMeHandler = () => {
        toggleRememberMe((prevRememberMe) => !prevRememberMe);
    };

    // const loginHandler = () => {
    //     dispatch(checkLogin({ username: loginUsername, password: loginPassword, rememberMe: rememberMe }));
    // };

    useEffect(() => {
        if (loginMessage) {
            enqueueSnackbar(loginMessage.message, {
                variant: 'error',
            });
        }

        // This runs on component unmount
        return () => {
            dispatch(loginActions.setLoginMessage(null));
        };
    }, [loginMessage, dispatch, enqueueSnackbar]);

    const login = (e) => {
        e.preventDefault();

        dispatch(loginActions.setLoginLoading(true));

        const loginInfo = { username: loginUsername, password: loginPassword, rememberMe: rememberMe };

        axiosApi
            .post('checkLogin.php', loginInfo)
            .then((response) => {
                if (response.data.token !== undefined && response.data.refreshToken !== undefined) {
                    localStorage.setItem(CONFIG.userTokenName, JSON.stringify(response.data.token));
                    localStorage.setItem(CONFIG.userTokenRefreshName, JSON.stringify(response.data.refreshToken));

                    const user = jwt(response.data.token);
                    dispatch(loginActions.authSuccess(user));
                    const currentTime = new Date().getTime() / 1000; // time in seconds
                    dispatch(checkAuthTimeout(user.exp - currentTime));

                    dispatch(loginActions.setIsLoggedIn(true));
                    dispatch(loginActions.setIsVisible(false));

                    // enqueueSnackbar('You are now logged in', {
                    //     variant: 'success',
                    // });
                }

                dispatch(loginActions.setLoginLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(loginActions.setLoginMessage({ message: message, type: 'error' }));
                dispatch(loginActions.setLoginLoading(false));
            });
    };

    return (
        <div className={classes.Login}>
            <div className={classes.ColumnA}>
                <img src={elearningImage} alt='Elearning' />
                <h1 dangerouslySetInnerHTML={{ __html: getTrans(translations, 'welcome', lang) }} />
                <span>{getTrans(translations, 'project_aim', lang)}</span>
            </div>

            <div className={classes.ColumnBWrapper}>
                <Scrollbars className={classes.Scroller} style={{ width: '100%', height: '100%' }}>
                    <div className={classes.ColumnB}>
                        <div className={classes.LogoWrapper}>
                            <img src={project_logo} alt='Project logo' />
                        </div>

                        <div className={classes.ColumnBContent}>
                            <h2>{getTrans(translations, 'sign_in', lang)}</h2>
                            <span>{getTrans(translations, 'enter_to_login', lang)}</span>

                            <form>
                                <MainInput
                                    type='text'
                                    label={getTrans(translations, 'username', lang)}
                                    name='username'
                                    fullWidth
                                    onChange={(e) => setLoginUsername(e.target.value)}
                                />
                                <div className={classes.VerticalSeparator}></div>

                                <MainInput
                                    type='password'
                                    label={getTrans(translations, 'password', lang)}
                                    name='password'
                                    fullWidth
                                    onChange={(e) => setLoginPassword(e.target.value)}
                                />

                                <div className={classes.Remember}>
                                    <MainCheckbox
                                        label={getTrans(translations, 'remember_me', lang)}
                                        name='remember'
                                        checked={rememberMe}
                                        onChange={rememberMeHandler}
                                    />
                                    <span className={classes.ForgotPassword} onClick={() => dispatch(loginActions.setShowing('forgot'))}>
                                        {getTrans(translations, 'forgot_password', lang)}
                                    </span>
                                </div>

                                <div className={classes.ButtonWrapper}>
                                    <MainButton
                                        icon={
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <path d='M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4'></path>
                                                <polyline points='10 17 15 12 10 7'></polyline>
                                                <line x1='15' y1='12' x2='3' y2='12'></line>
                                            </svg>
                                        }
                                        label={getTrans(translations, 'sign_in', lang).toUpperCase()}
                                        color='action'
                                        fullWidth
                                        onClick={login}
                                        loading={loginLoading}
                                    />
                                </div>

                                <div className={classes.NoAccount}>
                                    <span>{getTrans(translations, 'no_account', lang)}</span>
                                    <span className={classes.CreateAccount} onClick={() => dispatch(loginActions.setShowing('register'))}>
                                        {getTrans(translations, 'register', lang)}
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </Scrollbars>
            </div>
        </div>
    );
};

export default LoginPart;
