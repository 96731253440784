import classes from './MainButton.module.css';

const MainButton = (props) => {
    let classNames = [classes.MainButton];
    if (props.fullWidth) classNames.push(classes.FullWidth);
    else if (props.narrow) classNames.push(classes.Narrow);
    else if (props.veryNarrow) classNames.push(classes.VeryNarrow);

    if (props.loading) classNames.push(classes.Loading);

    if (props.type === 'circle') classNames.push(classes.Circle);
    if (props.outline) classNames.push(classes.Outline);

    return (
        <button className={classNames.join(' ')} color={props.color} disabled={props.disabled || false} onClick={props.onClick}>
            {props.loading ? (
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                >
                    <line x1='12' y1='2' x2='12' y2='6'></line>
                    <line x1='12' y1='18' x2='12' y2='22'></line>
                    <line x1='4.93' y1='4.93' x2='7.76' y2='7.76'></line>
                    <line x1='16.24' y1='16.24' x2='19.07' y2='19.07'></line>
                    <line x1='2' y1='12' x2='6' y2='12'></line>
                    <line x1='18' y1='12' x2='22' y2='12'></line>
                    <line x1='4.93' y1='19.07' x2='7.76' y2='16.24'></line>
                    <line x1='16.24' y1='7.76' x2='19.07' y2='4.93'></line>
                </svg>
            ) : (
                props.icon || null
            )}
            {props.label || null}
        </button>
    );
};

export default MainButton;
