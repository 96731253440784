import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Scrollbars } from 'react-custom-scrollbars-2';

import MainSelect from '../../features/UI/MainSelect/MainSelect_uncontrolled';
import MainInput from '../../features/UI/MainInput/MainInput';
import MainCheckbox from '../../features/UI/MainCheckbox/MainCheckboxControlled';
import MainButton from '../../features/UI/MainButton/MainButton';
import project_logo from '../../assets/images/project_logo.png';
import elearningImage from '../../assets/images/elearning-portals-cover-picture.svg';
import { loginActions } from './loginSlice';
import { register } from './loginAsyncActions';
import classes from './Login.module.css';
import PdfViewer from '../../features/PdfViewer/PdfViewer';
import { getTrans } from '../../utils/utils';

const RegisterPart = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [registerFormValidated, setRegisterFormValidated] = useState(false);
    const [registerName, setRegisterName] = useState('');
    const [registerCompany, setRegisterCompany] = useState('');
    const [registerTin, setRegisterTin] = useState('');
    const [registerMunicipalityId, setRegisterMunicipalityId] = useState(-1);
    const [registerUsername, setRegisterUsername] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [registerPasswordConfirm, setRegisterPasswordConfirm] = useState('');
    const [readTerms, setReadTerms] = useState(false);
    const [isCompany, setIsCompany] = useState(false);
    const [showPdf, setShowPdf] = useState(false);

    const minPasswordLength = useSelector((state) => state.system.minPasswordLength);
    const loginLoading = useSelector((state) => state.login.loginLoading);
    const loginMessage = useSelector((state) => state.login.loginMessage);
    const registerSuccess = useSelector((state) => state.login.registerSuccess);
    const municipalitiesOptions = useSelector((state) => state.login.municipalitiesOptions);
    const municipalities = useSelector((state) => state.login.municipalities);
    const translations = useSelector((state) => state.system.translations);
    const lang = useSelector((state) => state.system.lang);

    const registerHandler = (e) => {
        e.preventDefault();
        const isComplete = checkRegister();

        if (isComplete) {
            dispatch(
                register({
                    username: registerUsername,
                    password: registerPassword,
                    email: registerEmail,
                    fullname: registerName,
                    company: registerCompany,
                    tin: registerTin,
                    municipalityId: registerMunicipalityId,
                })
            );
            setRegisterFormValidated(false);
        }
    };

    const checkRegister = () => {
        setRegisterFormValidated(true);
        let isComplete = true;
        let error = '';

        if (isCompany && (registerCompany.trim() === '' || registerTin.trim() === '' || registerMunicipalityId === 0 || registerMunicipalityId === -1)) {
            error = 'Please complete all fields';
        } else if (!isCompany && registerName.trim() === '') {
            error = 'Please complete all fields';
        } else if (registerUsername.trim() === '' || registerEmail.trim() === '' || registerPassword.trim() === '' || registerPasswordConfirm.trim() === '')
            error = 'Please complete all fields';
        else if (registerEmail.includes('@') === false) error = 'Please provide a valid email';
        else if (registerPassword.trim() !== registerPasswordConfirm.trim()) error = 'The password confirm does not match with password';
        else if (registerPassword.trim().length < minPasswordLength) error = `The password must have at least ${minPasswordLength} characters`;
        else if (readTerms === false) error = 'Please accept the terms and conditions';

        if (error) {
            isComplete = false;
            enqueueSnackbar(error, {
                variant: 'error',
            });
        }

        return isComplete;
    };

    const resetState = useCallback(() => {
        setRegisterName('');
        setRegisterCompany('');
        setRegisterTin('');
        setRegisterMunicipalityId(-1);
        setRegisterUsername('');
        setRegisterEmail('');
        setRegisterPassword('');
        setRegisterPasswordConfirm('');
        setReadTerms(false);
    }, []);

    const updateIsCompany = (value) => {
        setRegisterName('');
        setRegisterCompany('');
        setRegisterTin('');
        setRegisterMunicipalityId(-1);
        setIsCompany(value);
    };

    const updateMunicipality = (value) => {
        const found = municipalities.find((m) => m.name === value);
        let mId = -1;
        if (found) mId = found.id;
        setRegisterMunicipalityId(mId);
    };

    useEffect(() => {
        if (loginMessage) {
            enqueueSnackbar(loginMessage.message, {
                variant: loginMessage.type,
            });
        }

        // This runs on component unmount
        return () => {
            dispatch(loginActions.setLoginMessage(null));
        };
    }, [loginMessage, dispatch, enqueueSnackbar]);

    useEffect(() => {
        if (registerSuccess) {
            resetState();
            dispatch(loginActions.setRegisterSuccess(false));
            dispatch(loginActions.setShowing('login'));
        }
    }, [registerSuccess, dispatch, resetState]);

    return (
        <div className={classes.Login}>
            {showPdf && <PdfViewer file={process.env.PUBLIC_URL + '/pdf/deminimis.pdf'} onClose={() => setShowPdf(false)} />}

            <div className={classes.ColumnA}>
                <img src={elearningImage} alt='Elearning' />
                <h1 dangerouslySetInnerHTML={{ __html: getTrans(translations, 'welcome', lang) }} />
                <span>{getTrans(translations, 'project_aim', lang)}</span>
            </div>

            <div className={classes.ColumnBWrapper}>
                <Scrollbars className={classes.Scroller} style={{ width: '100%', height: '100%' }}>
                    <div className={classes.ColumnB}>
                        <div className={classes.LogoWrapper}>
                            <img src={project_logo} alt='Project logo' />
                        </div>

                        <div className={classes.ColumnBContent}>
                            <h2>{getTrans(translations, 'create_account', lang)}</h2>
                            <span>{getTrans(translations, 'enter_info_register', lang)}</span>

                            <form autoComplete='new-password'>
                                <div className={classes.FirstRowWrapper}>
                                    <div className={classes.IsCompany}>
                                        <MainCheckbox
                                            label={getTrans(translations, 'company', lang)}
                                            name='is_company'
                                            checked={isCompany}
                                            onChange={(value) => updateIsCompany(value)}
                                        />
                                    </div>

                                    {isCompany && (
                                        <div className={classes.Deminimis}>
                                            <span className={classes.PdfLink} onClick={() => setShowPdf(true)}>
                                                De Minimis pdf
                                            </span>
                                            {/* <a className={classes.PdfLink} href={process.env.PUBLIC_URL + '/pdf/deminimis.pdf'} target='_blank'>
                                                <span>Download De Minimis pdf</span>
                                            </a> */}
                                        </div>
                                    )}
                                </div>
                                {isCompany ? (
                                    <>
                                        <MainInput
                                            key='companyInput'
                                            type='text'
                                            label={`${getTrans(translations, 'company', lang)} *`}
                                            name='company'
                                            fullWidth
                                            onChange={(e) => setRegisterCompany(e.target.value)}
                                            autoComplete='off'
                                            validated={registerFormValidated}
                                            isValid={registerCompany !== ''}
                                        />
                                        <div className={classes.VerticalSeparator}></div>
                                        <MainInput
                                            key='tinInput'
                                            type='text'
                                            label={`${getTrans(translations, 'tin', lang)} *`}
                                            name='tin'
                                            fullWidth
                                            onChange={(e) => setRegisterTin(e.target.value)}
                                            autoComplete='off'
                                            validated={registerFormValidated}
                                            isValid={registerTin !== ''}
                                        />
                                        <div className={classes.VerticalSeparator}></div>
                                        <MainSelect
                                            label={`${getTrans(translations, 'municipality', lang)} *`}
                                            options={municipalitiesOptions}
                                            onChange={(e) => updateMunicipality(e.target.value)}
                                            fullWidth
                                            withEmpty
                                            validated={registerFormValidated}
                                            isValid={registerMunicipalityId !== -1}
                                        />
                                        <div className={classes.VerticalSeparator}></div>
                                    </>
                                ) : (
                                    <>
                                        <MainInput
                                            key='fullNameInput'
                                            type='text'
                                            label={`${getTrans(translations, 'full_name', lang)} *`}
                                            name='fullname'
                                            fullWidth
                                            onChange={(e) => setRegisterName(e.target.value)}
                                            autoComplete='off'
                                            validated={registerFormValidated}
                                            isValid={registerName !== ''}
                                        />
                                        <div className={classes.VerticalSeparator}></div>
                                    </>
                                )}

                                <MainInput
                                    type='text'
                                    label={`${getTrans(translations, 'username', lang)} *`}
                                    name='username'
                                    fullWidth
                                    onChange={(e) => {
                                        const value = e.target.value.trim();
                                        setRegisterUsername(value);
                                    }}
                                    value={registerUsername}
                                    autoComplete='off'
                                    validated={registerFormValidated}
                                    isValid={registerUsername !== ''}
                                />
                                <div className={classes.VerticalSeparator}></div>

                                <MainInput
                                    type='email'
                                    label='Email *'
                                    name='email'
                                    fullWidth
                                    onChange={(e) => {
                                        const value = e.target.value.trim();
                                        setRegisterEmail(value);
                                    }}
                                    value={registerEmail}
                                    autoComplete='off'
                                    validated={registerFormValidated}
                                    isValid={registerEmail !== ''}
                                />
                                <div className={classes.VerticalSeparator}></div>

                                <MainInput
                                    type='password'
                                    label={`${getTrans(translations, 'password', lang)} *`}
                                    name='password'
                                    fullWidth
                                    onChange={(e) => {
                                        const value = e.target.value.trim();
                                        setRegisterPassword(value);
                                    }}
                                    value={registerPassword}
                                    validated={registerFormValidated}
                                    isValid={registerPassword.length >= minPasswordLength}
                                />
                                <div className={classes.VerticalSeparator}></div>

                                <MainInput
                                    type='password'
                                    label={`${getTrans(translations, 'password_confirm', lang)} *`}
                                    name='confirm_password'
                                    fullWidth
                                    onChange={(e) => {
                                        const value = e.target.value.trim();
                                        setRegisterPasswordConfirm(value);
                                    }}
                                    value={registerPasswordConfirm}
                                    autoComplete='off'
                                    validated={registerFormValidated}
                                    isValid={registerPasswordConfirm.length >= minPasswordLength && registerPasswordConfirm === registerPassword}
                                />

                                <div className={classes.Remember}>
                                    <MainCheckbox
                                        label={getTrans(translations, 'agree_with_the', lang)}
                                        name='agree_terms'
                                        checked={readTerms}
                                        onChange={(value) => setReadTerms(value)}
                                    />
                                    <span className={classes.TermsLink} onClick={() => navigate('/terms')}>
                                        {getTrans(translations, 'terms_conditions', lang)}
                                    </span>
                                </div>

                                <div className={classes.ButtonWrapper}>
                                    <MainButton
                                        icon={
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <path d='M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4'></path>
                                                <polyline points='10 17 15 12 10 7'></polyline>
                                                <line x1='15' y1='12' x2='3' y2='12'></line>
                                            </svg>
                                        }
                                        label={getTrans(translations, 'register', lang).toUpperCase()}
                                        color='action'
                                        fullWidth
                                        onClick={registerHandler}
                                        loading={loginLoading}
                                    />
                                </div>

                                <div className={classes.NoAccount}>
                                    <span>{getTrans(translations, 'already_account', lang)}</span>
                                    <span className={classes.CreateAccount} onClick={() => dispatch(loginActions.setShowing('login'))}>
                                        {getTrans(translations, 'sign_in', lang)}
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </Scrollbars>
            </div>
        </div>
    );
};

export default RegisterPart;
