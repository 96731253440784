import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pageTitle: '',
    menuIsClosed: true,
    showingMap: false,
    showingLogin: false,
    isMobileWidth: 1200,
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setPageTitle: (state, action) => {
            state.pageTitle = action.payload;
        },
        toggleMenuClosed: (state) => {
            state.menuIsClosed = !state.menuIsClosed;
        },
        setMenuClosed: (state) => {
            state.menuIsClosed = true;
        },
        setShowingMap: (state, action) => {
            state.showingMap = action.payload;
        },
        setShowingLogin: (state, action) => {
            state.showingLogin = action.payload;
        },
    },
});

export const layoutActions = layoutSlice.actions;

export default layoutSlice;
