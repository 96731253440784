import { configureStore } from '@reduxjs/toolkit';

import layoutSlice from '../features/Layout/layoutSlice';
import systemSlice from '../features/systemSlice';
import adminSlice from '../pages/Admin/adminSlice';
import loginSlice from '../pages/Login/loginSlice';
import webGisSlice from '../pages/WebGis/webGisSlice';
import profileSlice from '../pages/Profile/profileSlice';
import elearningSlice from '../pages/ELearning/elearningSlice';
import dropzoneSlice from '../features/Dropzone/dropzoneSlice';
import suggestedRoutesSlice from '../pages/SuggestedRoutes/suggestedRoutesSlice';

export const store = configureStore({
    reducer: {
        layout: layoutSlice.reducer,
        system: systemSlice.reducer,
        login: loginSlice.reducer,
        webGis: webGisSlice.reducer,
        admin: adminSlice.reducer,
        profile: profileSlice.reducer,
        elearning: elearningSlice.reducer,
        dropzone: dropzoneSlice.reducer,
        suggestedRoutes: suggestedRoutesSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
