import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Scrollbars } from 'react-custom-scrollbars-2';

import MainInput from '../../features/UI/MainInput/MainInput';
import MainButton from '../../features/UI/MainButton/MainButton';
import project_logo from '../../assets/images/project_logo.png';
import elearningImage from '../../assets/images/elearning-portals-cover-picture.svg';
import { loginActions } from './loginSlice';
import { sendResetPasswordEmail } from './loginAsyncActions';
import classes from './Login.module.css';
import { getTrans } from '../../utils/utils';

const ForgotPart = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [forgotEmail, setForgotEmail] = useState('');
    const activationMessage = useSelector((state) => state.login.activationMessage);
    const loginLoading = useSelector((state) => state.login.loginLoading);

    const translations = useSelector((state) => state.system.translations);
    const lang = useSelector((state) => state.system.lang);

    const forgotEmailHandler = (e) => {
        e.preventDefault();

        if (forgotEmail.trim() === '' || forgotEmail.includes('@') === false) {
            enqueueSnackbar('Please fill in a valid email', {
                variant: 'error',
            });
        } else {
            dispatch(sendResetPasswordEmail(forgotEmail));
        }
    };

    useEffect(() => {
        if (activationMessage) {
            enqueueSnackbar(activationMessage.message, {
                variant: activationMessage.type,
            });
        }

        // This runs on component unmount
        return () => {
            dispatch(loginActions.setActivationMessage(null));
        };
    }, [activationMessage, dispatch, enqueueSnackbar]);

    return (
        <div className={classes.Login}>
            <div className={classes.ColumnA}>
                <img src={elearningImage} alt='Elearning' />
                <h1 dangerouslySetInnerHTML={{ __html: getTrans(translations, 'welcome', lang) }} />
                <span>{getTrans(translations, 'project_aim', lang)}</span>
            </div>

            <div className={classes.ColumnBWrapper}>
                <Scrollbars className={classes.Scroller} style={{ width: '100%', height: '100%' }}>
                    <div className={classes.ColumnB}>
                        <div className={classes.LogoWrapper}>
                            <img src={project_logo} alt='Project logo' />
                        </div>

                        <div className={classes.ColumnBContent}>
                            <h2>{getTrans(translations, 'recover_password', lang)}</h2>
                            <span>{getTrans(translations, 'enter_email_for_link', lang)}</span>

                            <form>
                                <MainInput type='email' label='Email *' fullWidth onChange={(e) => setForgotEmail(e.target.value)} value={forgotEmail} />

                                <div className={classes.ButtonWrapper}>
                                    <MainButton
                                        icon={
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <line x1='22' y1='2' x2='11' y2='13'></line>
                                                <polygon points='22 2 15 22 11 13 2 9 22 2'></polygon>
                                            </svg>
                                        }
                                        label={getTrans(translations, 'send_reset', lang).toUpperCase()}
                                        color='action'
                                        fullWidth
                                        onClick={forgotEmailHandler}
                                        loading={loginLoading}
                                    />
                                </div>

                                <div className={classes.NoAccount}>
                                    <span>{getTrans(translations, 'or_go_back', lang)} </span>
                                    <span className={classes.CreateAccount} onClick={() => dispatch(loginActions.setShowing('login'))}>
                                        {getTrans(translations, 'sign_in', lang)}
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </Scrollbars>
            </div>
        </div>
    );
};

export default ForgotPart;
