import { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import AdminUserView from './AdminUserView';
import AdminUserEdit from './AdminUserEdit';
import AdminCourseView from './AdminCourseView';
import AdminCourseEdit from './AdminCourseEdit';
import AdminBusinessView from './AdminBusinessView';
import AdminBusinessEdit from './AdminBusinessEdit';
import Modal from '../../features/UI/Modal/Modal';
import ModalYesNo from '../../features/UI/Modal/ModalYesNo';
import Widget from '../../features/Widget/Widget';
import MainButton from '../../features/UI/MainButton/MainButton';
import Badge from '../../features/UI/Badge/Badge';
import MyTable from '../../features/UI/MyTable/MyTable';
import classes from './Admin.module.css';
import Title from '../../features/Layout/Title';
import { getTableData, deleteBusiness, deleteUser, deleteCourse } from './adminAsyncActions';
import { adminActions } from './adminSlice';
import { formatDatetime } from '../../utils/utils';

const Admin = () => {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState(null);
    const [type, setType] = useState('');
    const [action, setAction] = useState('');
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [deleteCourseId, setDeleteCourseId] = useState(null);
    const [deleteBusinessId, setDeleteBusinessId] = useState(null);

    const users = useSelector((state) => state.admin.users);
    const courses = useSelector((state) => state.admin.courses);
    const businesses = useSelector((state) => state.admin.businesses);
    const roles = useSelector((state) => state.admin.roles);

    useEffect(() => {
        dispatch(getTableData());

        return () => {
            dispatch(adminActions.reset());
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    const newUser = useMemo(() => {
        const currentDate = new Date();
        const now = formatDatetime(currentDate);

        return {
            id: '',
            username: '',
            email: '',
            password: '',
            active: true,
            registered: now,
            role_id: 20,
        };
    }, []);

    const newCourse = useMemo(() => {
        const currentDate = new Date();
        const now = formatDatetime(currentDate);

        return {
            id: '',
            title_en: '',
            title_gr: '',
            title_bg: '',
            description: '',
            added: now,
            pages_num: 0,
        };
    }, []);

    const newBusiness = useMemo(() => {
        const currentDate = new Date();
        const now = formatDatetime(currentDate);

        return {
            id: '',
            title: '',
            description: '',
            website: '',
            position: [40.635, 22.939],
            added: now,
            images: [],
        };
    }, []);

    const resetModal = () => {
        setShowModal(false);
        setSelected(null);
        setType('');
        setAction('');
    };

    const userColumns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
            },
            {
                accessorKey: 'username',
                header: 'Username',
            },
            {
                accessorKey: 'email',
                header: 'Email',
            },
            {
                accessorFn: (row) => row.role_id,
                id: 'role_id',
                header: 'Role',
                Cell: ({ cell }) => {
                    const role = roles.find((r) => r.role_id === cell.getValue());
                    if (!role) return '';
                    return role.role_name;
                },
            },
            {
                accessorFn: (row) => row.active,
                id: 'active',
                header: 'Active',
                Cell: ({ cell }) =>
                    cell.getValue() ? (
                        <div className={classes.IconWrapper}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className={classes.Check}
                            >
                                <polyline points='20 6 9 17 4 12'></polyline>
                            </svg>
                        </div>
                    ) : (
                        <div className={classes.IconWrapper}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className={classes.NoCheck}
                            >
                                <line x1='18' y1='6' x2='6' y2='18'></line>
                                <line x1='6' y1='6' x2='18' y2='18'></line>
                            </svg>
                        </div>
                    ),
                size: 40,
            },
            {
                accessorKey: 'registered',
                header: 'Registered',
            },
            {
                accessorFn: (row) => row.id,
                id: 'id2',
                header: 'Actions',
                Cell: ({ cell }) => (
                    <div className={classes.TableActions}>
                        <ReactTooltip anchorId={`userColView_${cell.getValue()}`} content='View' />
                        <div
                            className={classes.ActionBtn}
                            id={`userColView_${cell.getValue()}`}
                            onClick={() => {
                                setSelected(cell.row.original);
                                setType('User');
                                setAction('View');
                                setShowModal(true);
                            }}
                        >
                            <Badge
                                color='success'
                                size='small'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <circle cx='11' cy='11' r='8'></circle>
                                        <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                    </svg>
                                }
                            />
                        </div>

                        <ReactTooltip anchorId={`userColEdit_${cell.getValue()}`} content='Edit' />
                        <div
                            className={classes.ActionBtn}
                            id={`userColEdit_${cell.getValue()}`}
                            onClick={() => {
                                setSelected(cell.row.original);
                                setType('User');
                                setAction('Edit');
                                setShowModal(true);
                            }}
                        >
                            <Badge
                                color='warning'
                                size='small'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <path d='M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z'></path>
                                    </svg>
                                }
                            />
                        </div>

                        <ReactTooltip anchorId={`userColDelete_${cell.getValue()}`} content='Delete' />
                        <div
                            id={`userColDelete_${cell.getValue()}`}
                            className={classes.ActionBtn}
                            aria-label='Delete'
                            onClick={() => setDeleteUserId(cell.getValue())}
                        >
                            <Badge
                                color='danger'
                                size='small'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <polyline points='3 6 5 6 21 6'></polyline>
                                        <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'></path>
                                    </svg>
                                }
                            />
                        </div>
                    </div>
                ),
                size: 40,
            },
        ],
        [roles]
    );

    const coursesColumns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
            },
            {
                accessorKey: 'chapter',
                header: 'Chapter',
            },
            {
                accessorKey: 'subchapter',
                header: 'Subchapter',
            },
            {
                accessorKey: 'title_en',
                header: 'Title english',
            },
            {
                accessorKey: 'title_gr',
                header: 'Title greek',
            },
            {
                accessorKey: 'title_bg',
                header: 'Title bulgarian',
            },
            {
                accessorFn: (row) => row.id,
                id: 'id2',
                header: 'Actions',
                Cell: ({ cell }) => (
                    <div className={classes.TableActions}>
                        <ReactTooltip anchorId={`coursesColView_${cell.getValue()}`} content='View' />
                        <div
                            className={classes.ActionBtn}
                            id={`coursesColView_${cell.getValue()}`}
                            onClick={() => {
                                setSelected(cell.row.original);
                                setType('Course');
                                setAction('View');
                                setShowModal(true);
                            }}
                        >
                            <Badge
                                color='success'
                                size='small'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <circle cx='11' cy='11' r='8'></circle>
                                        <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                    </svg>
                                }
                            />
                        </div>

                        <ReactTooltip anchorId={`coursesColEdit_${cell.getValue()}`} content='Edit' />
                        <div
                            className={classes.ActionBtn}
                            id={`coursesColEdit_${cell.getValue()}`}
                            onClick={() => {
                                setSelected(cell.row.original);
                                setType('Course');
                                setAction('Edit');
                                setShowModal(true);
                            }}
                        >
                            <Badge
                                color='warning'
                                size='small'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <path d='M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z'></path>
                                    </svg>
                                }
                            />
                        </div>

                        <ReactTooltip anchorId={`coursesColDelete_${cell.getValue()}`} content='Delete' />
                        <div
                            id={`coursesColDelete_${cell.getValue()}`}
                            className={classes.ActionBtn}
                            aria-label='Delete'
                            onClick={() => setDeleteCourseId(cell.getValue())}
                        >
                            <Badge
                                color='danger'
                                size='small'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <polyline points='3 6 5 6 21 6'></polyline>
                                        <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'></path>
                                    </svg>
                                }
                            />
                        </div>
                    </div>
                ),
                size: 40,
            },
        ],
        []
    );

    const businessesColumns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
            },
            {
                accessorKey: 'title',
                header: 'Title',
            },
            {
                accessorKey: 'description',
                header: 'Description',
            },
            {
                accessorKey: 'added',
                header: 'Added',
            },
            {
                accessorFn: (row) => row.id,
                id: 'id2',
                header: 'Actions',
                Cell: ({ cell }) => (
                    <div className={classes.TableActions}>
                        <ReactTooltip anchorId={`businessColView_${cell.getValue()}`} content='View' />
                        <div
                            className={classes.ActionBtn}
                            id={`businessColView_${cell.getValue()}`}
                            onClick={() => {
                                setSelected(cell.row.original);
                                setType('Business');
                                setAction('View');
                                setShowModal(true);
                            }}
                        >
                            <Badge
                                color='success'
                                size='small'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <circle cx='11' cy='11' r='8'></circle>
                                        <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                    </svg>
                                }
                            />
                        </div>

                        <ReactTooltip anchorId={`businessColEdit_${cell.getValue()}`} content='Edit' />
                        <div
                            className={classes.ActionBtn}
                            id={`businessColEdit_${cell.getValue()}`}
                            onClick={() => {
                                setSelected(cell.row.original);
                                setType('Business');
                                setAction('Edit');
                                setShowModal(true);
                            }}
                        >
                            <Badge
                                color='warning'
                                size='small'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <path d='M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z'></path>
                                    </svg>
                                }
                            />
                        </div>

                        <ReactTooltip anchorId={`businessColDelete_${cell.getValue()}`} content='Delete' />
                        <div
                            id={`businessColDelete_${cell.getValue()}`}
                            className={classes.ActionBtn}
                            aria-label='Delete'
                            onClick={() => setDeleteBusinessId(cell.getValue())}
                        >
                            <Badge
                                color='danger'
                                size='small'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <polyline points='3 6 5 6 21 6'></polyline>
                                        <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'></path>
                                    </svg>
                                }
                            />
                        </div>
                    </div>
                ),
                size: 40,
            },
        ],
        []
    );

    // TODO: delete from database
    const prepareDeleteUser = () => {
        if (!deleteUserId) return;
        dispatch(deleteUser(deleteUserId));
        setDeleteUserId(null);
    };
    const prepareDeleteCourse = () => {
        if (!deleteCourseId) return;
        dispatch(deleteCourse(deleteCourseId));
        setDeleteCourseId(null);
    };
    const prepareDeleteBusiness = () => {
        if (!deleteBusinessId) return;
        dispatch(deleteBusiness(deleteBusinessId));
        setDeleteBusinessId(null);
    };

    return (
        <div className={classes.Admin}>
            <Title
                title='Admin'
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <path d='M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2'></path>
                        <circle cx='8.5' cy='7' r='4'></circle>
                        <polyline points='17 11 19 13 23 9'></polyline>
                    </svg>
                }
            />
            <div className={classes.ContentInner}>
                <div className={classes.Widgets}>
                    <Widget>
                        <div className={classes.TitleRow}>
                            <h2>Users</h2>
                        </div>

                        <div className={classes.WidgetContent}>
                            <MyTable
                                data={users}
                                columns={userColumns}
                                buttons={
                                    <div className={classes.AddButton}>
                                        <MainButton
                                            label='Add new'
                                            color='success'
                                            fullWidth
                                            onClick={() => {
                                                setSelected(newUser);
                                                setType('User');
                                                setAction('New');
                                                setShowModal(true);
                                            }}
                                        />
                                    </div>
                                }
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className={classes.TitleRow}>
                            <h2>Courses</h2>
                        </div>

                        <div className={classes.WidgetContent}>
                            <MyTable
                                data={courses}
                                columns={coursesColumns}
                                buttons={
                                    <div className={classes.AddButton}>
                                        <MainButton
                                            label='Add new'
                                            color='success'
                                            fullWidth
                                            onClick={() => {
                                                setSelected(newCourse);
                                                setType('Course');
                                                setAction('New');
                                                setShowModal(true);
                                            }}
                                        />
                                    </div>
                                }
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className={classes.TitleRow}>
                            <h2>Businesses</h2>
                        </div>

                        <div className={classes.WidgetContent}>
                            <MyTable
                                data={businesses}
                                columns={businessesColumns}
                                buttons={
                                    <div className={classes.AddButton}>
                                        <MainButton
                                            label='Add new'
                                            color='success'
                                            fullWidth
                                            onClick={() => {
                                                setSelected(newBusiness);
                                                setType('Business');
                                                setAction('New');
                                                setShowModal(true);
                                            }}
                                        />
                                    </div>
                                }
                            />
                        </div>
                    </Widget>
                </div>
            </div>

            <Modal title={`${action} ${type}`} size='small' show={showModal} onClose={resetModal}>
                {type === 'User' && action === 'View' && <AdminUserView selected={selected} onButtonClick={resetModal} />}
                {type === 'User' && (action === 'Edit' || action === 'New') && <AdminUserEdit selected={selected} onButtonClick={resetModal} />}

                {type === 'Course' && action === 'View' && <AdminCourseView selected={selected} onButtonClick={resetModal} />}
                {type === 'Course' && (action === 'Edit' || action === 'New') && <AdminCourseEdit selected={selected} onButtonClick={resetModal} />}

                {type === 'Business' && action === 'View' && <AdminBusinessView selected={selected} onButtonClick={resetModal} />}
                {type === 'Business' && (action === 'Edit' || action === 'New') && <AdminBusinessEdit selected={selected} onButtonClick={resetModal} />}
            </Modal>

            <ModalYesNo
                show={deleteUserId}
                title='Are you sure?'
                subtitle={`The user with id ${deleteUserId} will be deleted`}
                onYes={prepareDeleteUser}
                onCancel={() => setDeleteUserId(null)}
            />
            <ModalYesNo
                show={deleteCourseId}
                title='Are you sure?'
                subtitle={`The course with id ${deleteCourseId} will be deleted`}
                onYes={prepareDeleteCourse}
                onCancel={() => setDeleteCourseId(null)}
            />
            <ModalYesNo
                show={deleteBusinessId}
                title='Are you sure?'
                subtitle={`The business with id ${deleteBusinessId} will be deleted`}
                onYes={prepareDeleteBusiness}
                onCancel={() => setDeleteBusinessId(null)}
            />
        </div>
    );
};

export default Admin;
