import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import CloseSnackbarAction from './features/CloseSnackbarAction/CloseSnackbarAction';
import Layout from './features/Layout/Layout';
import Home from './pages/Home/Home';
import ELearning from './pages/ELearning/ELearning';
import ELearningView from './pages/ELearning/ELearningView';
import ELearningAssessment from './pages/ELearning/ELearningAssessment';
import WebGis from './pages/WebGis/WebGis';
import Profile from './pages/Profile/Profile';
import Admin from './pages/Admin/Admin';
import Page404 from './pages/Page404/Page404';
import Login from './pages/Login/Login';
import Terms from './pages/Terms/Terms';
import { authCheckState } from './pages/Login/loginAsyncActions';
import PrivateRoute from './features/PrivateRoute/PrivateRoute';
import Activation from './pages/Login/Activation';
import ResetPassword from './pages/Login/ResetPassword';
import SuggestedRoutes from './pages/SuggestedRoutes/SuggestedRoutes';
import SuggestedRoutesMap from './pages/SuggestedRoutes/SuggestedRoutesMap';
import Sommelier from './pages/Sommelier/Sommelier';

const CONFIG = require('./reactConfig.json');

function App() {
    const initialToken = JSON.parse(localStorage.getItem(CONFIG.userTokenName));
    const [token, setToken] = useState(() => initialToken);

    const dispatch = useDispatch();
    const snackbarRef = useRef();
    const isAuthenticated = useSelector((state) => state.login.isAuthenticated);

    useEffect(() => {
        dispatch(authCheckState(true));
    }, [dispatch]);

    return (
        <Router>
            <SnackbarProvider
                ref={snackbarRef}
                autoHideDuration={3000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                action={(id) => <CloseSnackbarAction id={id} />}
            >
                <Layout>
                    <Routes>
                        {/* protected routes */}
                        <Route
                            path='/elearning'
                            element={
                                <PrivateRoute isAllowed={token || isAuthenticated}>
                                    <ELearning />
                                </PrivateRoute>
                            }
                            exact
                        />
                        <Route
                            path='/elearning-view/:id'
                            element={
                                <PrivateRoute isAllowed={token || isAuthenticated}>
                                    <ELearningView />
                                </PrivateRoute>
                            }
                            exact
                        />
                        <Route
                            path='/elearning-assessment/:id'
                            element={
                                <PrivateRoute isAllowed={token || isAuthenticated}>
                                    <ELearningAssessment />
                                </PrivateRoute>
                            }
                            exact
                        />
                        <Route
                            path='/profile'
                            element={
                                <PrivateRoute isAllowed={token || isAuthenticated}>
                                    <Profile />
                                </PrivateRoute>
                            }
                            exact
                        />
                        <Route
                            path='/admin'
                            element={
                                <PrivateRoute isAllowed={token || isAuthenticated}>
                                    <Admin />
                                </PrivateRoute>
                            }
                            exact
                        />

                        {/* Not protected routes */}
                        <Route path='/home' element={<Home />} exact />
                        <Route path='/' element={<Home />} exact />
                        <Route path='/login' element={<Login />} exact />
                        <Route path='/activation/:id' element={<Activation />} exact />
                        <Route path='/reset-password/:id' element={<ResetPassword />} exact />
                        <Route path='/webgis' element={<WebGis />} exact />
                        <Route path='/suggestedroutes' element={<SuggestedRoutes />} exact />
                        <Route path='/suggested_routes_map/:id' element={<SuggestedRoutesMap />} exact />
                        <Route path='/sommelier' element={<Sommelier />} exact />
                        <Route path='/page404' element={<Page404 />} exact />
                        <Route path='/terms' element={<Terms />} exact />
                        <Route path='*' element={<Navigate replace to='/page404' />} />
                    </Routes>
                </Layout>
            </SnackbarProvider>
        </Router>
    );
}

export default App;
